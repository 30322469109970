<template>
  <v-card v-if="!loading" color="secondary darken-1">
    <v-card-title class="accent--text">
      {{ $t('customer.customer_information') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="currentCustomer.name"
          :label="$t('customers.name')"
          :rules="[$rules.required]"
          class="mt-5 customername"
          type="text"
          validate-on-blur
        ></v-text-field>
        <v-textarea
          v-model="currentCustomer.description"
          :label="$t('customers.description')"
          class="mt-5 customername"
          type="text"
        ></v-textarea>
      </v-form>

      <v-alert :value="showAlert" class="mt-4" outlined type="error">
        {{ $t('validation.check_all_required_fields') }}.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <template v-if="$isAdmin">
        <v-btn
          v-if="currentCustomer.id && currentCustomer.status === 'ACTIVE'"
          :loading="saving"
          color="red"
          @click="setCustomerInactive"
        >
          <span>{{ $t('set_inactive') }}</span>
        </v-btn>
        <v-btn
          v-if="currentCustomer.id && currentCustomer.status === 'DISABLED'"
          :loading="saving"
          color="green"
          @click="setCustomerActive"
        >
          <span>{{ $t('set_active') }}</span>
        </v-btn>
      </template>
      <v-spacer></v-spacer>
      <template v-if="$isAdmin || $isSuperior">
        <v-btn :disabled="saving" color="default" @click="setCurrentCustomer">
          <span>{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn :loading="saving" color="primary" @click="updateCustomer">
          <span>{{ $t('save') }}</span>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>
<script>

export const customerTemplate = {
  id: null,
  description: '',
  name: '',
  status: 'ACTIVE',
}
export default {
  name: 'customer-basic-info',
  props: {
    id: [Number, String],
    customer: Object,
    loading: Boolean,
  },
  data() {
    return {
      currentCustomer: {},
      newCustomer: false,
      saving: false,
      showAlert: false,
    }
  },
  methods: {
    async updateCustomer() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      if (!this.$isAdmin && !this.$isSuperior) {
        return;
      }
      this.saving = true;
      await this.$store.dispatch('upsertCustomer', this.currentCustomer)
      this.saving = false;
    },
    setCurrentCustomer() {
      console.log(this.id)
      const customerIndex = this.$store.state.customers.findIndex(customer => parseInt(customer.id) === parseInt(this.id));
      if (customerIndex > -1) {
        this.currentCustomer = {...this.$store.state.customers[customerIndex]};
      }
    },
    setCustomerInactive() {
      this.currentCustomer.status = 'DISABLED';
      this.updateCustomer();
    },
    setCustomerActive() {
      this.currentCustomer.status = 'ACTIVE';
      this.updateCustomer();
    },
    getStatusColor(status) {
      switch (status) {
        case 'DISABLED':
          return 'grey darken-1';
        case 'ACTIVE':
          return 'success';
      }
    },
  },
  mounted() {
    if (this.customer && this.customer.id) {
      this.currentCustomer = {...this.customer};
    } else if (this.id) {
      this.setCurrentCustomer();
    } else {
      this.newCustomer = true;
      this.currentCustomer = {...customerTemplate};
    }
  }
}
</script>
