import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"light":""}},[_c(VToolbar,{attrs:{"color":"secondary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('timer.create_new_equipment')))]),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{staticClass:"equipmentdescription mt-2",attrs:{"label":_vm.$t('equipments.description'),"rules":[_vm.$rules.required],"type":"text","validate-on-blur":""},model:{value:(_vm.currentEquipment.description),callback:function ($$v) {_vm.$set(_vm.currentEquipment, "description", $$v)},expression:"currentEquipment.description"}})],1),_c(VAlert,{staticClass:"mt-4",attrs:{"value":_vm.showAlert,"outlined":"","type":"error"}},[_vm._v(_vm._s(_vm.$t('validation.check_all_required_fields'))+". ")])],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.saving,"color":"default"},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c(VBtn,{attrs:{"loading":_vm.saving,"color":"primary"},on:{"click":_vm.saveEquipment}},[_c('span',[_vm._v(_vm._s(_vm.$t('save')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }