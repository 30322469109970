<script src="../main.js"></script>
<template>
  <v-container :fill-height="loading || sites.length === 0" :pa-0="$vuetify.breakpoint.xsOnly" class="sites-view" fluid>
    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$t('sites.search')}...`"
          class="topbar-search"
          clearable
          full-width
          hide-details
          single-line
          style="font-size: 20px"
        >
          <v-icon slot="append" style="cursor: pointer">search</v-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <template v-if="!loading">
        <v-row v-if="sites.length > 0" no-gutters>
          <v-col :style="{ height: $vuetify.breakpoint.xsOnly ? '0' : 'auto' }" cols="12" sm="5">
            <v-btn
              v-if="$isAdmin || $isSuperior"
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewSiteInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('sites.create_new_site') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="7">
            <v-row justify-sm="end" no-gutters>
              <div
                :class="$vuetify.breakpoint.xsOnly ? 'ma-3' : 'ml-3 mb-3'"
                :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : '300px' }"
              >
                <v-select
                  v-if="$isAdmin"
                  v-model="filter"
                  :items="filterOptions"
                  :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
                  background-color="secondary lighten-1"
                  hide-details
                  item-text="desc"
                  item-value="value"
                  multiple
                  prepend-inner-icon="filter_list"
                  solo
                >
                  <template v-slot:selection="{ item, index }">
                    <template v-if="filter.length !== filterOptions.length && index === 0">
                      <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
                      <span class="grey--text caption">({{ filter.length }} {{
                          $t('user_management.selections')
                        }})</span>
                    </template>
                    <template v-else>
                      <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
                    </template>
                  </template>
                </v-select>
              </div>
              <div
                :class="$vuetify.breakpoint.xsOnly ? 'mb-3 mx-3' : 'ml-3'"
                :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : '300px' }"
              >
                <v-autocomplete
                  v-model="filterCustomer"
                  :items="customers"
                  :label="$tc('customers.title', 1)"
                  :loading="loadingCustomers"
                  autocomplete="off"
                  background-color="secondary lighten-1"
                  clearable
                  hide-details
                  item-text="name"
                  item-value="id"
                  prepend-inner-icon="far fa-building"
                  return-object
                  solo
                />
              </div>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-list color="transparent" two-line>
              <v-list-item>
                <v-list-item-avatar
                ><span class="grey--text caption">{{ $t('sites.status') }}</span></v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title class="grey--text caption">{{ $tc('sites.title', 1) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text v-if="$isAdmin" class="text-align-center">
                  <span class="grey--text caption">{{ $t('sites.hidden') }}</span>
                </v-list-item-action-text>
              </v-list-item>

              <v-divider></v-divider>

              <template v-for="site in filteredSites">
                <v-list-item :key="site.id + '_listitem'" :to="{ name: 'site', params: { id: site.id } }" exact>
                  <v-list-item-avatar>
                    <v-icon :color="getStatusColor(site.status)">radio_button_checked</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title :class="site.status === 0 ? 'grey--text' : ''">{{
                        site.name
                      }}
                      <v-chip
                        v-if="$isAdmin && site.billed"
                        class="ml-2"
                        color="primary"
                        label
                        small
                      >{{ $t('sites.billed') }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="$vuetify.breakpoint.xsOnly" class="font-weight-medium">
                      {{ getDatesString(site) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle :class="site.status === 0 ? 'grey--text text--darken-1' : ''"
                                          class="d-flex justify-space-between"
                    >
                      <div>{{ site.customer && site.customer.name }}</div>
                      <div v-if="$vuetify.breakpoint.smAndUp" class="ml-1 mr-5">
                        <span class="font-weight-medium">{{ getDatesString(site) }}</span>
                      </div>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle :class="site.status === 0 ? 'grey--text text--darken-1' : ''"
                    >{{ site.address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <v-switch
                      v-if="$isAdmin"
                      v-model="site.hidden"
                      :loading="updatingSites.some(id => id === site.id)"
                      class="mt-0 pt-0"
                      color="primary"
                      hide-details
                      @click.stop.prevent="updateSiteVisibility(site)"
                    ></v-switch>
                  </v-list-item-action-text>
                </v-list-item>
                <v-divider :key="site.id + '_divider'"></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>

        <v-row
          v-if="sites.length === 0"
          align="stretch"
          align-content-lg="start"
          align-content-md="start"
          align-content-sm="start"
          align-content-xl="start"
          class="fill-height"
          no-gutters
        >
          <v-col cols="12">
            <v-btn
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewSiteInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('sites.create_new_site') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center" no-gutters>
              <div
                :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '' }"
                class="text-center pl-5 pr-5"
                style="padding-bottom: 150px"
              >
                <v-icon color="secondary lighten-2" size="100">fas fa-map-marked-alt</v-icon>
                <div class="headline font-weight-bold mt-3 mb-3">{{ $t('sites.no_sites') }}</div>
                <div v-if="$vuetify.breakpoint.smAndUp" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('sites.no_sites_help') }}.
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('sites.no_sites_help_xs') }}.
                </div>
                <v-btn class="mt-5" outlined @click="$reloadApp">
                  <v-icon left>refresh</v-icon>
                  {{ $t('reload') }}
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-fade-transition>

    <site-dialog ref="siteDialog"/>
  </v-container>
</template>

<script>
import SiteDialog from '../components/Sites/SiteDialog.vue';
import moment from 'moment';

export default {
  name: 'SitesView',
  components: {
    SiteDialog: SiteDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      offsetTop: 0,
      filter: [1, 2, 5],
      filterOptions: [
        {desc: 'Piilotetut', value: 0},
        {desc: 'Ei piilotetut', value: 1},
        {desc: 'Aktiiviset', value: 2},
        {desc: 'Ei aktiiviset', value: 3},
        {desc: 'Laskutettu', value: 4},
        {desc: 'Ei laskutettu', value: 5},
      ],
      filterCustomer: null,
      loadingCustomers: false,
      updatingSites: [],
    };
  },
  methods: {
    openNewSiteInDialog() {
      this.$refs.siteDialog.openNew();
    },
    async updateSiteVisibility(site) {
      this.updatingSites.push(site.id);
      await this.$store.dispatch('upsertSite', site);
      const siteIndex = this.updatingSites.indexOf(site.id);
      if (siteIndex > -1) {
        this.updatingSites.splice(siteIndex, 1);
      }
    },
    getDatesString(site) {
      let startDate = moment(site.startDate);
      let endDate = moment(site.endDate);
      if (!startDate.isValid()) {
        startDate = null;
      }
      if (!endDate.isValid()) {
        endDate = null;
      }
      startDate = startDate ? startDate.format('DD.MM.YYYY') : startDate;
      endDate = endDate ? endDate.format('DD.MM.YYYY') : endDate;
      return [startDate, endDate].filter(Boolean).join(' - ');
    },
    async reloadSites() {
      if (this.$store.state.sites.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadSites');

      this.loading = false;
      this.subtleLoading = false;
    },
    async reloadCustomers() {
      this.loadingCustomers = true;
      await this.$store.dispatch('reloadCustomers');
      this.loadingCustomers = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'grey darken-1';
        case 10:
          return 'success';
      }
    },
  },
  computed: {
    customers() {
      return this.$store.state.customers;
    },
    sites() {
      return this.$store.state.sites;
    },
    filteredSites() {
      console.log('filteredSites');
      const filteredSites = [...this.sites].sort((a, b) => {
        return b.id - a.id;
      });

      const filterOutHidden = this.filter.findIndex(f => f === 0) === -1;
      const filterOutVisible = this.filter.findIndex(f => f === 1) === -1;
      const filterOutActive = this.filter.findIndex(f => f === 2) === -1;
      const filterOutInactive = this.filter.findIndex(f => f === 3) === -1;
      const filterOutBilled = this.filter.findIndex(f => f === 4) === -1;
      const filterOutNotBilled = this.filter.findIndex(f => f === 5) === -1;

      if (
        (this.searchPhrase == null || this.searchPhrase.length === 0) &&
        !filterOutActive &&
        !filterOutInactive &&
        !filterOutHidden &&
        !filterOutVisible &&
        !filterOutBilled &&
        !filterOutNotBilled
      ) {
        return filteredSites;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredSites.filter(site => {
        let found = [];
        const lowerSearchedString =
          (site.name ? site.name.toLowerCase() : '') +
          (site.address ? site.address.toLowerCase() : '') +
          (site.customer && site.customer.name ? site.customer.name.toLowerCase() : '');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });

        if (this.filterCustomer && (!site.customer || this.filterCustomer.id !== site.customer.id)) found.push(false);

        if (filterOutHidden && site.hidden) found.push(false);
        if (filterOutVisible && !site.hidden) found.push(false);
        if (filterOutActive && site.status === 10) found.push(false);
        if (filterOutInactive && site.status === 0) found.push(false);
        if (filterOutBilled && site.billed) found.push(false);
        if (filterOutNotBilled && !site.billed) found.push(false);
        return found.indexOf(false) === -1;
      });
    },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    if (this.$isAdmin) {
      this.filter = [0, 1, 2, 5];
    }
    this.reloadSites();
    this.reloadCustomers();
  },
};
</script>

<style scoped></style>
