<template>
  <v-container class="fill-height pa-0" fluid>
    <v-row>
      <v-col cols="12">
        <v-btn :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.xsOnly }"
               :large="$vuetify.breakpoint.smAndUp"
               :block="$vuetify.breakpoint.xsOnly"
               color="primary" @click="openDialog(null)">
          <v-icon class="no-gradient" left>mdi-plus</v-icon>
          <span>{{ $t('item_types.new_item_type') }}</span>
        </v-btn>
        <v-btn :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.xsOnly }"
               :large="$vuetify.breakpoint.smAndUp"
               :block="$vuetify.breakpoint.xsOnly"
               v-if="searchIsClearable"
               color="info"
               @click="clearSearch">
          <v-icon class="no-gradient" left>mdi-close</v-icon>
          <span>{{ $t('clear_search') }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="secondary darken-1" tile>
              <v-card-title class="accent--text">
                {{ $t('item_types.active_types_title') }}
              </v-card-title>
              <v-list v-if="localFilteredActiveItemTypes && localFilteredActiveItemTypes.length" color="transparent">
                <draggable v-model="localFilteredActiveItemTypes"
                           :disabled="reorderLoading" @end="reorder">
                  <v-list-item v-for="(type) in activeItemTypes"
                               :key="type.id"
                               class="draggable-item"
                  >

                    <v-list-item-icon class="mt-4">
                      <v-icon>mdi-drag</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ type.shortDescription }}</v-list-item-title>
                      <v-list-item-subtitle>{{ type.longDescription }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row no-gutters>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon @click="openDialog(type)" v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('item_types.edit_item_type') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn color="warning" icon @click="toggleItemTypeActive(type.id, type.active)"
                                   v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('item_types.delete_item_type') }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </draggable>
              </v-list>
                <!-- NO CONTENT -->
              <v-card-text v-else>
                {{ $t('item_types.no_item_types') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-spacer/>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card class="secondary darken-1 no-gutters pa-0 mx-0 my-3" tile>
              <v-card-title class="accent--text">
                {{ $t('item_types.inactive_types_title') }}
              </v-card-title>
                <v-list v-if="localFilteredInactiveItemTypes && localFilteredInactiveItemTypes.length" color="transparent">
                  <v-list-item v-for="(type) in inactiveItemTypes"
                               :key="type.id"
                               two-line
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ type.shortDescription }}</v-list-item-title>
                      <v-list-item-subtitle>{{ type.longDescription }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row no-gutters>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn icon @click="openDialog(type)" v-on="on">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('item_types.edit_item_type') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn color="success" icon @click="toggleItemTypeActive(type.id, type.active)"
                                   v-on="on">
                              <v-icon>mdi-delete-off</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('item_types.restore_item_type') }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <!-- NO CONTENT -->
              <v-card-text v-else>
                {{ $t('item_types.no_item_types') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <template v-slot:activator="{ on }"></template>
        <v-card color="secondary darken-1">
          <v-card-title color="secondary">
            <span class="headline">{{ dialogTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItemType.shortDescription"
                  :label="editedItemType.shortDescription ? '' : $t('item_types.short_desc_label')"
                  :placeholder="!editedItemType.shortDescription ? $t('item_types.short_desc_placeholder') : editedItemType.shortDescription"
                  :rules="[$rules.required]"
                  clearable
                  type="text"
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItemType.longDescription"
                  :label="editedItemType.longDescription ? '' : $t('item_types.long_desc_label')"
                  :placeholder="!editedItemType.longDescription ? $t('item_types.long_desc_placeholder') : editedItemType.longDescription"
                  clearable
                  type="text"
                  validate-on-blur
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :bottom="$vuetify.breakpoint.xsOnly"
                   :disabled="saving"
                   :fab="$vuetify.breakpoint.xsOnly"
                   :fixed="$vuetify.breakpoint.xsOnly"
                   :left="$vuetify.breakpoint.xsOnly"
                   color="default"
                   @click="closeDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn :bottom="$vuetify.breakpoint.xsOnly"
                   :fab="$vuetify.breakpoint.xsOnly"
                   :fixed="$vuetify.breakpoint.xsOnly"
                   :loading="saving"
                   :right="$vuetify.breakpoint.xsOnly"
                   color="primary"
                   @click="saveItemType"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: "ItemTypes",
  components: {Draggable},
  props: ['searchPhrase'],
  data() {
    return {
      dialog: false,
      dialogTitle: '',
      editedItemType: {},
      localFilteredActiveItemTypes: [],
      localFilteredInactiveItemTypes: [],
      reorderLoading: false,
      saving: false,
    };
  },
  computed: {
    activeItemTypes() {
      const actives = [...this.$store.state.itemTypes].sort(
        (a, b) => a.ordernum - b.ordernum
      ).filter((itemType) => itemType.active);

      if (this.searchPhrase && this.searchPhrase !== '') {
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(' ');

        return actives.filter(type => {
          let found = [];
          const lowerSearchedString =
            (type.shortDescription ? type.shortDescription.toLowerCase() : '') +
            (type.longDescription ? type.longDescription.toLowerCase() : '')
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          });
          return found.indexOf(false) === -1;
        })
      } else {
        return actives;
      }
    },
    inactiveItemTypes() {
      const inactives = [...this.$store.state.itemTypes].sort(
        (a, b) => a.id - b.id
      ).filter((itemType) => !itemType.active);

      if (this.searchPhrase && this.searchPhrase !== '') {
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(' ');

        return inactives.filter(type => {
          let found = [];
          const lowerSearchedString =
            (type.shortDescription ? type.shortDescription.toLowerCase() : '') +
            (type.longDescription ? type.longDescription.toLowerCase() : '')
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          });
          return found.indexOf(false) === -1;
        })
      } else {
        return inactives;
      }
    },
    searchIsClearable() {
      return (this.searchPhrase !== '')
    }
  },
  watch: {
    activeItemTypes(newFilteredItemTypes) {
      this.localFilteredActiveItemTypes = newFilteredItemTypes.sort((a, b) => a.ordernum - b.ordernum);
    },
    inactiveItemTypes(newFilteredItemTypes) {
      this.localFilteredInactiveItemTypes = newFilteredItemTypes;
    },
  },
  methods: {
    clearSearch() {
      this.$emit('clear-search');
    },
    async reorder(event) {
      const {newIndex, oldIndex} = event;
      if (oldIndex === newIndex) {
        return;
      }
      this.reorderLoading = true;
      // Create a copy of localFilteredActiveItemTypes and update the order locally
      let updatedItemTypes = [...this.localFilteredActiveItemTypes];
      // Update the ordernum property for each element in updatedItemTypes
      updatedItemTypes.forEach((itemType, index) => {
        itemType.ordernum = index;
      });
      this.localFilteredActiveItemTypes = updatedItemTypes;
      // Update the order in the backend
      const ids = updatedItemTypes.map(itemType => itemType.id);
      await this.$store.dispatch('reorderItemTypes', ids);
      this.reorderLoading = false;
    },
    async saveItemType() {
      this.saving = true;
      await this.$store.dispatch('upsertItemType', this.editedItemType);
      await this.saveOrder();
      await this.reloadItemTypes();
      this.saving = false;
      this.closeDialog();
    },
    async saveOrder() {
      try {
        await this.$store.dispatch('reorderItemTypes', this.localFilteredActiveItemTypes.map(it => it.id))
      } catch (e) {
        console.log('Error saving the order of the types', e.Message);
      }
    },
    async toggleItemTypeActive(id, wasActive) {
      if (wasActive) {
        this.editedItemType = this.activeItemTypes.find(it => it.id === id); // Set the editedItemType
      } else {
        this.editedItemType = this.inactiveItemTypes.find(it => it.id === id); // Set the editedItemType
        this.editedItemType.ordernum = this.localFilteredActiveItemTypes.length || 0;
      }
      if (!this.editedItemType) {
        console.warn('toggleItemTypeActive could not find itemType with the id', id);
        return;
      }
      this.saving = true;
      try {
        this.editedItemType.active = !this.editedItemType.active;
        await this.$store.dispatch('upsertItemType', this.editedItemType);
        await this.reloadItemTypes();
        if (wasActive) {
          await this.$store.dispatch('reorderItemTypes', this.localFilteredActiveItemTypes.map(it => it.id))
        }
        await this.reloadItemTypes();
      } catch (e) {
        console.log('Error with itemType soft deletion or activation:', e.Message);
      }
      this.editedItemType = {};
      this.saving = false;
    },

    async reloadItemTypes() {
      this.subtleLoading = true;
      await this.$store.dispatch('reloadItemTypes');
      this.subtleLoading = false;
    },
    openDialog(itemType = null) {
      console.log(itemType);
      if (itemType) {
        this.editedItemType = Object.assign({}, itemType);
        this.dialogTitle = this.$t('item_types.edit_item_type');
      } else {
        this.editedItemType = {
          id: null,
          shortDescription: '',
          longDescription: '',
          ordernum: (this.localFilteredActiveItemTypes ? this.localFilteredActiveItemTypes.length : 0),
        };
        console.log(this.editedItemType);
        this.dialogTitle = this.$t('item_types.new_item_type');
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editedItemType = {};
    },
  },
  async mounted() {
    await this.reloadItemTypes();
  }
}
</script>

<style scoped>
.draggable-item {
  cursor: pointer;
}

.draggable-item:active {
  cursor: grabbing;
}
</style>
