import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.loading)?_c(VRow,{staticClass:"fill-height",attrs:{"align-content":"start","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"10","xl":"8"}},[_c(VBtn,{class:{ 'ma-0 mr-5': _vm.$vuetify.breakpoint.smAndUp, 'mb-3': _vm.$vuetify.breakpoint.xsOnly },attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"color":"primary","dark":""},on:{"click":_vm.exportExcel}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("save_alt")]),_c('span',[_vm._v(_vm._s(_vm.$t('customer.export_pricing')))])],1),_c(VBtn,{class:{ 'ma-0 mr-5': _vm.$vuetify.breakpoint.smAndUp },attrs:{"large":_vm.$vuetify.breakpoint.smAndUp,"color":"primary","dark":""},on:{"click":_vm.importExcel}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("upload")]),_c('span',[_vm._v(_vm._s(_vm.$t('customer.import_pricing')))])],1)],1),_c(VCol,{attrs:{"cols":"12","md":"10","xl":"8"}},[_vm._l((_vm.sortedActiveItemTypes),function(type){return [(_vm.getItemsByItemType(type.id) && _vm.getItemsByItemType(type.id).length)?_c(VCard,{key:type.id,attrs:{"color":"secondary darken-1 mb-5"}},[_c(VCardTitle,{staticClass:"accent--text"},[_vm._v(" "+_vm._s(type.shortDescription)+" ")]),(_vm.getItemsByItemType(type.id) && _vm.getItemsByItemType(type.id).length)?_c(VDataTable,{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.getItemsByItemType(type.id),"items-per-page":_vm.getItemsByItemType(type.id).length,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{key:item.id + '-code'},[_vm._v(_vm._s(item.code))])]}},{key:"item.shortDescription",fn:function(ref){
var item = ref.item;
return [_c('span',{key:item.id + '-shortDescription'},[_vm._v(_vm._s(item.shortDescription))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{key:item.id + '-price'},[_vm._v(_vm._s(item.price)+" / "+_vm._s(item.unit))])]}},{key:"item.customerPrice",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{key:item.id + '-customerPrice',ref:("customerPrice-" + (item.id)),refInFor:true,staticClass:"mt-2 mb-n2 pb-0 pt-0 price-field",attrs:{"disabled":_vm.loadingItems.includes(item.id),"rules":_vm.priceRules,"inputmode":"decimal","prefix":"€","reverse":"","step":"0.01","type":"number","validate-on-blur":""},on:{"input":function($event){return _vm.handleCustomerPriceChange(item, $event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loadingItems.includes(item.id))?_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"20"}}):_vm._e()]},proxy:true}],null,true),model:{value:(item.customerPrice),callback:function ($$v) {_vm.$set(item, "customerPrice", _vm._n($$v))},expression:"item.customerPrice"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"disabled":item.customerPrice == null,"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.removeCustomerPrice(item)}}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}],null,true)}):_vm._e()],1):_vm._e()]})],2)],1):_vm._e(),_c('input',{ref:"importFileInput",staticStyle:{"display":"none"},attrs:{"multiple":false,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel","type":"file"},on:{"change":_vm.onImportFileChanged}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }