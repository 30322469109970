<template>
  <v-container :fill-height="loading" class="single-equipment-view fill-height" fluid pa-0>
    <v-progress-linear
      v-show="subtleLoading"
      :indeterminate="true"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
    ></v-progress-linear>

    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-back-button fallback="equipments"/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{
          equipmentCheck.id ? $t('equipments.equipment_checks.edit') : $t('equipments.equipment_checks.new_equipment_check')
        }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-container>
        <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
          <v-col cols="12" md='8' xl='6'>
            <v-card v-if="equipmentCheck" class="equipment-check-form" color="secondary darken-1">
              <v-card-title class="accent--text text-break">
                {{
                  equipmentCheck.id ? $t('equipments.equipment_checks.equipment') : $t('equipments.equipment_checks.create_check_for_equipment')
                }}:
                {{ equipment.description }}
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                {{ $t('equipments.equipment_checks.steps_help') }}
              </v-card-text>
              <v-list class="equipment-check-steps transparent">
                <template v-for="step in equipmentCheckSteps">
                  <v-list-item :key="step" dark>
                    <v-list-item-content>
                      <v-list-item-title
                        :class="validationErrors.findIndex(ve => ve === step) > -1 ? 'error--text font-weight-bold' : ''"
                        class="text-wrap body-1">
                        {{ $t(`equipments.equipment_checks.step.${step}`) }}
                      </v-list-item-title>
                      <div class="mt-2 body-2">
                        <div @click="toggleStepApprove(step, 'true')">
                          <v-text-field :class="equipmentCheck[step] === 'true' ? 'selected success darken-4' : ''"
                                        :disabled='saving || !!equipmentCheck.id'
                                        :placeholder="$t('equipments.equipment_checks.ok')"
                                        class="rounded-b-0 body-2 accept-step-input"
                                        hide-details
                                        outlined
                                        readonly>
                            <template v-slot:prepend-inner>
                              <v-btn :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'success'"
                                     class="mb-n1 mr-2"
                                     icon
                                     outlined
                                     x-small
                                     @click.stop="toggleStepApprove(step, 'true')">
                                <v-fab-transition hide-on-leave>
                                  <v-icon v-if="equipmentCheck[step] === 'true'" size="16">mdi mdi-check-bold</v-icon>
                                  <v-icon v-if="validationErrors.findIndex(ve => ve === step) > -1" size="10">mdi
                                    mdi-exclamation
                                  </v-icon>
                                </v-fab-transition>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </div>
                        <div @click="toggleStepApprove(step, 'false')">
                          <v-text-field v-model="equipmentCheck[step + 'Fault']"
                                        :class="equipmentCheck[step] === 'false' ? 'selected warning darken-4' : ''"
                                        :disabled='saving || !!equipmentCheck.id'
                                        :placeholder="equipmentCheck[step] === 'false' ? '' : $t('equipments.equipment_checks.fault')"
                                        class="rounded-t-0 body-2 accept-step-input"
                                        hide-details
                                        outlined
                                        style="margin-top: -1px;"
                                        @blur="validateFault(step)">
                            <template v-slot:prepend-inner>
                              <v-btn :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'warning'"
                                     class="mb-n1 mr-2"
                                     icon
                                     outlined
                                     x-small
                                     @click.stop="toggleStepApprove(step, 'false')">
                                <v-fab-transition hide-on-leave>
                                  <v-icon v-if="equipmentCheck[step] === 'false'" size="16">mdi mdi-close</v-icon>
                                  <v-icon v-if="validationErrors.findIndex(ve => ve === step) > -1" size="12">mdi
                                    mdi-exclamation
                                  </v-icon>
                                </v-fab-transition>
                              </v-btn>
                            </template>
                            <template v-if="equipmentCheck.id" v-slot:append>
                              <v-btn v-if="equipmentCheck[step] === 'false' && !equipmentCheck[step + 'Fixed']"
                                     :loading="fixingStep === step"
                                     class="fix-button"
                                     x-small
                                     @click="markFixed(step)">
                                {{ $t('equipments.equipment_checks.mark') }}
                                <br v-if="$vuetify.breakpoint.xsOnly"/>
                                {{ $t('equipments.equipment_checks.as_fixed') }}
                              </v-btn>
                              <v-chip v-if="equipmentCheck[step + 'Fixed']" color="success" small>
                                {{ $t('equipments.equipment_checks.fixed') }}
                                {{ $formatDateNoTime(equipmentCheck[step + 'Fixed'] * 1000) }}
                              </v-chip>
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
              <v-card-text>
                <v-checkbox
                  v-model="equipmentCheck.daily"
                  :disabled='saving || !!equipmentCheck.id'
                  :label="$t('equipments.equipment_checks.checked_every_day')"
                ></v-checkbox>
              </v-card-text>
              <v-card-text>
                <v-textarea
                  v-model="equipmentCheck.info"
                  :disabled='saving || !!equipmentCheck.id'
                  :label="$t('equipments.equipment_checks.additional_info')"
                  autocomplete='off'
                  dense
                  hide-details
                  outlined/>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text v-if="!equipmentCheck.id" class="pb-0">
                <v-alert v-if="validationErrors.length > 0" text type="error">
                  {{ $t('equipments.introduction.validation_error_message') }}
                </v-alert>
                <div class="text-center my-5">
                  {{ $t('equipments.equipment_checks.what_signing_means') }}
                </div>
                <v-btn
                  :loading="saving"
                  :small="$vuetify.breakpoint.xsOnly"
                  block
                  color='primary'
                  @click='saveEquipmentCheck'
                >
                  {{ $t('equipments.equipment_checks.save') }}
                </v-btn>
              </v-card-text>
              <v-card-text v-else class="pb-0">
                <v-alert v-if="equipmentCheck.workerUser" class="mb-0" text type="info">
                  {{ $t('equipments.equipment_checks.user_that_did_the_equipment_check') }}:
                  <strong v-if="equipmentCheck.workerUser.firstName && equipmentCheck.workerUser.lastName">
                    {{ equipmentCheck.workerUser.firstName }} {{ equipmentCheck.workerUser.lastName }}
                  </strong>
                  <strong v-else>
                    {{ equipmentCheck.workerUser.email }}
                  </strong>
                </v-alert>
                <v-alert v-if="equipmentCheck.inspectorUser" class="mb-0 mt-4" text type="success">
                  {{ $t('equipments.equipment_checks.supervisor_user') }}:
                  <strong v-if="equipmentCheck.inspectorUser.firstName && equipmentCheck.inspectorUser.lastName">
                    {{ equipmentCheck.inspectorUser.firstName }} {{ equipmentCheck.inspectorUser.lastName }}
                  </strong>
                  <strong v-else>
                    {{ equipmentCheck.inspectorUser.email }}
                  </strong>
                </v-alert>
              </v-card-text>
              <v-card-text>
                <template v-if="equipmentCheck.id && !equipmentCheck.inspectorUserId && ($isAdmin || $isSuperior)">
                  <v-btn
                    :loading="saving"
                    :small="$vuetify.breakpoint.xsOnly"
                    block
                    class="mb-4"
                    color='primary'
                    @click='markInspected'
                  >
                    {{ $t('equipments.equipment_checks.mark_inspected') }}
                  </v-btn>
                </template>
                <v-btn
                  :disabled="saving"
                  :small="$vuetify.breakpoint.xsOnly"
                  block
                  depressed
                  @click='closeEquipmentCheck'
                >
                  {{ $t('equipments.equipment_checks.close') }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </v-container>
</template>

<script>
import equipmentChecksApi from "@/api/equipmentcheck";
import moment from 'moment';

export default {
  name: "SingleEquipmentCheckView",
  props: ['equipmentId', 'id'],
  data() {
    return {
      saving: false,
      loading: true,
      subtleLoading: false,
      selectedEquipment: null,
      equipmentCheck: {},
      equipmentCheckTemplate: {
        contractor: null,
        contactPerson: null,
        phone: null,
        info: null,
        daily: null,
        koneenhavaittavuus: null,
        koneenhavaittavuusFault: null,
        koneenhavaittavuusFixed: null,
        valaisimetjasuuntavalaisimet: null,
        valaisimetjasuuntavalaisimetFault: null,
        valaisimetjasuuntavalaisimetFixed: null,
        hydrauliikka: null,
        hydrauliikkaFault: null,
        hydrauliikkaFixed: null,
        akselistotjavaihteistot: null,
        akselistotjavaihteistotFault: null,
        akselistotjavaihteistotFixed: null,
        nostokoukut: null,
        nostokoukutFault: null,
        nostokoukutFixed: null,
        laitekiinnitykset: null,
        laitekiinnityksetFault: null,
        laitekiinnityksetFixed: null,
        ajojahallintalaitteet: null,
        ajojahallintalaitteetFault: null,
        ajojahallintalaitteetFixed: null,
        turvakatkaisijat: null,
        turvakatkaisijatFault: null,
        turvakatkaisijatFixed: null,
        suojauksetjasuojalaitteet: null,
        suojauksetjasuojalaitteetFault: null,
        suojauksetjasuojalaitteetFixed: null,
        alkusammutinensiapulaukku: null,
        alkusammutinensiapulaukkuFault: null,
        alkusammutinensiapulaukkuFixed: null,
        koneenhuoltokayttoturvallisuus: null,
        koneenhuoltokayttoturvallisuusFault: null,
        koneenhuoltokayttoturvallisuusFixed: null,
        koneenmerkinnatjakilvet: null,
        koneenmerkinnatjakilvetFault: null,
        koneenmerkinnatjakilvetFixed: null,
        koneenlisalaitteidenturvallisuus: null,
        koneenlisalaitteidenturvallisuusFault: null,
        koneenlisalaitteidenturvallisuusFixed: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiin: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiinFault: null,
        koneenkuljettajaperehdytystyomaanolosuhteisiinFixed: null,
        tyokonekunnossa: null,
        tyokonekunnossaFault: null,
        tyokonekunnossaFixed: null,
        tyokonevaatimustenmukainen: null,
        tyokonevaatimustenmukainenFault: null,
        tyokonevaatimustenmukainenFixed: null,
      },
      equipmentCheckSteps: [
        'koneenhavaittavuus', 'valaisimetjasuuntavalaisimet', 'hydrauliikka', 'akselistotjavaihteistot', 'nostokoukut',
        'laitekiinnitykset', 'ajojahallintalaitteet', 'turvakatkaisijat', 'suojauksetjasuojalaitteet', 'alkusammutinensiapulaukku',
        'koneenhuoltokayttoturvallisuus', 'koneenmerkinnatjakilvet', 'koneenlisalaitteidenturvallisuus',
        'koneenkuljettajaperehdytystyomaanolosuhteisiin', 'tyokonekunnossa', 'tyokonevaatimustenmukainen'
      ],
      validationErrors: [],
      fixingStep: null,
    }
  },
  methods: {
    validateFault(step) {
      if (this.equipmentCheck[step + 'Fault'] === null || this.equipmentCheck[step + 'Fault'].length === 0) {
        this.equipmentCheck[step] = null;
      }
    },
    selectEquipmentForCheck() {
      this.equipmentCheck = {
        ...this.equipmentCheckTemplate,
        equipment: this.equipment
      };
    },
    toggleStepApprove(step, value) {
      if (this.equipmentCheck.id) return;
      if (this.equipmentCheck[step] === value) {
        this.equipmentCheck[step] = null;
      } else {
        this.equipmentCheck[step] = value;
        const validationErrorIndex = this.validationErrors.findIndex(ve => ve === step);
        if (validationErrorIndex > -1) {
          this.validationErrors.splice(validationErrorIndex, 1)
        }
      }
    },
    async getEquipmentCheck() {
      try {
        const equipmentCheck = await equipmentChecksApi.getEquipmentCheck(this.id);
        if (equipmentCheck) {
          this.equipmentCheck = equipmentCheck;
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    async saveEquipmentCheck() {
      this.saving = true;
      try {
        const equipmentCheck = {...this.equipmentCheck};
        this.equipmentCheckSteps.forEach(step => {
          if (equipmentCheck[step] === 'true') {
            equipmentCheck[step + 'Fault'] = null;
          }
        });
        if (!this.equipmentCheck.id) {
          const response = await equipmentChecksApi.createEquipmentCheck(equipmentCheck);
          if (response.id) {
            this.equipmentCheck = await equipmentChecksApi.markEquipmentCheckChecked(response.id); // merkitään samalla tarkastus suoritetuksi
          }
        } else {
          this.equipmentCheck = await equipmentChecksApi.updateEquipmentCheck(equipmentCheck.id, equipmentCheck);
        }
        this.$showSuccessNotification(this.$t('equipments.equipment_checks.equipment_check_saved'));
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    async markInspected() {
      this.saving = true;
      try {
        if (this.equipmentCheck.id) {
          this.equipmentCheck = await equipmentChecksApi.markEquipmentCheckInspected(this.equipmentCheck.id);
        }
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    async markFixed(step) {
      this.fixingStep = step;
      try {
        const equipmentCheck = {...this.equipmentCheck};
        equipmentCheck[step + 'Fixed'] = moment().toISOString();
        this.equipmentCheck = await equipmentChecksApi.updateEquipmentCheck(equipmentCheck.id, equipmentCheck);
      } catch (error) {
        this.$handleApiError(error);
      }
      this.fixingStep = null;
    },
    closeEquipmentCheck() {
      this.$router.go(-1)
    },
  },
  computed: {
    equipments() {
      return this.$store.state.equipments;
    },
    equipment() {
      const equipmentIndex = this.equipments.findIndex(equipment => parseInt(equipment.id) === parseInt(this.equipmentId));
      if (equipmentIndex > -1) {
        return this.equipments[equipmentIndex];
      }
      return false;
    },
  },
  async mounted() {
    this.loading = true;
    if (this.id) {
      await this.getEquipmentCheck();
    } else if (this.equipmentId) {
      await this.selectEquipmentForCheck()
    }
    this.loading = false;
    this.$vuetify.goTo(0);
  }
}
</script>

<style lang="scss">
.equipment-check-form {
  .selected {
    font-weight: 600;
  }

  .equipment-check-steps {
    .theme--light.v-input input {
      &::placeholder {
        color: rgba(255, 255, 255, 1);
      }

      &:focus {
        font-weight: 600;
      }

      &:focus::placeholder {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .v-input--is-disabled input {
    color: rgba(255, 255, 255, 0.68);
  }

  .accept-step-input {

    input::placeholder {
      color: rgba(255, 255, 255, 0.9);
    }

    * {
      cursor: pointer !important;
    }

    &.v-input--is-disabled * {
      cursor: default !important;
    }

    .fix-button, .fix-button * {
      pointer-events: all !important;
      cursor: pointer !important;
      height: 30px;
      padding-top: 2px;
      margin-top: -4px;
    }
  }
}
</style>
