<template>
  <v-container fluid :fill-height="loading" class="user-profile">

    <v-progress-linear style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99;" :style="{top: $vuetify.breakpoint.smAndDown ? '54px' : '62px'}" color="primary lighten-2" height="2" v-show="subtleLoading" :indeterminate="true"></v-progress-linear>

    <v-app-bar :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'" dark fixed app :elevate-on-scroll="$vuetify.breakpoint.smAndDown">

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button />
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        {{ $t('user_profile.title') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" style="position: relative;" justify="center">

        <v-col cols="12" md="6">
          <v-card color="secondary darken-1">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar :size="$vuetify.breakpoint.xsOnly ? '68' : '48'" :color="user.enabled ? $randomizeColorFromString(user.email ? user.email : user.subject) : 'grey lighten-2'" class="user-avatar" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  <!--<img :src="$getGravatarUrl(user.email)" alt="Avatar" />-->
                  <v-icon v-if="!user.agentUser" color="white" style="margin-top: 8px;" :size="$vuetify.breakpoint.xsOnly ? '60' : '42'">fas fa-user</v-icon>
                  <v-icon v-else color="white" style="margin-top: 8px;" :size="$vuetify.breakpoint.xsOnly ? '68' : '46'">fas fa-users-cog</v-icon>
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ user.email ? user.email : user.sub }}</h3>
                <div class="body-1 text-center text-sm-left"><span v-for="role in user.roles" :key="role" :style="{color: $randomizeColorFromString(role, 60, 45)}">{{ $t('user_management.role.' + role) }}</span></div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" text color="primary" @click="openChangePasswordDialog">{{ $t('user_profile.change_password') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <!--
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title primary-title>
              <div>
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="mb-0">{{ $t('user_profile.push_notifications') }}</h3>
                <div class="body-1">{{ $t('user_profile.push_notifications_explained') }}</div>
              </div>
            </v-card-title>
            <v-card-actions>
              <v-switch
                v-model="allowPushNotifications"
                color="primary"
                class="mt-0 mb-2 ml-2"
                hide-details
                :label="$t('user_profile.allow_notifications')"
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-col>
        -->

      </v-row>
    </v-fade-transition>

    <change-password-dialog :user="user" ref="changePasswordDialog" />
  </v-container>
</template>

<script>
  import ChangePasswordDialog from '../components/UserProfile/ChangePasswordDialog.vue';

  export default {
    name: "UserProfile",
    components: {
      ChangePasswordDialog: ChangePasswordDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        user: {},
        allowPushNotifications: false,
      }
    },
    methods: {
      openChangePasswordDialog() {
        this.$refs.changePasswordDialog.open();
      },
    },
    computed: {

    },
    mounted() {
      if (this.$isLoggedIn) {
        this.user = this.$userInfo;
      }
      this.loading = false;
    },
  }
</script>

<style lang="scss">
  .user-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
