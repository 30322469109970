<template>
    <span>{{ duration }}</span>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "DurationDisplay",
    props: {
      entries: {
        type: Array,
        default: () => [],
      }
    },
    data() {
      return {
        currentTime: moment().toISOString(),
      }
    },
    methods: {
      updateCurrentTime() {
        this.currentTime = moment().toISOString();
      },
      getEntryDuration(entry) {
        if (!entry.entryStart) return this.$humanizeDuration(moment.duration(0), false);

        let diff;
        let start = entry.entryStart;
        let isActiveEntry = !entry.entryEnd;
        let end = isActiveEntry ? moment(this.currentTime) : entry.entryEnd;

        if (end.isBefore(start)) {
          diff = start.diff(end);
          return '-' + this.$humanizeDuration(moment.duration(diff), isActiveEntry);
        } else {
          diff = end.diff(start);
          return this.$humanizeDuration(moment.duration(diff), isActiveEntry);
        }
      },
      getCombinedDurations(entries) {
        const end = moment(this.currentTime);
        const start = moment(this.currentTime);
        let hasActiveEntry = false;
        entries.forEach(entry => {
          let diff;
          const entryStart = entry.entryStart;
          const isActiveEntry = !entry.entryEnd;
          const entryEnd = isActiveEntry ? moment(this.currentTime) : entry.entryEnd;

          if (isActiveEntry) hasActiveEntry = true;

          if (entryEnd.isBefore(entryStart)) {
            diff = start.diff(entryEnd);
            start.add(diff, 'milliseconds');
          } else {
            diff = entryEnd.diff(entryStart);
            start.subtract(diff, 'milliseconds');
          }
        });

        let combinedDiff;
        if (end.isBefore(start)) {
          combinedDiff = start.diff(end);
          return '-' + this.$humanizeDuration(moment.duration(combinedDiff), hasActiveEntry);
        } else {
          combinedDiff = end.diff(start);
          return this.$humanizeDuration(moment.duration(combinedDiff), hasActiveEntry);
        }
      },
    },
    computed: {
      duration() {
        if (this.entries.length === 0) return '00:00:00';

        if (this.entries.length === 1) return this.getEntryDuration(this.entries[0]);

        return this.getCombinedDurations(this.entries);
      }
    },
    mounted() {
      this.currentTime = moment().toISOString();
      if (this.entries.findIndex(entry => !entry.entryEnd) > -1) {
        setInterval(this.updateCurrentTime, 1000);
      }
    },
  }
</script>

<style scoped>

</style>
