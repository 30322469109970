<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog" max-width="400" persistent>
    <v-card light>
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>{{ $t('timer.create_new_equipment') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">

          <v-text-field
            v-model="currentEquipment.description"
            :label="$t('equipments.description')"
            :rules="[$rules.required]"
            class="equipmentdescription mt-2"
            type="text"
            validate-on-blur
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" class="mt-4" outlined type="error">{{
            $t('validation.check_all_required_fields')
          }}.
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" color="default" @click="dialog = false">
          <span>{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn :loading="saving" color="primary" @click="saveEquipment">
          <span>{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SimpleEquipmentDialog.vue",
  data() {
    return {
      dialog: false,
      currentEquipment: {},
      equipmentTemplate: {
        description: "",
        status: 10,
      },
      saving: false,
      showAlert: false
    };
  },
  methods: {
    openNew() {
      this.currentEquipment = {...this.equipmentTemplate};
      this.dialog = true;
    },
    async saveEquipment() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }
      this.saving = true;
      const response = await this.$store.dispatch('upsertEquipment', this.currentEquipment);
      if (response) {
        const newEquipment = this.$store.state.equipments[this.$store.state.equipments.length - 1];
        this.$emit('on-dismiss', newEquipment);
        this.dialog = false;
      }
      this.saving = false;
    }
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
      }
    },
  },
}
</script>

<style scoped>

</style>
