<template>
  <v-container v-if="$isAdmin"
               class="timer-view"
               fluid>
    <v-app-bar
        :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
        :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
        app
        dark
        fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{ $tc('site_reports.title', 0) }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-fade-transition>
      <v-row no-gutters>
        <!-- Lataa exceli -->
        <v-col :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : ''"
               :style="{ height: $vuetify.breakpoint.xsOnly ? '0' : 'auto' }"
               cols="12">
          <v-btn
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp }"
              :disabled="!(filteredSiteItems && filteredSiteItems.length)"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :left="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="exportToExcel"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">save_alt</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('reports.download_excel') }}</span>
          </v-btn>
        </v-col>

        <!-- Filtterit alkaa -->
        <v-col cols="12">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-menu
                  ref="dateRangePicker"
                  v-model="dateRangePicker"
                  :close-on-content-click="false"
                  :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="filterDatesText"
                      append-icon="filter_list"
                      background-color="secondary lighten-1"
                      hide-details
                      readonly
                      single-line
                      solo
                      v-on="on"
                  >
                    <template slot="prepend-inner">
                      <v-icon class="mr-2" color="white">event</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-card light>
                  <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                    <v-row no-gutters>
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisWeek">{{ $t('reports.this_week') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastWeek">{{ $t('reports.last_week') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisMonth">{{ $t('reports.this_month') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastMonth">{{ $t('reports.last_month') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisYear">{{ $t('reports.this_year') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastYear">{{ $t('reports.last_year') }}</v-btn>
                      </v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
                  <v-card-text>
                    <v-row>
                      <div v-if="$vuetify.breakpoint.smAndUp" class="mr-1">
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisWeek">
                          {{ $t('reports.this_week') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastWeek">
                          {{ $t('reports.last_week') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisMonth">{{
                            $t('reports.this_month')
                          }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastMonth">{{
                            $t('reports.last_month')
                          }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisYear">
                          {{ $t('reports.this_year') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastYear">
                          {{ $t('reports.last_year') }}
                        </v-btn>
                      </div>
                      <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
                      <v-date-picker
                          v-model="filterDates"
                          :full-width="$vuetify.breakpoint.xsOnly"
                          class="elevation-0"
                          color="secondary"
                          first-day-of-week="1"
                          light
                          locale="fi-fi"
                          no-title
                          range
                          scrollable
                      ></v-date-picker>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                  v-model="selectedCustomer"
                  :items="customers"
                  :placeholder="$t('reports.customer')"
                  append-icon="filter_list"
                  background-color="secondary lighten-1"
                  clearable
                  hide-details
                  item-text="name"
                  item-value="id"
                  single-line
                  solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-map-marked-alt</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                  v-model="selectedSite"
                  :items="filteredSites"
                  :placeholder="$t('reports.site')"
                  append-icon="filter_list"
                  background-color="secondary lighten-1"
                  clearable
                  hide-details
                  item-text="name"
                  item-value="id"
                  multiple
                  single-line
                  solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-map-marked-alt</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-switch
                  v-model="includeBilled"
                  :class="$vuetify.breakpoint.xsOnly ? 'mt-0' : 'my-2'"
                  :label="$t('reports.include_billed')"
                  color="primary"
                  hide-details
                  inset
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
        <!-- Filtterit päättyy -->

        <v-col cols="12">
          <v-data-table v-if="filteredSiteItems && filteredSiteItems.length"
                        :headers="headers"
                        :items="filteredSiteItems"
                        :items-per-page="filteredSiteItems.length"
                        class="transparent"
                        hide-default-footer
          ></v-data-table>
        </v-col>

        <hb-loading-indicator v-if="loading" align-middle/>

      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import moment from 'moment';
import downloadjs from "downloadjs";
import filenameValidator from "@/plugins/filenamevalidator.plugin.js";
import apiCollection from "@/api";

const ExcelJS = require('exceljs');

export default {
  name: 'SiteReports',
  components: {},
  data() {
    return {
      loading: true,
      loadingDownload: false,
      subtleLoading: false,
      searchPhrase: '',
      dateRangePicker: false,
      selectedCustomer: '',
      includeBilled: false,
      siteItems: [],
      customerSiteItems: [],
      filterDates: [moment().startOf('month').toISOString(), moment().endOf('day').toISOString()],
      selectedSite: null,
      offsetTop: 0,
      headers: [
        {
          text: 'Työmaa', value: 'site.name', width: '1px'
        },
        {
          text: 'Nimike', value: 'item.shortDescription',
        },
        {
          text: 'Määrä', value: 'amount', width: '80px'
        },
        {
          text: 'Yksikkö', value: 'item.unit', width: '80px'
        },
        {
          text: 'Hinta', value: 'price', width: '80px'
        },
        {
          text: 'Yhteensä', value: 'priceTotal', width: '80px'
        }
      ]
    };
  },
  watch: {
    async selectedSite(newValue) {
      if (newValue && newValue.length) {
        await this.getSiteItems(newValue);
      }
    },
    async selectedCustomer(newValue) {
      if (newValue) {
        await this.getCustomerSiteItems(newValue);
      }
    }
  },
  methods: {
    async getCustomerSiteItems(customerId) {
      this.subtleLoading = true;
      try {
        const siteItems = await apiCollection.getAllItemsForCustomer(customerId);
        this.customerSiteItems = siteItems.filter(si => si.amount > 0)
      } catch (e) {
        this.$handleApiError(e);
      }
      this.subtleLoading = false;
    },
    async getSiteItems(siteIds) {
      this.loading = true;
      await this.$store.dispatch('loadSiteItems', siteIds);
      this.siteItems = this.$store.getters.getSiteItems;
      this.loading = false;
    },
    sanitizeSiteName(name) {
      return name.replace(/[*?:\\/[\]]/g, ' ');
    },
    async exportToExcel() {
      if (!this.filteredSites || !this.filteredSiteItems.length) {
        return;
      }

      let customer = null;

      if (this.selectedCustomer) {
        for (const currentCustomer of this.customers) {
          if (currentCustomer.id === this.selectedCustomer) {
            customer = currentCustomer;
            break;
          }
        }
      } else {
        for (const siteItem of this.filteredSiteItems) {
          if (siteItem.site && siteItem.site.customer && siteItem.site.customer.name) {
            customer = siteItem.site.customer;
            break;
          }
        }
      }

      if (!customer) {
        customer = {
          id: -1,
          name: 'Ei asiakasta'
        }
      }

      const workbook = new ExcelJS.Workbook();
      const groupedSiteItems = this.groupSiteItemsBySiteId(this.filteredSiteItems);

      let grandTotal = 0;

      const summarySheet = workbook.addWorksheet(this.$t('site_reports.summary_sheet'));
      summarySheet.columns = [
        {header: this.$t('title'), key: 'A', width: 40},
        {header: '', key: 'spacer0', width: 2},
        {header: this.$tc('sites.title', 1), key: 'B', width: 40},
        {
          header: this.$t('items.priceTotal'),
          key: 'C',
          width: 40,
          style: {
            numFmt: '#,##0.00 "€"'
          }
        },
        {header: '', key: 'D', width: 40},
      ]

      summarySheet.addRow({
        A: customer.name
      });

      const headerCellA = summarySheet.getCell('A1');
      headerCellA.font = {...headerCellA.font, size: 16, bold: true}
      const headerCellC = summarySheet.getCell('C1');
      headerCellC.font = {...headerCellC.font, size: 13, bold: true}
      const headerCellD = summarySheet.getCell('D1');
      headerCellD.font = {...headerCellD.font, size: 13, bold: true}
      const customerCell = summarySheet.getCell('A2');
      customerCell.font = {...headerCellA.font, size: 14, bold: true}


      let lastSummarySheetRow = null;

      for (const siteId in groupedSiteItems) {
        const siteName = this.sanitizeSiteName(groupedSiteItems[siteId][0].site.name)
        const worksheet = workbook.addWorksheet(`${siteName}_${siteId}`);
        let rowIndex = 4;

        worksheet.getCell('A1').value = siteName;

        const customerNameCell = worksheet.getCell('A1');
        customerNameCell.font = {...customerNameCell.font, size: 14, bold: true}
        customerNameCell.alignment = {
          ...customerNameCell.alignment,
          wrapText: false,
          vertical: 'middle',
          horizontal: 'left'
        }

        const columns = [
          {header: this.$t('items.item_type'), key: 'type.shortDescription', width: 50},
          {header: this.$t('items.item'), key: 'shortDescription', width: 35},
          {header: this.$t('items.amount'), key: 'amount', width: 10},
          {header: this.$t('items.unit'), key: 'unit', width: 10},
          {
            header: this.$t('items.price'), key: 'price', width: 10, style: {
              numFmt: '#,##0.00 "€"'
            }
          },
          {
            header: this.$t('items.priceTotal'), key: 'priceTotal', width: 15, style: {
              numFmt: '#,##0.00 "€"'
            }
          },
        ];

        columns.forEach((column, index) => {
          const cell = worksheet.getCell(`${String.fromCharCode(65 + index)}3`);
          cell.value = column.header;
          cell.font = {bold: true};
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFC0C0C0'},
          };
          worksheet.getColumn(index + 1).width = column.width;
          if (column.style) {
            worksheet.getColumn(index + 1).style = column.style;
          }
        });

        let total = 0;

        for (const siteItem of groupedSiteItems[siteId]) {
          const newRow = worksheet.getRow(rowIndex);
          newRow.getCell('A').value = siteItem.item.type.shortDescription;
          newRow.getCell('B').value = siteItem.item.shortDescription;
          newRow.getCell('C').value = siteItem.amount;
          newRow.getCell('D').value = siteItem.item.unit;
          newRow.getCell('E').value = siteItem.price;
          newRow.getCell('F').value = siteItem.priceTotal;

          newRow.eachCell({includeEmpty: true}, (cell, colNumber) => {
            if (colNumber <= 6) {
              cell.border = {
                bottom: {style: 'thin'},
              };
            }
          });

          rowIndex++;
          total += siteItem.priceTotal;
        }

        worksheet.addRow([], (++rowIndex).toString());

        const summaryRow = worksheet.addRow(['', '', '', 'Summa:', total], (++rowIndex).toString());
        summaryRow.eachCell((cell, colNumber) => {
          if (colNumber === 6) {
            cell.border = {top: {style: 'double'}};
          }
        });

        lastSummarySheetRow = summarySheet.addRow({
          B: siteName,
          C: total,
        });

        lastSummarySheetRow.eachCell({includeEmpty: true}, (cell, colNumber) => {
          if (colNumber === 3 || colNumber === 4) {
            cell.border = {bottom: {style: 'thin'}}
          }
        })

        grandTotal += total;
      }

      const grandTotalRowNumber = lastSummarySheetRow.number + 2;

      const totals = summarySheet.insertRow(grandTotalRowNumber, {
        B: this.$t('items.priceTotal'),
        C: grandTotal
      })

      totals.eachCell({includeEmpty: true}, (cell, colNumber) => {
        if (colNumber === 3 || colNumber === 4) {
          cell.border = {
            top: {style: 'double'},
          };
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer]);
      const fileName = filenameValidator([`${customer.name}_${this.$formatDateNoTime(this.filterDates[0])}-${this.$formatDateNoTime(this.filterDates[1])}`].filter(Boolean).join('_').concat('.xlsx'));
      downloadjs(blob, fileName);
    },

    groupSiteItemsBySiteId(siteItems) {
      return siteItems.reduce((groups, siteItem) => {
        const siteId = siteItem.site.id;
        if (!groups[siteId]) {
          groups[siteId] = [];
        }
        groups[siteId].push(siteItem);
        return groups;
      }, {});
    },
    selectThisWeek() {
      this.filterDates = [moment().startOf('week').toISOString(), moment().endOf('week').toISOString()];
    },
    selectLastWeek() {
      this.filterDates = [
        moment().subtract(1, 'weeks').startOf('week').toISOString(),
        moment().subtract(1, 'weeks').endOf('week').toISOString(),
      ];
    },
    selectThisMonth() {
      this.filterDates = [moment().startOf('month').toISOString(), moment().endOf('month').toISOString()];
    },
    selectLastMonth() {
      this.filterDates = [
        moment().subtract(1, 'months').startOf('month').toISOString(),
        moment().subtract(1, 'months').endOf('month').toISOString(),
      ];
    },
    selectThisYear() {
      this.filterDates = [moment().startOf('year').toISOString(), moment().endOf('year').toISOString()];
    },
    selectLastYear() {
      this.filterDates = [
        moment().subtract(1, 'years').startOf('year').toISOString(),
        moment().subtract(1, 'years').endOf('year').toISOString(),
      ];
    },
    async reloadAll() {
      if (this.$store.state.entries.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadSites');
      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
  },
  computed: {
    filterDatesText() {
      const filterDates = [];
      this.filterDates.forEach(date => {
        filterDates.push(this.$formatDateNoTime(moment(date)));
      });
      filterDates.sort();
      return filterDates.join(' - ');
    },
    sites() {
      return this.$store.state.sites;
    },
    customers() {
      return this.$store.state.customers;
    },
    filteredSiteItems() {
      const filterSiteItems = (siteItems) => {
        return siteItems.filter(siteItem => {
          let include = true;

          if (this.filterDates && this.filterDates.length) {
            const updated = siteItem.updated ? moment.unix(siteItem.updated) : null;
            const rangeStart = moment(this.filterDates[0]);
            const rangeEnd = moment(this.filterDates[1]);

            if (updated) {
              include = include && updated.isSameOrBefore(rangeEnd) && updated.isSameOrAfter(rangeStart);
            }
          }

          if (!this.includeBilled) {
            include = include && !siteItem.site.billed;
          }

          return include;
        });
      };

      if (!this.selectedCustomer) {
        if (this.selectedSite && this.selectedSite.length) {
          return filterSiteItems(this.siteItems);
        } else {
          return [];
        }
      } else {
        let siteItems = [...this.customerSiteItems];
        if (this.selectedSite && this.selectedSite.length) {
          siteItems = siteItems.filter(siteItem => this.selectedSite.includes(siteItem.site.id));
        }
        return filterSiteItems(siteItems);
      }
    },
    filteredSites() {
      const filterByBilled = (siteItems) => {
        if (this.includeBilled) {
          return siteItems;
        } else {
          return siteItems.filter(siteItem => !siteItem.billed);
        }
      };

      let filteredSites = filterByBilled([...this.sites]);

      if (this.selectedCustomer) {
        filteredSites = filteredSites.filter(site => site.customer !== null && site.customer.id === this.selectedCustomer);
      }

      if (this.filterDates && this.filterDates.length) {
        const rangeStart = moment(this.filterDates[0]);
        const rangeEnd = moment(this.filterDates[1]);

        filteredSites = filteredSites.filter(site => {
          const siteStartDate = site.startDate ? moment(site.startDate) : null;
          const siteEndDate = site.endDate ? moment(site.endDate) : null;

          if (siteStartDate && siteEndDate) {
            return siteStartDate.isSameOrBefore(rangeEnd) && siteEndDate.isSameOrAfter(rangeStart);
          } else if (siteStartDate) {
            return siteStartDate.isSameOrBefore(rangeEnd);
          } else if (siteEndDate) {
            return siteEndDate.isSameOrAfter(rangeStart);
          } else {
            return true;
          }
        });
      }

      return filteredSites.sort((a, b) => {
        return b.id - a.id;
      });
    },
    // entriesByDate() {
    //   const entriesByDate = [];
    //   this.entries.forEach(entry => {
    //     const ebdIndex = entriesByDate.findIndex(ebd => moment(ebd.date).startOf('day').isSame(moment(entry.entryStart).startOf('day')));
    //     if (ebdIndex > -1) {
    //       entriesByDate[ebdIndex].entries.push(entry);
    //     } else {
    //       entriesByDate.push({
    //         date: entry.entryStart,
    //         entries: [entry],
    //       });
    //     }
    //   });
    //   entriesByDate.map(ebd => {
    //     ebd.entries.sort((a, b) => {
    //       if (!a.entryEnd && !b.entryEnd) return 0;
    //       if (!a.entryEnd) return -1;
    //       if (!b.entryEnd) return 1;
    //       return b.entryStart - a.entryStart;
    //     });
    //   });
    //   entriesByDate.sort((a, b) => {
    //     return b.date - a.date;
    //   });
    //   return entriesByDate;
    // },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    if (this.$isLoggedIn) {
      this.reloadAll();
    }
  },
};
</script>

<style scoped></style>
