<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="600" persistent
            @keydown.esc="cancel()">
    <v-card class="secondary darken-1">
      <v-toolbar color="primary" dark>
        <v-toolbar-title class="white--text">{{ newItem ? 'Uusi nimike' : 'Muokkaa nimikettä' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-5 gradient-icons">
        <v-form ref="form">
          <v-text-field
            v-model="item.code"
            :disabled="saving || !isEditable"
            :label="$t('items.code')"
            :rules="[$rules.required]"
            class="custom-input"
            clearable
            required
            type="text"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"

          >
          </v-text-field>

          <v-text-field
            v-model="item.shortDescription"
            :disabled="saving || !isEditable"
            :label="$t('items.short_desc')"
            :rules="[$rules.required]"
            class="custom-input"
            clearable
            required
            type="text"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"

          >
          </v-text-field>

          <v-text-field
            v-model="item.longDescription"
            :disabled="saving || !isEditable"
            :label="$t('items.long_desc')"
            class="custom-input"
            clearable
            required
            type="text"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"

          >
          </v-text-field>

          <v-text-field
            v-model="item.unit"
            :disabled="saving || !isEditable"
            :label="$t('items.unit')"
            :rules="[$rules.required]"
            class="custom-input"
            clearable
            dark
            required
            type="text"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"

          >
          </v-text-field>

          <v-text-field
            v-model.number="item.price"
            :disabled="saving || !isEditable"
            :label="$t('items.price')"
            :rules="[$rules.required]"
            class="custom-input price-field"
            clearable
            required
            type="number"
            inputmode="decimal"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"
          >
          </v-text-field>

          <v-autocomplete
            v-model="item.type"
            :disabled="saving || !isEditable"
            :items="activeTypes"
            :label="$t('items.item_type')"
            :rules="[$rules.required]"
            item-text="name"
            item-value="value"
            validate-on-blur
            v-on:keydown.enter.prevent="newItem ? createItem : saveItem"
          >
          </v-autocomplete>
        </v-form>
        <v-alert :value="showAlert" outlined type="error">{{ $t('validation.check_all_required_fields') }}</v-alert>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''">
        <v-btn
          v-if="item.id"
          :bottom="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          :color="item.active ? 'red' : 'green'"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          @click.native="item.active = !item.active"
        >
          <!-- väreiksi asetettu red ja green koska Vuetify tekee success ja error väreille myös muita tyylittelyjä -->
          <v-icon v-if="item.active">mdi-delete-off</v-icon>
          <v-icon v-else>mdi-delete</v-icon>
          <span v-if="item.active && $vuetify.breakpoint.smAndUp">{{ $t('items.set_inactive') }}</span>
          <span v-if="!item.active && $vuetify.breakpoint.smAndUp">{{ $t('items.set_active') }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :bottom="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          color="default"
          @click.native="cancel()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-close
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span></v-btn
        >
        <v-btn
          v-if="!newItem && isEditable"
          :bottom="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click.native="saveItem()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-content-save
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
        <v-btn
          v-if="newItem"
          :bottom="$vuetify.breakpoint.xsOnly"
          :class="$vuetify.breakpoint.xsOnly ? 'ma-0 pa-0' : ''"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
          color="primary"
          @click.native="createItem()"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">mdi-plus
          </v-icon
          >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export const itemTemplate = {
  id: null,
  active: true,
  code: '',
  shortDescription: '',
  longDescription: '',
  unit: '',
  type: {},
  price: '',
};
export default {
  name: 'AddOrEditItemDialog',
  data() {
    return {
      showAlert: false,
      title: '',
      newItem: null,
      saving: false,
      dialog: false,
      item: {...itemTemplate},
    };
  },
  props: ['types', 'items'],
  computed: {
    isEditable() {
      const uneditableTypes = ['työtehtävä', 'poissaolo']
      return this.newItem || !uneditableTypes.includes(this.item.type)
    },
    itemTypes() {
      return this.types.map(type => {
        return {
          name: `${type.shortDescription}`,
          value: type,
        };
      })
    },
    activeTypes() {
      return this.types.filter(it => it.active).sort((a, b) => {
        return a.ordernum - b.ordernum
      }).map(type => {
        return {
          name: `${type.shortDescription}`,
          value: type,
        }
      })
    }
  },
  methods: {
    open(itemId, newItem, type) {
      this.dialog = true;
      this.newItem = newItem;
      this.$refs.form?.reset();
      this.item = {...itemTemplate};
      if (!this.newItem) {
        this.item.id = itemId;
        this.getItem();
      } else {
        this.item.type = type;
      }
    },
    cancel() {
      this.item = itemTemplate;
      this.newItem = null;
      this.showAlert = false;
      this.dialog = false;
    },
    async saveItem() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      try {
        this.saving = true;
        await this.$store.dispatch('upsertItem', this.item);
        this.$emit('updated');
        this.cancel();
      } catch (err) {
        console.warn(err.Message);
      }
      this.saving = false;
    },
    async createItem() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      try {
        this.saving = true;
        console.log(this.item);
        await this.$store.dispatch('upsertItem', this.item);
        this.$emit('created');
        this.cancel();
      } catch (err) {
        console.log(err.Message);
      }
      this.saving = false;
    },
    async getItem() {
      this.saving = true;
      this.item = {...this.items.find((it) => it.id === this.item.id)};
      this.saving = false;
    },
  },
};
</script>

<style lang="scss">
.price-field {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>




