import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items && _vm.items.length)?_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":"secondary darken-1"}},[_c(VCardTitle,{staticClass:"accent--text text-break"},[(_vm.displayInactiveItems)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('items.inactive_items_title'))+" ")]):_vm._e(),(!_vm.displayInactiveItems && _vm.itemType)?_c('div',[_vm._v(" "+_vm._s(_vm.itemType.shortDescription)+" "),(!_vm.itemType.active)?_c('small',{staticClass:"warning--text"},[_vm._v(_vm._s(_vm.$t('item_types.item_type_inactive')))]):_vm._e()]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c(VSpacer):_vm._e(),_c(VBtn,{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-2' : '',attrs:{"disabled":!_vm.itemType || !_vm.itemType.active,"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.$emit('open-new', _vm.itemType)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t('items.create_new')))])],1)],1),(_vm.items.length > 0)?_c(VCardText,[_c(VDataTable,{staticClass:"transparent items-table",attrs:{"footer-props":{itemsPerPageText: _vm.$t('rows_per_page'), itemsPerPageAllText: _vm.$t('all'),  itemsPerPageOptions: [10, 25, 50, -1]},"header-props":{sortByText: _vm.$t('sort_by')},"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":15,"loading-text":_vm.$t('loading'),"dense":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.code))])]}},{key:"item.shortDescription",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.shortDescription))])]}},{key:"item.longDescription",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.longDescription))])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.unit))])]}},(_vm.displayInactiveItems)?{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"300px","width":"100%"}},[_vm._v(_vm._s(item.type.shortDescription))])]}}:null,{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Number(item.price).toLocaleString('fi', {minimumFractionDigits: 2, maximumFractionDigits: 2}))+" ")])]}}],null,true)})],1):_c(VCardText,[_c(VList,{staticClass:"secondary darken-1",attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.noContent))])],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }