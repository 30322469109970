<template>
  <v-btn icon @click="goBack">
    <v-icon>arrow_back</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: "BackButton",
    props: ['fallback'],
    data() {
      return {}
    },
    methods: {
      goBack() {
        if (this.$store.state.previousRouteName !== null) {
          this.$router.go(-1);
        } else {
          this.$router.push({name: this.fallback });
        }
      },
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
