import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import Touch from 'vuetify/lib/directives/touch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBtnToggle,{staticClass:"mb-4",model:{value:(_vm.workTypeFilter),callback:function ($$v) {_vm.workTypeFilter=$$v},expression:"workTypeFilter"}},[_c(VBtn,{key:0,attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('timer.work_types.0')))]),_c(VBtn,{key:1,attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('timer.work_types.1')))]),_c(VBtn,{key:2,attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('timer.work_types.2')))])],1),_c(VDataTable,{directives:[{def: Touch,name:"touch",rawName:"v-touch.prevent",value:(function () {}),expression:"() => {}",modifiers:{"prevent":true}}],staticClass:"secondary mb-3",staticStyle:{"max-width":"100%"},attrs:{"id":"entries-table","items":_vm.entries,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","locale":"fi-FI","no-data-text":_vm.$t('reports.no_entries'),"mobile-breakpoint":0,"fixed-header":"","height":"280","sort-by":_vm.sortBy,"show-select":"","sort-desc":_vm.descending},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.descending=$event},"update:sort-desc":function($event){_vm.descending=$event}},scopedSlots:_vm._u([{key:"item.entryStartTS",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"t":"s"}},[_vm._v(" "+_vm._s(_vm.$formatDateNoTime(item.entryStart))+" "),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$formatTimeNoDate(item.entryStart))+"-"+_vm._s(_vm.$formatTimeNoDate(item.entryEnd)))])])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.user ? item.user.firstName && item.user.lastName ? ((item.user.firstName) + " " + (item.user.lastName)) : item.user.email : ''))])]}},{key:"item.equipmentString",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"caption"},[_vm._l((item.equipments),function(equipment,index){return [_c('span',{key:equipment.id},[_c('span',[_vm._v(_vm._s(equipment.description))]),(index < item.equipments.length - 1)?_c('span',{staticStyle:{"display":"none"}},[_vm._v(", ")]):_vm._e(),_c('br')])]})],2)]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"caption"},[_vm._v(_vm._s(item.info))])]}},{key:"item.workType",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.$t('timer.work_types.' + item.workType)))])]}}]),model:{value:(_vm.selectedEntriesForSite),callback:function ($$v) {_vm.selectedEntriesForSite=$$v},expression:"selectedEntriesForSite"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }