<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    @keydown.esc="dialog = false"
    @keydown.enter="saveSite"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card color="secondary darken-1">
      <v-toolbar dark color="secondary">
        <v-toolbar-title>{{ currentSite.id ? $t('sites.edit_site') : $t('sites.create_new_site') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <template v-if="$isAdmin">
          <v-btn
            color="warning"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentSite.id && currentSite.status === 10"
            @click="setSiteInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentSite.id && currentSite.status === 0"
            @click="setSiteActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>

        <v-form ref="form">
          <template v-if="$isAdmin">
            <v-subheader class="pl-0">{{ $t('sites.site_visibility') }}</v-subheader>
            <v-switch
              color="primary"
              v-model="currentSite.hidden"
              :label="$t('sites.hidden')"
              persistent-hint
              :hint="$t('sites.hidden_hint')"
              class="mt-0 mb-2"
            ></v-switch>

            <v-subheader class="pl-0">{{ $t('sites.site_information') }}</v-subheader>
          </template>
          <v-text-field
            :class="$isAdmin ? 'mt-0' : 'mt-2'"
            class="mt-0 sitename"
            :label="$t('sites.name')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            v-model="currentSite.name"
          ></v-text-field>
          <v-text-field
            class="mt-0 siteaddress"
            :label="$t('sites.address')"
            :rules="$isAdmin ? [] : [$rules.required]"
            validate-on-blur
            type="text"
            v-model="currentSite.address"
          ></v-text-field>
          <v-autocomplete
            :items="customers"
            :label="$tc('customers.title', 1)"
            item-text="name"
            item-value="id"
            :loading="loadingCustomers"
            v-model="currentSite.customer"
            return-object
            clearable
          />
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <template v-if="$isAdmin">
          <v-btn
            color="warning"
            v-if="$vuetify.breakpoint.smAndUp && currentSite.id && currentSite.status === 10"
            @click="setSiteInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            v-if="$vuetify.breakpoint.smAndUp && currentSite.id && currentSite.status === 0"
            @click="setSiteActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-btn
          color="default"
          @click="dialog = false"
          :disabled="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          @click="saveSite"
          :loading="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SiteDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      currentSite: {},
      siteTemplate: {
        address: '',
        hidden: false,
        name: '',
        status: 10,
        customer: null,
      },
      loadingCustomers: true,
    };
  },
  methods: {
    setSiteInactive() {
      this.currentSite.status = 0;
      this.saveSite();
    },
    setSiteActive() {
      this.currentSite.status = 10;
      this.saveSite();
    },
    async saveSite() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      if (await this.$store.dispatch('upsertSite', this.currentSite)) {
        this.dialog = false;
      }
      this.saving = false;
    },
    openSite(site) {
      this.dialog = true;
      this.reloadCustomers();
      this.currentSite = Object.assign({}, site);
    },
    openNew() {
      this.dialog = true;
      this.reloadCustomers();
      this.currentSite = Object.assign({}, this.siteTemplate);
    },
    async reloadCustomers() {
      this.loadingCustomers = true;
      await this.$store.dispatch('reloadCustomers');
      this.loadingCustomers = false;
    },
  },
  computed: {
    customers() {
      return this.$store.state.customers;
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
