import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.options.width,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":_vm.options.color}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.title))])],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pt-5"},[_vm._v(_vm._s(_vm.message))]),_c(VDivider),_c(VCardActions,{class:_vm.$vuetify.breakpoint.xsOnly && 'flex-column-reverse'},[_c(VSpacer),_c(VBtn,{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"text":""},nativeOn:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),(_vm.options.agree)?_c(VBtn,{class:_vm.$vuetify.breakpoint.xsOnly && 'mb-2',attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":_vm.options.color},nativeOn:{"click":function($event){return _vm.agree()}}},[_vm._v(_vm._s(_vm.options.agree))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }