<template>
  <div v-bind:class="amplifyUI.formSection">
    <div v-bind:class="amplifyUI.sectionHeader">{{ options.header }}</div>
    <div v-bind:class="amplifyUI.sectionBody">
      <div v-bind:class="amplifyUI.formField">
        <div v-bind:class="amplifyUI.inputLabel">{{ $Amplify.I18n.get('New Password') }} *</div>
        <input
          v-bind:class="amplifyUI.input"
          v-model="password"
          type="password"
          :placeholder="$Amplify.I18n.get('New Password')"
          autofocus
        />
      </div>
    </div>
    <div
      v-bind:class="amplifyUI.formField"
      v-for="attribute in options.user.challengeParam.requiredAttributes"
      :attribute="attribute"
      v-bind:key="attribute"
    >
      <div v-bind:class="amplifyUI.inputLabel">{{ attribute.charAt(0).toUpperCase() + attribute.slice(1) }}</div>
      <input
        v-bind:class="amplifyUI.input"
        v-model="requiredAttributes[attribute]"
        :placeholder="attribute.charAt(0).toUpperCase() + attribute.slice(1)"
      />
    </div>
    <div v-bind:class="amplifyUI.sectionFooter">
      <span v-bind:class="amplifyUI.sectionFooterPrimaryContent">
        <button v-if="!sent" v-bind:class="amplifyUI.button" v-on:click="change" :disabled="!password">
          {{ $Amplify.I18n.get('Submit') }}
        </button>
      </span>
      <span v-bind:class="amplifyUI.sectionFooterSecondaryContent">
        <a v-if="!sent" v-bind:class="amplifyUI.a" v-on:click="signIn">{{ $Amplify.I18n.get('Back to Sign In') }}</a>
      </span>
    </div>
    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>
<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'RequireNewPassword',
  props: ['requireNewPasswordConfig'],
  data() {
    return {
      user: '',
      error: '',
      password: '',
      logger: {},
      requiredAttributes: {},
      amplifyUI: AmplifyUI,
    };
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Enter new password'),
        user: {
          challengeParam: {
            requiredAttributes: [],
          },
        },
      };
      return Object.assign(defaults, this.requireNewPasswordConfig || {});
    },
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    setError: function (e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
    checkContact(user) {
      this.$Amplify.Auth.verifiedContact(user)
        .then(() => {
          AmplifyEventBus.$emit('localUser', this.user);
          AmplifyEventBus.$emit('authState', 'signedIn');
        })
        .catch(e => this.setError(e));
    },
    change() {
      this.$Amplify.Auth.completeNewPassword(this.options.user, this.password, this.requiredAttributes)
        .then(() => {
          if (this.options.user.challengeName === 'SMS_MFA') {
            AmplifyEventBus.$emit('localUser', this.options.user);
            AmplifyEventBus.$emit('authState', 'confirmSignIn');
          } else if (this.options.user.challengeName === 'MFA_SETUP') {
            AmplifyEventBus.$emit('localUser', this.options.user);
            AmplifyEventBus.$emit('authState', 'setMfa');
          } else {
            this.checkContact(this.options.user);
          }
        })
        .catch(err => this.setError(err));
    },
    signIn: function () {
      AmplifyEventBus.$emit('authState', 'signIn');
    },
  },
};
</script>
