import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"secondary darken-1"}},[_c(VCardTitle,{staticClass:"accent--text"},[_vm._v(" "+_vm._s(_vm.$t('equipments.equipment_checks.checks'))+" ")]),_c(VDataTable,{staticClass:"transparent",attrs:{"id":"equipment-checks-table","footer-props":{itemsPerPageText: _vm.$t('rows_per_page'), itemsPerPageAllText: _vm.$t('all'),  itemsPerPageOptions: [10, 25, 50, -1]},"header-props":{sortByText: _vm.$t('sort_by')},"headers":_vm.headers,"items":_vm.equipmentChecksForEquipment,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('equipments.equipment_checks.no_equipment_checks'),"locale":"fi-FI"},scopedSlots:_vm._u([{key:"item.workerCheckDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerCheckDate ? _vm.$formatWeekAndYear(item.workerCheckDate * 1000) : '')+" ")]}},{key:"item.inspectedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inspectedDate ? _vm.$formatWeekAndYear(item.inspectedDate * 1000) : '')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.isEquipmentCheckWaitingForInspector(item))?_c(VChip,{staticClass:"ma-1",attrs:{"color":"info","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('equipments.equipment_checks.waiting_for_inspection'))+" ")]):_vm._e(),(_vm.isEquipmentCheckOk(item))?_c(VChip,{staticClass:"ma-1",attrs:{"color":"success","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('equipments.equipment_checks.ok'))+" ")]):_vm._e(),(_vm.equipmentCheckHasUncheckedFaults(item))?_c(VChip,{staticClass:"ma-1",attrs:{"color":"error","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t('equipments.equipment_checks.fault'))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"to":{name: 'equipmentcheck', params: {equipmentId: _vm.equipment.id, id: item.id}},"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('equipments.equipment_checks.open'))+" ")])]}}])}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{class:{ 'ma-0': _vm.$vuetify.breakpoint.smAndUp },attrs:{"bottom":_vm.$vuetify.breakpoint.xsOnly,"fab":_vm.$vuetify.breakpoint.xsOnly,"fixed":_vm.$vuetify.breakpoint.xsOnly,"right":_vm.$vuetify.breakpoint.xsOnly,"to":{name: 'equipmentcheck', params: {equipmentId: _vm.equipment.id}},"color":"success","dark":""}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi mdi-plus")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t('equipments.equipment_checks.create')))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }