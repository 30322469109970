<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    no-click-animation
    persistent
  >
    <v-card :style="$vuetify.breakpoint.xsOnly ? { borderRadius: '0' } : ''" color="secondary darken-1">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>{{ newEntryMode ? $t('timer.create_new_entry') : $t('timer.entry_info') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div
          v-if="dialog"
          :style="{ maxHeight: $vuetify.breakpoint.xsOnly ? 'calc(100vh - 57px)' : 'calc(100vh - 250px)' }"
          style="overflow: auto"
        >
          <v-card-text>
            <v-row>
              <v-col class="pb-0" cols="12">
                <v-combobox
                  v-if="adminMode && users && users.length > 0"
                  id="worker-select"
                  v-model="selectedUser"
                  :disabled="currentEntry.disabled"
                  :items="richerUsers"
                  :label="$t('timer.user')"
                  :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                  :placeholder="$t('timer.choose_user')"
                  :rules="[$rules.required]"
                  class="title"
                  clearable
                  item-text="combinedInfo"
                  item-value="id"
                  style="width: 100%"
                >
                  <template slot="prepend">
                    <div style="width: 25px; text-align: center">
                      <v-icon color="grey">person</v-icon>
                    </div>
                  </template>
                  <template v-slot:selection="data">
                    <div v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</div>
                    <div v-else>{{ data.item.email }}</div>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-title>
                      <template v-if="data.item.firstName">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                      <template v-else>{{ data.item.email }}</template>
                    </v-list-item-title>
                  </template>
                </v-combobox>

                <v-combobox
                  id="customer-select"
                  v-model="selectedCustomer"
                  :disabled="currentEntry.disabled"
                  :items="customers"
                  :label="$t('timer.customer')"
                  :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                  :placeholder="$t('timer.choose_customer')"
                  class="title"
                  clearable
                  item-text="name"
                  item-value="id"
                  style="width: 100%"
                  @change="handleCustomerChange"
                >
                  <template slot="prepend">
                    <div style="width: 25px; text-align: center">
                      <v-icon color="grey" size="18">fas fa-building</v-icon>
                    </div>
                  </template>
                  <template v-slot:selection="data">
                    <div>{{ data.item.name }}</div>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-title>
                      {{ data.item.name }}
                    </v-list-item-title>
                  </template>
                  <template slot="no-data">
                    <v-container fluid pb-5 pt-3>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <div class="body-2 grey--text">{{ $t('timer.no_customers_found') }}</div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-combobox>

                <v-combobox
                  id="site-select"
                  v-model="currentEntry.site"
                  :disabled="currentEntry.disabled"
                  :error-messages="siteError"
                  :items="sites"
                  :label="$t('timer.site')"
                  :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                  :placeholder="$t('timer.choose_site')"
                  class="title"
                  clearable
                  item-text="combinedInfo"
                  item-value="id"
                  style="width: 100%"
                  @change="handleSiteChange"
                >
                  <template slot="prepend">
                    <div style="width: 25px; text-align: center">
                      <v-icon color="grey" size="18">fas fa-map-marked-alt</v-icon>
                    </div>
                  </template>
                  <template v-slot:selection="data">
                    <div>{{ data.item.name }}</div>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-title>
                      {{ data.item.name }}<br/>
                      <span v-if="data.item && data.item.customer" class="caption grey--text">
                        {{ data.item.customer && data.item.customer.name }}&nbsp;{{ data.item.address && '-' }}
                      </span>
                      <span class="caption grey--text">{{ data.item.address }}</span>
                    </v-list-item-title>
                  </template>
                  <template slot="append-item">
                    <v-divider></v-divider>
                    <v-list-item @click="openCreateSiteDialog">
                      <v-list-item-title class="accent--text"> {{ $t('timer.create_new_site') }}<br/>
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon color="accent">add</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <template slot="no-data">
                    <v-container fluid pb-5 pt-3>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <div class="body-2 grey--text">{{ $t('timer.no_sites_found') }}</div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-combobox>

                <v-select
                  id="equipment-select"
                  v-model="currentEntry.equipmentIds"
                  :disabled="currentEntry.disabled"
                  :items="equipments"
                  :label="$t('timer.equipment')"
                  :placeholder="$t('timer.choose_equipment')"
                  class="title"
                  item-value="id"
                  multiple
                  style="width: 100%"
                >
                  <template slot="prepend">
                    <div style="width: 25px; text-align: center">
                      <v-icon color="grey" size="18">fas fa-snowplow</v-icon>
                    </div>
                  </template>
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-if="item === Object(item)"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      class="ml-0"
                      label
                      small
                      v-bind="attrs"
                    >
                      <span class="pr-2">
                        {{ item.description.length > 10 ? item.description.substring(0, 10) + '..' : item.description }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-title>{{ data.item.description }}</v-list-item-title>
                  </template>
                  <template v-if="$isSuperior || $isAdmin" slot="append-item">
                    <v-divider></v-divider>
                    <v-list-item @click="openCreateEquipmentDialog">
                      <v-list-item-title class="accent--text"> {{ $t('timer.create_new_equipment') }}<br/>
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon color="accent">add</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-select>

                <div class="mb-5">
                  <div
                    style="width: 25px; padding-top: 4px; text-align: center; display: inline-block; margin-right: 8px">
                    <v-icon color="grey" size="18">fas fa-euro-sign</v-icon>
                  </div>
                  <div style="display: inline-block">
                    <label class="caption" style="color: hsla(0, 0%, 100%, 0.7)">{{ $t('timer.work_type') }}</label
                    ><br/>
                    <v-btn-toggle v-model="currentEntry.workType" class="elevation-0" mandatory>
                      <template v-for="wt in workTypes">
                        <v-btn :key="wt" :disabled="currentEntry.disabled" :value="wt" small style="font-size: 14px">{{
                            $t('timer.work_types.' + wt)
                          }}
                        </v-btn>
                      </template>
                    </v-btn-toggle>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col class="pb-0" cols="12">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-dialog
                      ref="startTimeDialog"
                      v-model="startTimeModal"
                      :disabled="currentEntry.disabled"
                      :return-value.sync="currentEntry.entryStartTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="currentEntry.entryStartTime"
                          :disabled="currentEntry.disabled"
                          :label="$t('timer.start_time')"
                          class="title"
                          readonly
                          v-on="on"
                        >
                          <template slot="prepend">
                            <div style="width: 25px; text-align: center">
                              <v-icon color="grey" size="18">fas fa-clock</v-icon>
                            </div>
                          </template>
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startTimeModal"
                        v-model="currentEntry.entryStartTime"
                        :allowed-minutes="[0, 30]"
                        color="secondary"
                        format="24hr"
                        full-width
                        light
                      >
                        <v-btn text @click="startTimeModal = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" elevation="0" @click="handleEntryStartTimeChange">{{ $t('ok') }}</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="startDateDialog"
                      v-model="startDateModal"
                      :disabled="currentEntry.disabled"
                      :return-value.sync="currentEntry.entryStartDate"
                      light
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="currentEntry.disabled"
                          :value="$formatDateNoTime(currentEntry.entryStartDate)"
                          class="ml-2 title"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentEntry.entryStartDate"
                        color="secondary"
                        first-day-of-week="1"
                        light
                        locale="fi-fi"
                        scrollable
                      >
                        <v-btn text @click="startDateModal = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" elevation="0" @click="handleEntryStartDateChange">{{ $t('ok') }}</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-dialog
                      ref="endTimeDialog"
                      v-model="endTimeModal"
                      :disabled="currentEntry.disabled"
                      :return-value.sync="currentEntry.entryEndTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="currentEntry.entryEndTime"
                          :disabled="currentEntry.disabled"
                          :label="$t('timer.end_time')"
                          class="title"
                          placeholder="--:--"
                          readonly
                          v-on="on"
                        >
                          <template slot="prepend">
                            <div style="width: 25px; padding-top: 4px; text-align: center"></div>
                          </template>
                        </v-text-field>
                      </template>
                      <v-time-picker
                        v-if="endTimeModal"
                        v-model="currentEntry.entryEndTime"
                        :allowed-minutes="[0, 30]"
                        color="secondary"
                        format="24hr"
                        full-width
                        light
                      >
                        <v-btn text @click="endTimeModal = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" elevation="0" @click="handleEntryEndTimeChange">{{ $t('ok') }}</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-dialog
                      ref="endDateDialog"
                      v-model="endDateModal"
                      :disabled="currentEntry.disabled"
                      :return-value.sync="currentEntry.entryEndDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :disabled="currentEntry.disabled"
                          :value="$formatDateNoTime(currentEntry.entryEndDate)"
                          class="ml-2 title"
                          placeholder="--.--.----"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentEntry.entryEndDate"
                        color="secondary"
                        first-day-of-week="1"
                        light
                        locale="fi-fi"
                        scrollable
                      >
                        <v-btn text @click="endDateModal = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" elevation="0" @click="handleEntryEndDateChange">{{ $t('ok') }}</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <template v-if="dialog">
                  <duration-dialog
                    ref="durationDialog"
                    :disabled="currentEntry.disabled"
                    :end-date="currentEntry.entryEndDate"
                    :end-time="currentEntry.entryEndTime"
                    :start-date="currentEntry.entryStartDate"
                    :start-time="currentEntry.entryStartTime"
                    @on-save="handleDurationDialogSave"
                  />
                </template>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox
                  v-model="currentEntry.containsLunch"
                  :label="$t('timer.contains_lunch')"
                  class="mt-1"
                  color="primary"
                  style="margin-left: 31px"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text :style="{ paddingBottom: $vuetify.breakpoint.xsOnly && newEntryMode ? '120px' : '' }">
            <v-row>
              <v-col class="pb-0" cols="12">
                <v-textarea
                  v-model="currentEntry.info"
                  :disabled="currentEntry.disabled"
                  :label="$t('timer.info')"
                  :placeholder="$t('timer.info_placeholder')"
                  auto-grow
                  rows="2"
                >
                  <template slot="prepend">
                    <div style="width: 25px; padding-top: 4px; text-align: center">
                      <v-icon color="grey" size="20">info</v-icon>
                    </div>
                  </template>
                </v-textarea>

                <div style="margin-left: 34px">
                  <label class="caption" style="color: hsla(0, 0%, 100%, 0.7)">{{ $t('timer.add_images') }}</label>

                  <v-container fluid pa-0>
                    <viewer ref="viewer" :images="images" :options="viewerOptions" class="viewer" @inited="inited">
                      <template slot-scope="scope">
                        <v-row align="stretch" justify="start" no-gutters>
                          <template v-if="scope.images.length > 0 || previewImages.length > 0">
                            <v-col v-for="(image, index) in scope.images" :key="image.source" class="pt-2 pr-2"
                                   cols="3">
                              <v-card class="d-flex" flat style="position: relative" tile>
                                <v-img
                                  :data-source="image.source"
                                  :src="image.thumbnail"
                                  aspect-ratio="1"
                                  @click="openFullImage(index)"
                                >
                                  <template v-slot:placeholder>
                                    <v-row align="center" class="ma-0 fill-height" justify="center">
                                      <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <img :data-source="image.source" :src="image.thumbnail" class="image"
                                     style="display: none"/>
                                <v-btn :disabled="currentEntry.disabled" icon
                                       style="position: absolute; top: -2px; right: -2px"
                                >
                                  <v-icon color="error" size="30" @click="deleteImage(image)">delete</v-icon>
                                </v-btn
                                >
                              </v-card>
                            </v-col>
                            <v-col v-for="image in previewImages" :key="image.index" class="pt-2 pr-2" cols="3">
                              <v-card class="d-flex" flat style="position: relative" tile>
                                <v-img :src="image.data" aspect-ratio="1">
                                  <template v-slot:placeholder>
                                    <v-row align="center" class="ma-0 fill-height" justify="center">
                                      <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <v-btn :disabled="currentEntry.disabled" icon
                                       style="position: absolute; top: -2px; right: -2px"
                                >
                                  <v-icon color="error" size="30" @click="deletePreviewImage(image)">delete</v-icon>
                                </v-btn
                                >
                              </v-card>
                            </v-col>
                          </template>
                          <v-col class="pt-2 pr-2" cols="3">
                            <v-card
                              :disabled="currentEntry.disabled"
                              class="text-xs-center fill-height"
                              elevation="2"
                              style="cursor: pointer"
                              @click="addImage"
                            >
                              <v-img aspect-ratio="1">
                                <template v-slot:placeholder>
                                  <v-row align="center" class="fill-height" justify="center" no-gutters>
                                    <v-icon size="28">add_a_photo</v-icon>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </viewer>
                  </v-container>
                </div>

                <v-text-field
                  v-model="currentEntry.distance"
                  :disabled="currentEntry.disabled"
                  :label="$t('timer.distance')"
                  :placeholder="$t('timer.distance_placeholder')"
                  class="mt-5"
                  suffix="km"
                >
                  <template slot="prepend">
                    <div style="width: 25px; padding-top: 4px; text-align: center">
                      <v-icon color="grey" size="20">fas fa-car</v-icon>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12">
                <v-checkbox v-model="currentEntry.invoiced" :label="$t('reports.invoiced')" class="mt-1"
                            color="primary">
                  <template slot="prepend">
                    <div style="width: 25px; padding-top: 2px; text-align: center">
                      <v-icon color="grey" size="20">fas fa-euro-sign</v-icon>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>

          <template v-if="$vuetify.breakpoint.xsOnly && !newEntryMode">
            <v-divider></v-divider>
            <v-card-text :style="{ paddingBottom: $vuetify.breakpoint.xsOnly ? '120px' : '' }">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-icon color="grey" style="margin-right: 10px">delete</v-icon>
                  <v-btn :disabled="saving || currentEntry.disabled" color="warning" outlined small
                         @click="deleteEntry">{{
                      $t('delete_entry')
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </div>
      </v-form>

      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
        <v-btn
          v-if="$vuetify.breakpoint.smAndUp && !newEntryMode"
          :disabled="saving || currentEntry.disabled"
          :loading="deleting"
          color="warning"
          @click="deleteEntry"
        >
          <span>{{ $t('delete') }}</span>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          :bottom="$vuetify.breakpoint.xsOnly"
          :disabled="saving || deleting"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
          color="default"
          @click="dialog = false"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>

        <v-btn
          :bottom="$vuetify.breakpoint.xsOnly"
          :color="newEntryMode ? 'success' : 'primary'"
          :disabled="currentEntry.disabled || deleting"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :loading="saving"
          :right="$vuetify.breakpoint.xsOnly"
          @click="saveEntry"
        >
          <template v-if="newEntryMode && (!currentEntry.entryEndTime || !currentEntry.entryEndDate)">
            <v-icon v-if="$vuetify.breakpoint.xsOnly">play_arrow</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('start') }}</span>
          </template>
          <template v-else>
            <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>

    <simple-site-dialog ref="siteDialog" @on-dismiss="updateSiteForEntry"/>
    <simple-equipment-dialog ref="equipmentDialog" @on-dismiss="updateEquipmentForEntry"/>
    <input ref="imageFileInput" :multiple="false" accept="image/*" style="display: none" type="file"
           @change="onImageFileChanged"/>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import DurationDialog from './DurationDialog.vue';
import SimpleSiteDialog from './SimpleSiteDialog.vue';
import EntryService from '../../service/entry.service';
import '../../../node_modules/viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
import SimpleEquipmentDialog from './SimpleEquipmentDialog.vue';

const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'EntryDialog',
  components: {
    SimpleEquipmentDialog,
    DurationDialog: DurationDialog,
    SimpleSiteDialog: SimpleSiteDialog,
    Viewer: Viewer,
  },
  props: {
    adminMode: {
      type: Boolean,
      default: false,
    },
    users: {
      type: [Boolean, Array],
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      workTypes: [0, 1, 2],
      images: [],
      startTimeModal: false,
      startDateModal: false,
      endTimeModal: false,
      endDateModal: false,
      currentEntry: {},
      selectedUser: null,
      entryTemplate: {
        id: null,
        info: '',
        entryStart: null,
        entryStartDate: null,
        entryStartTime: null,
        entryEnd: null,
        entryEndDate: null,
        entryEndTime: null,
        siteId: null,
        site: null,
        equipmentIds: [],
        workType: null,
        containsLunch: false,
      },
      newEntryMode: false,
      saving: false,
      deleting: false,
      addedImages: [],
      previewImages: [],
      previewIndex: 0,
      viewerOptions: {
        inline: false,
        button: false,
        navbar: false,
        focus: false,
        title: false,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: false,
        fullscreen: false,
        keyboard: true,
        url: 'data-source',
      },
      siteError: '',
      selectedCustomer: null,
      fromTimerView: false,
    };
  },
  methods: {
    handleCustomerChange(customer) {
      if (
        !customer ||
        (this.currentEntry.site && this.currentEntry.site.customer && customer && this.currentEntry.site.customer.id !== customer.id)
      ) {
        this.$set(this.currentEntry, 'site', null);
        this.$set(this.currentEntry, 'siteId', null);
      }
    },
    handleSiteChange(site) {
      if (site) {
        this.selectedCustomer = site.customer;
      } else if (this.currentEntry.site && this.currentEntry.site.customer) {
        this.selectedCustomer = this.currentEntry.site.customer;
      }
    },
    updateSiteForEntry(site) {
      if (site.id) {
        this.$set(this.currentEntry, 'site', site);
        this.$set(this.currentEntry, 'siteId', site.id);
        if (site.customer) {
          this.selectedCustomer = site.customer;
        }
      }
    },
    updateEquipmentForEntry(equipment) {
      console.log(JSON.stringify(equipment, null, 2));
      if (equipment) {
        if (!this.equipments.some(eq => eq.id === equipment.id)) {
          this.equipments.push(equipment);
        }
        this.currentEntry.equipmentIds.push(equipment.id);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    openFullImage(index) {
      this.$viewer.view(index);
    },
    isEntryValid() {
      return EntryService.isEntryValid(this.currentEntry, this.$userInfo);
    },
    deletePreviewImage(image) {
      this.previewImages = this.previewImages.filter(ig => ig.index !== image.index);
      this.addedImages = this.addedImages.filter(ig => ig.previewIndex !== image.index);
    },
    async deleteImage(image) {
      this.saving = true;
      try {
        await this.$api.deleteImage(image.key);
        this.images = this.images.filter(img => img.key !== image.key);
      } catch (error) {
        console.log(error);
      }
      this.saving = false;
    },
    getImageUrl(image) {
      return `${this.$baseUrl}/api/image/${image.key}`;
    },
    getImageUrlLazy(image) {
      return `${this.$baseUrl}/api/image/${image.key}?thumbnail=true`;
    },
    async loadImages() {
      try {
        const images = await this.$api.getEntryImages(this.currentEntry.id);
        images.forEach(img => {
          img.thumbnail = this.getImageUrlLazy(img);
          img.source = this.getImageUrl(img);
        });
        this.images = images;
      } catch (error) {
        console.log(error);
      }
    },
    async onImageFileChanged(fileAddedEvent) {
      if (!fileAddedEvent.target.files[0]) {
        return;
      }

      const image = {
        file: fileAddedEvent.target.files[0],
        filename: fileAddedEvent.target.value.split('\\').pop(),
      };

      const entryId = this.currentEntry.id;
      if (!this.newEntryMode) {
        this.saving = true;
        try {
          const imageResponse = await this.$api.uploadImage(entryId, image.file, image.filename);
          imageResponse.thumbnail = this.getImageUrlLazy(imageResponse);
          imageResponse.source = this.getImageUrl(imageResponse);
          this.images.push(imageResponse);
        } catch (error) {
          console.log(error);
        }
        this.saving = false;
      } else {
        const reader = new FileReader();
        const ind = (this.previewIndex += 1);

        reader.onload = e => {
          const ig = {
            data: e.target.result,
            index: ind,
          };
          this.previewIndex += 1;
          this.previewImages.push(ig);
        };
        reader.readAsDataURL(image.file);
        image.previewIndex = ind;
        this.addedImages.push(image);
      }
    },
    openNew(fromTimerView = false) {
      this.newEntryMode = true;
      this.fromTimerView = fromTimerView;
      this.currentEntry = Object.assign({}, this.entryTemplate);
      this.syncEntryTimesAndDates();
      this.dialog = true;
    },
    openEntry(entry, stopTimer = false, copy = false, canModify = true, fromTimerView = false) {
      this.newEntryMode = copy;
      this.fromTimerView = fromTimerView;
      const entryCopy = Object.assign({}, entry);
      entryCopy.entryEndTime = null;
      this.currentEntry = entryCopy;

      if (entryCopy.site && entryCopy.site.customer) {
        this.selectedCustomer = entryCopy.site.customer;
      }

      if (this.adminMode && this.users.length > 0) {
        const userIndex = this.users.findIndex(user => user.id === this.currentEntry.userId);
        if (userIndex > -1) {
          this.selectedUser = this.users[userIndex];
        }
      }

      if (!canModify) {
        this.currentEntry.disabled = true;
      }

      if (stopTimer) {
        this.currentEntry.entryEnd = moment(moment().format(dateFormat) + ' ' + this.getClosestHalfHourInterval());
        this.checkIfEntryShouldContainLunch();
      }
      this.syncEntryTimesAndDates();

      if (!copy) {
        this.loadImages();
      }

      this.dialog = true;
    },
    syncEntryTimesAndDates() {
      if (!this.currentEntry.entryStart) {
        this.currentEntry.entryStartDate = moment().format(dateFormat);
        this.currentEntry.entryStartTime = this.getClosestHalfHourInterval();
      } else {
        this.currentEntry.entryStartDate = this.currentEntry.entryStart.format(dateFormat);
        this.currentEntry.entryStartTime = this.currentEntry.entryStart.format('HH:mm');
      }
      if (!this.currentEntry.entryEnd) {
        this.currentEntry.entryEndDate = moment().format(dateFormat);
      } else {
        this.currentEntry.entryEndDate = this.currentEntry.entryEnd.format(dateFormat);
        this.currentEntry.entryEndTime = this.currentEntry.entryEnd.format('HH:mm');
      }
    },
    handleDurationDialogSave(end) {
      this.currentEntry.entryEnd = moment(end);
      this.currentEntry.entryEndDate = moment(end).format(dateFormat);
      this.currentEntry.entryEndTime = moment(end).format('HH:mm');
    },
    handleTimeChangeInDurationDialog() {
      this.$nextTick(() => {
        this.$refs.durationDialog.updateTimes();
      });
    },
    async undoDelete(copy) {
      copy.deleted = false;
      await this.$store.dispatch('upsertEntry', copy);
      await this.reloadStoreEntries();
    },
    async deleteEntry() {
      this.deleting = true;

      try {
        const copy = Object.assign({}, this.currentEntry);
        if (this.adminMode) {
          copy.userSubject = this.selectedUser.subject;
        } else {
          if (!copy.userSubject) copy.userSubject = this.$userInfo.subject;
        }

        await this.$api.deleteEntry(this.currentEntry.id);

        this.$store.dispatch('showNotification', {
          color: 'orange',
          timeOut: 10000,
          message: this.$t('timer.entry_deleted_succesfully'),
          showButton: true,
          buttonText: this.$t('undo'),
          callback: () => this.undoDelete(copy),
        });

        await this.reloadStoreEntries();

        this.deleting = false;
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.deleting = false;
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_deleting_entry'),
          showButton: false,
        });
      }
    },
    async saveEntry() {
      this.saving = true;
      if (!this.$refs.form.validate()) {
        this.saving = false;
        return;
      }

      if (this.selectedCustomer) {
        if (!this.currentEntry.site) {
          this.siteError = this.$t('timer.site_customer_error');
          this.saving = false;
          return;
        } else if (!this.currentEntry.site.customer) {
          const site = {...this.currentEntry.site};
          site.customer = this.selectedCustomer;
          this.$set(this.currentEntry, 'site', site);
          await this.$store.dispatch('upsertSite', this.currentEntry.site);
        }
      }

      if (this.adminMode) {
        this.currentEntry.userSubject = this.selectedUser.subject;
      } else {
        if (!this.currentEntry.userSubject) this.currentEntry.userSubject = this.$userInfo.subject;
      }
      if (this.currentEntry.site) this.currentEntry.siteId = this.currentEntry.site.id;

      this.currentEntry.entryStart = moment(`${this.currentEntry.entryStartDate} ${this.currentEntry.entryStartTime}`);

      if (this.currentEntry.entryEndDate && this.currentEntry.entryEndTime) {
        this.currentEntry.entryEnd = moment(`${this.currentEntry.entryEndDate} ${this.currentEntry.entryEndTime}`);
      }

      const upsertedEntry = await this.$store.dispatch('upsertEntry', this.currentEntry);

      if (upsertedEntry != null && this.newEntryMode) {
        for (let i = 0; i < this.addedImages.length; i += 1) {
          try {
            const img = this.addedImages[i];
            this.$api.uploadImage(upsertedEntry.id, img.file, img.filename);
          } catch (error) {
            console.log(error);
          }
        }
      }

      await this.reloadStoreEntries();

      if (upsertedEntry != null) {
        this.dialog = false;
      }

      this.saving = false;
    },
    async reloadStoreEntries() {
      if (this.fromTimerView) {
        this.$store.dispatch('reloadMyEntries');
      } else {
        this.$store.dispatch('reloadEntries');
      }
    },
    openCreateSiteDialog() {
      this.$refs.siteDialog.openNew(this.selectedCustomer);
    },
    openCreateEquipmentDialog() {
      this.$refs.equipmentDialog.openNew();
    },
    addImage() {
      this.$refs.imageFileInput.click();
    },
    getClosestHalfHourInterval() {
      const nowMinutes = moment().minutes();
      if (nowMinutes < 45) {
        return moment().format('HH:') + (nowMinutes > 15 ? '30' : '00');
      } else {
        return moment().add(1, 'hours').format('HH:') + '00';
      }
    },
    handleEntryStartDateChange() {
      this.$refs.startDateDialog.save(this.currentEntry.entryStartDate);
      this.currentEntry.entryStart = moment(`${this.currentEntry.entryStartDate} ${this.currentEntry.entryStartTime}`);
      this.currentEntry.entryEndDate = this.currentEntry.entryStartDate;
      this.handleTimeChangeInDurationDialog();
    },
    handleEntryStartTimeChange() {
      this.$refs.startTimeDialog.save(this.currentEntry.entryStartTime);
      this.handleTimeChangeInDurationDialog();
    },
    handleEntryEndDateChange() {
      this.$refs.endDateDialog.save(this.currentEntry.entryEndDate);
      this.handleTimeChangeInDurationDialog();
    },
    handleEntryEndTimeChange() {
      this.$refs.endTimeDialog.save(this.currentEntry.entryEndTime);
      this.handleTimeChangeInDurationDialog();
    },
    checkIfEntryShouldContainLunch() {
      const lunchStart = moment('11:00', 'HH:mm');
      const lunchEnd = moment('11:30', 'HH:mm');
      const entryStart = this.currentEntry.entryStart.clone();
      const entryEnd = this.currentEntry.entryEnd.clone();
      const entryStartedBeforeLunchStart = entryStart.isBefore(lunchStart);
      const entryEndAfterLunchEnd = entryEnd.isAfter(lunchEnd);
      if (entryStartedBeforeLunchStart && entryEndAfterLunchEnd) {
        this.currentEntry.containsLunch = true;
      }
    },
  },
  computed: {
    customers() {
      let customers = [...this.$store.state.customers];
      customers = customers.filter(c => c.status === 'ACTIVE');
      return customers;
    },
    sites() {
      let sites = [...this.$store.state.sites];
      sites = sites.filter(s => {
        if (s.hidden || !!s.billed || (this.selectedCustomer && (!s.customer || this.selectedCustomer.id !== s.customer.id))) return false;
        return parseInt(s.status, 10) !== 0;
      });
      sites.map(site => {
        site.combinedInfo = `${site.name} ${site.address}`;
      });
      sites.sort((a, b) => {
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
      })
      return sites;
    },
    equipments() {
      let equipments = [...this.$store.state.equipments];
      equipments = equipments.filter(e => parseInt(e.status, 10) !== 0);
      return equipments;
    },
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return filteredUsers.map(user => {
        const userCopy = Object.assign({}, user);
        userCopy.combinedInfo = `${userCopy.firstName} ${userCopy.lastName} ${userCopy.email}`;
        return userCopy;
      });
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.images = [];
        this.previewImages = [];
        this.selectedUser = null;
        this.selectedCustomer = null;
        this.siteError = '';
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
