<template>
  <v-col v-if="shouldAlertBeDisplayed" cols="12">
    <v-alert :value="shouldAlertBeDisplayed" prominent outlined type="warning" icon="access_time" class="my-4">
      {{ $t('timer.reminder') }}
    </v-alert>
  </v-col>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "ReminderAlert",
    data() {
      return {
        currentTime: moment(),
      }
    },
    methods: {
      updateCurrentTime() {
        this.currentTime = moment();
      },
    },
    computed: {
      shouldAlertBeDisplayed() {
        const startOfDay = moment().startOf('day');
        return this.currentTime.diff(startOfDay, 'hours') < 9;
      },
    },
    mounted() {
      this.currentTime = moment();
      setInterval(this.updateCurrentTime, 1000 * 60);
    },
  }
</script>

<style scoped>

</style>
