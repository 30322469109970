<template>
  <v-container :fill-height="loading" class="single-site-view fill-height" fluid pa-0>
    <v-progress-linear
      v-show="subtleLoading"
      :indeterminate="true"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
    ></v-progress-linear>

    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-back-button fallback="sites"/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{ site ? site.name : '' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-model="tab" background-color="transparent" color="accent" grow
                mobile-break-point="0">
          <v-tabs-slider color="accent"/>
          <v-tab>
            {{ $t('sites.basic_details') }}
          </v-tab>
          <v-tab>
            {{ $t('sites.introduction.title') }}
          </v-tab>
          <v-tab v-if="$isAdmin || $isSuperior">
            {{ $t('sites.introduction_list') }}
          </v-tab>
          <v-tab v-if="$isAdmin || $isSuperior">
            {{ $t('sites.site_items.title') }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <div v-if="!loading" class="fill-height" style="min-width: 100%">
        <v-tabs-items v-model="tab" class="fill-height transparent">
          <v-tab-item class="fill-height">
            <v-container>
              <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
                <v-col cols="12" md='8' xl='6'>
                  <v-card color="secondary darken-1">
                    <v-card-title class="accent--text">
                      {{ $t('sites.site_information') }}
                    </v-card-title>
                    <v-card-text>
                      <v-form ref="form">
                        <v-text-field
                          v-model="currentSite.name"
                          :disabled="!$isAdmin && !$isSuperior"
                          :label="$t('sites.name')"
                          :rules="[$rules.required]"
                          class="mt-1 sitename"
                          type="text"
                          validate-on-blur
                        ></v-text-field>
                        <v-text-field
                          v-model="currentSite.address"
                          :disabled="!$isAdmin && !$isSuperior"
                          :label="$t('sites.address')"
                          :rules="$isAdmin ? [] : [$rules.required]"
                          class="mt-1 siteaddress"
                          type="text"
                          validate-on-blur
                        ></v-text-field>
                        <v-autocomplete
                          v-model="currentSite.customer"
                          :disabled="!$isAdmin && !$isSuperior"
                          :items="customers"
                          :label="$tc('customers.title', 1)"
                          class="mt-1"
                          clearable
                          item-text="name"
                          item-value="id"
                          return-object
                        />
                        <v-menu
                          ref="dateRangePicker"
                          v-model="dateRangePicker"
                          :close-on-content-click="false"
                          :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="durationDatesString"
                              :disabled="!$isAdmin && !$isSuperior"
                              :label="$t('sites.duration_dates')"
                              class="mt-1"
                              clearable
                              hide-details
                              readonly
                              v-on="on"
                              @click:clear="
                          () => {
                            currentSite.startDate = null;
                            currentSite.endDate = null;
                            durationDates = [];
                          }
                        "
                            >
                            </v-text-field>
                          </template>
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-date-picker
                                  v-model="durationDates"
                                  :full-width="$vuetify.breakpoint.xsOnly"
                                  class="elevation-0"
                                  color="primary"
                                  first-day-of-week="1"
                                  light
                                  locale="fi-fi"
                                  no-title
                                  range
                                  scrollable
                                ></v-date-picker>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                        <!--<date-picker
                          :inputStyles="{ class: 'mt-3' }"
                          v-model="currentSite.startDate"
                          :label="$t('sites.start_date')"
                          :disabled="!$isAdmin"
                        />
                        <date-picker
                          :inputStyles="{ class: 'mt-3' }"
                          v-model="currentSite.endDate"
                          :label="$t('sites.end_date')"
                          :disabled="!$isAdmin"
                        />-->
                      </v-form>

                      <v-alert :value="showAlert" class="mt-4" outlined type="error">
                        {{ $t('validation.check_all_required_fields') }}.
                      </v-alert>
                    </v-card-text>
                    <v-card-actions v-if="$isAdmin || $isSuperior">
                      <template v-if="$isAdmin">
                        <v-btn
                          v-if="$vuetify.breakpoint.smAndUp && currentSite.id && currentSite.status === 10"
                          :disabled="saving"
                          color="warning"
                          @click="setSiteInactive"
                        >
                          <span>{{ $t('set_inactive') }}</span>
                        </v-btn>
                        <v-btn
                          v-if="$vuetify.breakpoint.smAndUp && currentSite.id && currentSite.status === 0"
                          :disabled="saving"
                          color="success"
                          @click="setSiteActive"
                        >
                          <span>{{ $t('set_active') }}</span>
                        </v-btn>
                      </template>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="saving" color="default" @click="setCurrentSite">
                        <span>{{ $t('cancel') }}</span>
                      </v-btn>
                      <v-btn :loading="saving" color="primary" @click="saveSite">
                        <span>{{ $t('save') }}</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                  <v-card v-if="$isAdmin" color="secondary darken-1 mt-6">
                    <v-card-title class="accent--text">
                      {{ $t('sites.site_visibility') }}
                    </v-card-title>
                    <v-card-text>
                      <v-switch
                        :hint="$t('sites.hidden_hint')"
                        :label="$t('sites.hidden')"
                        :value="currentSite.hidden"
                        class="mt-0 mb-2"
                        color="primary"
                        persistent-hint
                        @click="this.updateSiteVisibility"
                      ></v-switch>
                      <v-switch
                        v-model="siteIsBilled"
                        :hint="$t('sites.billed_hint')"
                        :label="$t('sites.billed')"
                        class="mt-0 mb-2"
                        color="primary"
                        persistent-hint
                        @click="this.updateSiteBilled"
                      ></v-switch>
                    </v-card-text>
                  </v-card>

                  <v-card v-if="currentSite.id" color="secondary darken-1 mt-6" style="overflow: hidden">
                    <v-tabs v-model="fileTab" background-color="transparent" color="accent" grow>
                      <v-tab>{{ $t('sites.images') }}</v-tab>
                      <v-tab>{{ $t('sites.files') }}</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="fileTab">
                      <v-tab-item :key="$t('sites.images')">
                        <v-card color="secondary darken-1" flat tile>
                          <v-card-text>
                            <file-upload
                              :delete-disabled="!$isAdmin"
                              :object-id="currentSite.id"
                              accept="image/*"
                              field="images"
                              item
                              object="site"
                            >
                            </file-upload>

                            <v-row v-if="siteEntryImageList.length > 0">
                              <v-col cols="12">
                                <v-card-title class="ml-n5">{{ $t('sites.images_from_entries') }}</v-card-title>

                                <file-upload
                                  :delete-disabled="true"
                                  :disabled="true"
                                  :file-list="siteEntryImageList"
                                  :show-upload="false"
                                  accept="image/*"
                                  fetch-uri-type="image"
                                >
                                </file-upload>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :key="$t('sites.files')">
                        <v-card color="secondary darken-1" flat tile>
                          <v-card-text
                          >
                            <file-upload
                              :delete-disabled="!$isAdmin"
                              :object-id="currentSite.id"
                              accept="video/*, application/*, text/*"
                              field="files"
                              item
                              object="site"
                            >
                            </file-upload>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item class="fill-height">
            <v-container>
              <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
                <v-col cols="12" md='8' xl='6'>
                  <site-introduction :site="site"/>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="$isAdmin || $isSuperior" class="fill-height">
            <v-container>
              <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
                <v-col cols="12" md='8' xl='6'>
                  <v-card color="secondary darken-1">
                    <v-card-title class="accent--text">
                      {{ $t('sites.site_orientations') }}
                    </v-card-title>
                    <template v-if="siteIntroductions && siteIntroductions.length">
                      <v-list v-if="siteIntroductions && siteIntroductions.length" color="transparent">
                        <v-list-item v-for="(introduction, index) in siteIntroductions"
                                     :key="`${introduction.id}_${index}`"
                                     two-line
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                introduction.username ? introduction.username : `${introduction.user.firstName} ${introduction.user.lastName}`
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="introduction.accepted ">
                              {{ $t('sites.introduction.accepted') }}:
                              {{ timestamp(introduction.accepted) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="introduction.checked">
                              {{ $t('sites.introduction.checked') }}: {{ timestamp(introduction.checked) }}.
                              {{ $t('sites.introduction.checker') }}:
                              <template v-if="introduction.checkedUser.firstName && introduction.checkedUser.lastName">
                                {{ introduction.checkedUser.firstName }} {{ introduction.checkedUser.lastName }}
                              </template>
                              <template v-else>
                                {{ introduction.checkedUser.email }}
                              </template>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              {{ $t('sites.introduction.not_checked') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="justify-end" style="min-width: 50px">
                            <v-tooltip :disabled="$vuetify.breakpoint.smAndUp" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="introduction.accepted && !introduction.checked"
                                  :icon="$vuetify.breakpoint.xsOnly"
                                  class="my-1 py-1 success"
                                  v-bind="attrs"
                                  @click="approveOrientation(introduction.id)"
                                  v-on="on"
                                >
                                  <v-icon :left="$vuetify.breakpoint.smAndUp">
                                    mdi-check
                                  </v-icon>
                                  <span v-if="$vuetify.breakpoint.smAndUp">
                                    {{ $t('ok') }}
                                  </span>
                                </v-btn>
                              </template>
                              <span>{{ $t('ok') }}</span>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </template>
                    <v-card-text v-else>
                      {{ $t('sites.no_introductions_to_list') }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="$isAdmin || $isSuperior" class="fill-height">
            <v-container>
              <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
                <v-col cols="12" md='8' xl='6'>
                  <site-items-view :site="this.site"></site-items-view>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import apiCollection from '@/api';
import moment from 'moment';
import FileUpload from '@/components/FileUpload';
import SiteIntroduction from "@/components/SingleSite/SiteIntroduction";
import axios from "axios";
import SiteItemsView from "@/components/SingleSite/SiteItemsView.vue";

export default {
  name: 'SingleSiteView',
  props: ['id'],
  components: {
    SiteItemsView,
    SiteIntroduction,
    FileUpload,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      saving: false,
      showAlert: false,
      siteIsBilled: null,
      currentSite: {},
      siteTemplate: {
        address: '',
        hidden: false,
        name: '',
        status: 10,
        customer: null,
        startDate: null,
        endDate: null,
        billed: null,
      },
      siteEntryImages: [],
      dateRangePicker: false,
      durationDates: [],
      durationDatesString: '',
      fileTab: null,
      selectedCustomer: null,
      tab: 0,
    };
  },
  methods: {
    async approveOrientation(introductionId) {
      if (!this.$isSuperior && !this.$isAdmin) {
        return;
      }
      try {
        await axios.post(`api/tenant/introduction/${introductionId}/check`);
        await this.reloadSiteIntroductions(this.currentSite.id);
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    timestamp(timestamp) {
      const dateTime = moment.unix(timestamp).toDate();
      return dateTime.toLocaleDateString('fi-FI');
    },
    updateSiteVisibility() {
      this.currentSite.hidden = !this.currentSite.hidden;
      this.saveSite();
    },
    async updateSiteBilled() {
      if (!this.$isAdmin) {
        return;
      }
      if (this.siteIsBilled) {
        try {
          await apiCollection.setSiteBilled(this.currentSite.id);
        } catch (e) {
          this.$handleApiError(e);
        }
      } else {
        try {
          await apiCollection.setSiteUnbilled(this.currentSite.id);
        } catch (e) {
          this.$handleApiError(e);
        }
      }
    },
    setSiteInactive() {
      this.currentSite.status = 0;
      this.saveSite();
    },
    setSiteActive() {
      this.currentSite.status = 10;
      this.saveSite();
    },
    async saveSite() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      if (await this.$store.dispatch('upsertSite', this.currentSite)) {
        this.showAlert = false;
      }
      this.saving = false;
    },
    async reloadSites() {
      if (this.$store.state.sites.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadSites');

      this.loading = false;
      this.subtleLoading = false;
    },
    setCurrentSite() {
      this.showAlert = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      const siteIndex = this.$store.state.sites.findIndex(site => parseInt(site.id) === parseInt(this.id));
      if (siteIndex > -1) {
        this.currentSite = {...this.$store.state.sites[siteIndex]};
        if (this.currentSite.startDate && this.currentSite.endDate) {
          this.durationDates = [
            moment(this.currentSite.startDate).format('YYYY-MM-DD'),
            moment(this.currentSite.endDate).format('YYYY-MM-DD'),
          ];
        }
        this.siteIsBilled = this.currentSite.billed !== null;
      }
    },
    async reloadCustomers() {
      if (this.$store.state.customers.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
    async reloadSiteIntroductions(id) {
      await this.$store.dispatch('loadSiteIntroductions', id)
    }
  },
  computed: {
    site() {
      const siteIndex = this.$store.state.sites.findIndex(site => parseInt(site.id) === parseInt(this.id));
      if (siteIndex > -1) {
        return this.$store.state.sites[siteIndex];
      }
      return false;
    },
    siteEntryImageList() {
      if (this.siteEntryImages) {
        return this.siteEntryImages.flatMap(se => se.images);
      }
      return [];
    },
    customers() {
      return this.$store.state.customers;
    },
    siteIntroductions() {
      return this.$store.state.siteIntroductions;
    }
  },
  watch: {
    currentSite: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.reloadSiteIntroductions(newValue.id);
        }
      }
    },
    durationDates() {
      if (!this.durationDates || this.durationDates.length < 2) {
        this.currentSite.startDate = null;
        this.currentSite.endDate = null;
        this.durationDatesString = null;
        return;
      }
      let startDate = moment(this.durationDates[0]);
      let endDate = moment(this.durationDates[1]);
      if (!startDate.isValid()) {
        startDate = null;
      }
      if (!endDate.isValid()) {
        endDate = null;
      }
      this.currentSite.startDate = startDate ? startDate.format('YYYY-MM-DD') : startDate;
      this.currentSite.endDate = endDate ? endDate.format('YYYY-MM-DD') : endDate;
      this.durationDatesString = [
        startDate !== null ? startDate.format('DD.MM.YYYY') : null,
        endDate !== null ? endDate.format('DD.MM.YYYY') : null,
      ]
        .filter(Boolean)
        .join(' - ');
    },
  },
  beforeMount() {
    if (this.currentSite && this.id) {
      this.currentSite.id = parseInt(this.id);
    }
  },
  async mounted() {
    await this.reloadSites();
    await this.reloadCustomers();
    await this.setCurrentSite();
    this.siteEntryImages = await apiCollection.getSiteEntryImages(this.id);
  },
};
</script>
<style></style>
