<template>
  <v-dialog
    ref="durationDialog"
    v-model="dialog"
    persistent
    width="320px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field :disabled="disabled" :label="$t('timer.duration')" :value="humanizedCurrentDuration" readonly class="title" v-on="on" >
        <template slot="prepend">
          <div style="width: 25px; padding-top: 4px; text-align: center;"></div>
        </template>
      </v-text-field>
    </template>

    <v-card light>
      <v-card-title class="secondary">
        <v-row>
          <v-col cols="12" class="display-1 font-weight-medium text-right white--text">
            {{ humanizedCurrentDuration }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <label class="caption ml-1" style="color: hsla(0,0%,100%,.7);">{{ $t('timer.add_time') }}</label><br />
            <v-row justify="space-between">
              <v-col cols="4">
                <v-btn block outlined @click="addMinutes(30)">+30min</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block outlined @click="addMinutes(60)">+1h</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block outlined @click="addMinutes(120)">+2h</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <label class="caption ml-1" style="color: hsla(0,0%,100%,.7);">{{ $t('timer.subtract_time') }}</label><br />
            <v-row justify="space-between">
              <v-col cols="4">
                <v-btn block outlined @click="subtractMinutes(30)">-30min</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block outlined @click="subtractMinutes(60)">-1h</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn block outlined @click="subtractMinutes(120)">-2h</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text @click="handleCancel">{{ $t('cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" @click="handleSave">{{ $t('ok') }}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
  import moment from 'moment';

  export default {
    name: "DurationDialog",
    props: {
      startTime: {
        type: [Boolean, String],
        default: false,
      },
      startDate: {
        type: [Boolean, String],
        default: false,
      },
      endTime: {
        type: [Boolean, String],
        default: false,
      },
      endDate: {
        type: [Boolean, String],
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialog: false,
        start: false,
        end: false,
        fakeEnd: false,
        fakeEndInterval: null,
      }
    },
    methods: {
      updateFakeEnd() {
        this.fakeEnd = moment().toISOString();
      },
      handleSave() {
        this.dialog = false;
        this.$emit('on-save', this.end);
      },
      handleCancel() {
        this.dialog = false;
        this.updateTimes();
      },
      addMinutes(minutes) {
        this.end = moment(this.end).add(minutes, 'minutes').toISOString();
        this.fakeEnd = false;
        clearInterval(this.fakeEndInterval);
      },
      subtractMinutes(minutes) {
        this.end = moment(this.end).subtract(minutes, 'minutes').toISOString();
        this.fakeEnd = false;
        clearInterval(this.fakeEndInterval);
      },
      getClosestHalfHourMoment() {
        const start = moment();
        let remainder = 30 - (start.minute() + start.second()/60) % 30;
        remainder = (remainder >  30/2) ? remainder = -30 + remainder : remainder;
        return moment(start).add(remainder, "minutes" ).seconds(0);
      },
      updateTimes() {
        this.fakeEnd = false;
        clearInterval(this.fakeEndInterval);
        this.start = moment(`${this.startDate} ${this.startTime}`).toISOString();
        if (this.endDate && this.endTime) {
          this.end = moment(`${this.endDate} ${this.endTime}`).toISOString();
        } else {
          this.end = this.getClosestHalfHourMoment().toISOString();
          this.fakeEnd = moment().toISOString();
          this.fakeEndInterval = setInterval(this.updateFakeEnd, 1000);
        }
      }
    },
    computed: {
      humanizedCurrentDuration() {
        return (this.isNegativeDuration ? '-' : '') + this.$humanizeDuration(this.duration, !!this.fakeEnd);
      },
      duration() {
        if (!this.start) return moment.duration(0);

        let diff;
        let start = moment(this.start);
        let end = moment(this.end);

        if (this.fakeEnd) {
          end = moment(this.fakeEnd);
        }

        if (end.isBefore(start)) {
          diff = start.diff(end);
        } else {
          diff = end.diff(start);
        }
        return moment.duration(diff);
      },
      isNegativeDuration() {
        if (this.fakeEnd) {
          return moment(this.fakeEnd).isBefore(this.start);
        } else if (this.end) {
          return moment(this.end).isBefore(this.start);
        }
        return false;
      }
    },
    watch: {
    },
    mounted() {
      this.updateTimes();
    },
  }
</script>

<style scoped>

</style>
