import 'vuetify/dist/vuetify.min.css';
import Vue from 'vue';
import i18n from './i18n.js';
import vuetify from './plugins/vuetify.plugin.js';
import Axios from 'axios'; // set defaults before any other imports
import moment from 'moment';

import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';

import HelperPlugin from './plugins/helper.plugin';
import ValidationPlugin from './plugins/validation.plugin';
import RolesPlugin from './plugins/roles.plugin';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import UiEventBus from './UiEventBus';

//import messages from './config/lang'
import BackButton from './components/BackButton.vue';
import MainMenuButton from './components/MainMenuButton.vue';
import LoadingIndicator from './components/LoadingIndicator.vue';

import UserService from './service/user.service';

import Amplify, * as AmplifyModules from 'aws-amplify';
import {AmplifyPlugin} from 'aws-amplify-vue';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

AmplifyModules.I18n.putVocabulariesForLanguage('fi', {
  Username: 'Sähköposti',
  Password: 'Salasana',
  'Forget your password? ': 'Unohditko salasanan?',
  'Reset password': 'Nollaa salasana',
  'Sign In': 'Kirjaudu sisään',
  'Enter your username': 'Sähköposti',
  'Enter your password': 'Salasana',
  'Reset your password': 'Nollaa salasanasi',
  'Back to Sign In': 'Takaisin kirjautumiseen',
  'Resend Code': 'Lähetä nollauskoodi uudestaan',
  'Send Code': 'Lähetä',
  'New Password': 'Uusi salasana',
  Code: 'Koodi',
  Submit: 'Tallenna',
  'Enter new password': 'Anna uusi salasana',
});

AmplifyModules.I18n.setLanguage('fi');

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(HelperPlugin);
Vue.use(ValidationPlugin);
Vue.use(RolesPlugin);

Vue.component('hb-back-button', BackButton);
Vue.component('hb-main-menu-button', MainMenuButton);
Vue.component('hb-loading-indicator', LoadingIndicator);

const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') !== -1 ? false : true;
if (hasCustomBaseUrl) {
  Axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
}
Axios.defaults.withCredentials = true;
Axios.defaults.headers.common['Pragma'] = 'no-cache';
Axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';
Vue.config.productionTip = false;

async function getUserModelObject() {
  const response = await Axios.get('/api/user/me');
  try {
    const userInfo = {};
    userInfo.roles = response.data.roleSet;
    userInfo.firstName = response.data.firstName;
    userInfo.lastName = response.data.lastName;
    userInfo.username = response.data.username;
    userInfo.agentUser = response.data.agentUser;
    userInfo.enabled = response.data.enabled;
    userInfo.subject = response.data.subject;
    userInfo.id = response.data.id;
    userInfo.email = response.data.email;
    UserService.setUserInfo(userInfo);
    return userInfo;
  } catch (error) {
    UserService.clearUserInfo();
    console.log(error);
    return null;
  }
}

UiEventBus.$on('userLoggedIn', (/*email*/) => {
  console.log('userLoggedIn');
  getUserModelObject()
    .then(userData => {
      console.log(userData, 'userData');
      store.commit('setLoadingOverlay', true);
    })
    .catch(error => {
      console.log(error);
    });
});

UiEventBus.$on('loginSuccess', () => {
  router.push({name: 'timer'});
});

function getCurrentSession(amplify) {
  let userInfo = UserService.getSession();
  let isValid = userInfo != null && moment().add(5, 'minutes').isBefore(moment(userInfo.accessTokenExpires));
  // console.log('isValid', isValid);
  if (!isValid) {
    return amplify.Auth.currentSession()
      .then(session => {
        const accessTokenExpires = moment(session.accessToken.payload.exp * 1000);
        const sessionInfo = {
          sub: session.idToken.payload.sub,
          email: session.idToken.payload.email,
          roles: '',
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          accessTokenExpires,
        };
        UserService.setSession(sessionInfo);
        return Promise.resolve(sessionInfo);
      })
      .catch(() => {
        UserService.clearUserInfo();
        return Promise.reject(null);
      });
  } else {
    return Promise.resolve(userInfo);
  }
}

Axios.interceptors.request.use(
  config => {
    UiEventBus.$emit('addProgress');
    let userInfo = UserService.getSession();
    let isValid = userInfo != null && moment().add(5, 'minutes').isBefore(moment(userInfo.accessTokenExpires));
    // console.log('isValid', isValid);
    if (!isValid) {
      return getCurrentSession(vue.$Amplify)
        .then(userInfo => {
          config.headers.Authorization = 'Bearer ' + userInfo.accessToken;
          return Promise.resolve(config);
        })
        .then()
        .catch(error => {
          console.log(error);
          return Promise.resolve(config);
        });
    } else if (isValid && userInfo != null) {
      config.headers.Authorization = 'Bearer ' + userInfo.accessToken;
    }
    return Promise.resolve(config);
  },
  error => {
    UiEventBus.$emit('removeProgress');
    return Promise.reject(error);
  }
);
if (process.env.VUE_APP_SENTRY_DSN && process.env.NODE_ENV !== 'development') {
  const tracingOrigins = (process.env.VUE_APP_SENTRY_TRACING_ORIGINS || '').split(',');
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [...tracingOrigins, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    logErrors: true,
  });
} else {
  console.warn('No env.VUE_APP_SENTRY_DSN provided');
}
const vue = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

if (UserService.getSession() != null) {
  getUserModelObject().then(userInfo => {
    if (userInfo == null) {
      if (router.currentRoute.name !== 'login') {
        router.push({name: 'login'});
      }
    }
  });
} else {
  if (router.currentRoute.name !== 'login') {
    router.push({name: 'login'});
  }
}

router.beforeEach((to, from, next) => {
  console.log('route change', from.path, to.path);
  const isLoggedIn = UserService.getUserInfo() != null && UserService.getSession() != null;
  if (to.name !== 'login' && !isLoggedIn) {
    next({name: 'login'});
  } else {
    next();
  }
});

// Add a response interceptor
Axios.interceptors.response.use(
  response => {
    UiEventBus.$emit('removeProgress');
    return response;
  },
  error => {
    UiEventBus.$emit('removeProgress');
    return Promise.reject(error);
  }
);
