import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$isAdmin || _vm.$isSuperior)?_c('div',[_c(VExpansionPanels,[_vm._l((_vm.itemTypesWithItems),function(itemType){return [(itemType.items.length > 0)?_c(VExpansionPanel,{key:itemType.id,staticClass:"secondary darken-1"},[_c(VExpansionPanelHeader,[_c(VCardTitle,{staticClass:"accent--text pa-0 text-break"},[_c('div',[_c(VBadge,{attrs:{"value":itemType.siteItemCount,"content":itemType.siteItemCount,"left":"","color":"red"}},[_vm._v(" "+_vm._s(itemType.shortDescription)+" ")])],1)])],1),_c(VExpansionPanelContent,[_c('div',{staticClass:"subtitle-2 white--text mb-5"},[_vm._v(" "+_vm._s(itemType.longDescription)+" ")]),(itemType.shortDescription === 'Aikatyö')?[_c('site-entries',{ref:"siteEntries",refInFor:true,attrs:{"site":_vm.site}})]:_vm._e(),_c(VList,{staticClass:"mx-n4",attrs:{"color":"transparent"}},[_vm._l((itemType.items),function(item,index){return [_c(VListItem,{key:item.id},[_c(VListItemContent,{staticClass:"flex-grow-1"},[_c(VListItemTitle,[_c('strong',[_vm._v(_vm._s(item.shortDescription))]),_vm._v(" "),_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v("("+_vm._s(item.code)+")")]),(itemType.shortDescription === 'Aikatyö')?_c(VBtn,{staticClass:"ml-2",attrs:{"disabled":_vm.$store.state.selectedEntriesForSite.length === 0,"small":"","color":"primary"},on:{"click":function($event){return _vm.assignSelectedEntriesHours(item)}}},[_vm._v(" "+_vm._s(_vm.$t('sites.site_items.add_entries_to_site_item'))+" ")]):_vm._e()],1),_c(VListItemSubtitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.longDescription)+" ")])],1),_c(VListItemActionText,{staticClass:"pl-5"},[_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"end"}},[_c(VTooltip,{attrs:{"left":"","color":"success","value":_vm.justUpdatedItems.findIndex(function (i) { return i === item.id; }) > -1,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,{staticClass:"my-2",staticStyle:{"width":"105px","max-width":"105px"},attrs:{"hide-details":"","type":"number","inputmode":"number","suffix":item.unit,"disabled":!!_vm.site.billed,"loading":item.id === _vm.savingItemId,"outlined":"","dense":""},on:{"change":function($event){return _vm.upsertItemAmount(item)}},model:{value:(item.siteItemAmount),callback:function ($$v) {_vm.$set(item, "siteItemAmount", _vm._n($$v))},expression:"item.siteItemAmount"}})]}}],null,true)},[_c('span',[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t('sites.site_items.amount_updated'))+"!")],1)])],1)],1)],1),(index < itemType.items.length - 1)?_c(VDivider,{key:((item.id) + "-divider")}):_vm._e()]})],2)],2)],1):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }