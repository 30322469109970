<template>
  <v-container :fill-height="loading" class="customer-view" fluid pa-0>
    <v-progress-linear
      v-show="subtleLoading"
      :indeterminate="true"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
    ></v-progress-linear>

    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-back-button fallback="customers"/>
      </template>
      <template v-if="$isAdmin" v-slot:extension>
        <v-tabs v-model="tab" background-color="transparent" color="accent" grow>
          <v-tabs-slider color="accent"></v-tabs-slider>
          <v-tab key="perus">
            {{ $t('customer.basic_info') }}
          </v-tab>
          <v-tab key="hinnoittelu">
            {{ $t('customer.pricing') }}
          </v-tab>
        </v-tabs>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{ customer ? customer.name : '' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-fade-transition>
      <div class="fill-height" style="min-width: 100%">
        <template v-if="noContent">
          <v-row v-if="!loading" class="secondary" justify="start" style="position: relative">
            <v-col cols="12" md='8' xl='6'>
              <v-card color="secondary darken-1">
                <v-card-title class="accent--text">
                  {{ $t('customers.no_content_title') }}
                </v-card-title>
                <v-card-actions>
                  <v-btn @click="$router.push('/customers')">{{ $t('customers.return_to_listing') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <v-tabs-items v-model="tab" class="transparent">
          <v-tab-item key="perus" class="fill-height">
            <v-container>
              <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
                <v-col cols="12" md='10' xl="8">
                  <customer-basic-info :id="id"
                                       :customer="customer"
                                       :loading="loading"
                  />
                </v-col>
              </v-row>
            </v-container>

          </v-tab-item>
          <v-tab-item v-if="$isAdmin" key="hinnoittelu" class="fill-height">
            <customer-pricing v-if="customer" :customer-id="id" :customer-name="customer.name"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-fade-transition>

    <hb-loading-indicator v-if="loading" align-middle/>

  </v-container>
</template>

<script>
import CustomerBasicInfo from "@/components/Customers/CustomerBasicInfo.vue";
import CustomerPricing from "@/components/Customers/CustomerPricing.vue";

export default {
  name: "CustomerView",
  components: {CustomerBasicInfo, CustomerPricing},
  props: ['id'],
  data() {
    return {
      loading: true,
      subtleLoading: false,
      saving: false,
      showAlert: false,
      currentCustomer: {},
      customerTemplate: {
        address: '',
        hidden: false,
        name: '',
        status: 10,
        startDate: null,
        endDate: null,
      },
      dateRangePicker: false,
      durationDates: [],
      durationDatesString: '',
      fileTab: null,
      selectedCustomer: null,
      tab: 0,
    };
  },
  methods: {
    setCustomerInactive() {
      this.currentCustomer.status = 'DISABLED';
      this.saveCustomer(true);
    },
    setCustomerActive() {
      this.currentCustomer.status = 'ACTIVE';
      this.saveCustomer(true);
    },
    async saveCustomer(customer) {
      this.saving = true;
      await this.$store.dispatch('upsertCustomer', customer);
      this.saving = false;
    },
    openCustomer(customer) {
      this.currentCustomer = Object.assign({}, customer);
    },
    openNew() {
      this.currentCustomer = Object.assign({}, this.customerTemplate);
    },
    async reloadCustomers() {
      if (this.$store.state.customers.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
  },
  watch: {},
  computed: {
    customer() {
      return this.$store.getters.getCustomer(parseInt(this.id));
    },
    noContent() {
      return !((this.customer && this.customer.id) || this.id === 'new');
    }
  },
  async mounted() {
    await this.reloadCustomers();
  }
}
</script>

