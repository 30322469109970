/* eslint-disable no-param-reassign,no-unused-vars */
import Vue from 'vue'
import moment from 'moment/moment'
import md5 from 'md5';

import apiCollection from '../api.js';
import i18n from '../i18n';
import store from '../store';

moment.locale('fi');

const HelperPlugin = {
  install(/* vue */) {
    Vue.mixin({
      computed: {
        $api() {
          return apiCollection;
        },
        $baseUrl() {
          const hasCustomBaseUrl = process.env.VUE_APP_API_BASEURL.indexOf('undefined') === -1;
          if (hasCustomBaseUrl) {
            return process.env.VUE_APP_API_BASEURL;
          }
          return '';
        }
      },
      methods: {
        $reloadApp() {
          location.reload();
        },
        $formatTimeStamp(date) {
          return moment(date).format('LLLL');
        },
        $formatDateNoTime(date) {
          if (date) {
            return moment(date).format('DD.MM.YYYY');
          }
        },
        $formatTimeNoDate(date) {
          if (date) {
            return moment(date).format('HH:mm');
          }
        },
        $formatWeekAndYear(date) {
          if (date) {
            const d = moment(date);
            return `${d.week()}/${d.year()}`;
          }
        },
        $humanizeDuration(duration, showSeconds = false) {
          if (duration.asHours() > 23) {
            return Math.floor(duration.asHours()) + 'h ' + duration.minutes() + 'min';
          }
          return duration.hours() + 'h ' + duration.minutes() + 'min ' + (showSeconds ? duration.seconds() + 's' : '');
        },
        $randomizeColorFromString(string, saturation = 75, lightness = 60) {
          let hash = 0;
          if (string.length > 0) {
            for (var i = 0; i < string.length; i++) {
              hash = string.charCodeAt(i) + ((hash << 5) - hash);
              hash = hash & hash; // Convert to 32bit integer
            }
          }

          if (hash < 0) hash = hash * -1;

          let shortened = hash % 200;
          return `hsl(${shortened}, ${saturation}%, ${lightness}%)`;
        },
        $getGravatarUrl(email, size) {
          const picSize = size ? size : '68';
          const hash = md5((email.toLowerCase()).trim());
          return `https://www.gravatar.com/avatar/${hash}?s=${picSize}&d=mm`; // eslint-disable-line no-undef
        },
        $getHeightForFixedHeaderTable() {
          const tableHeader = document.getElementsByClassName('v-data-table-header')
          const tableContainer = document.getElementsByClassName('scroll-content')
          let height = ''
          if (tableContainer[0]) {
            height = tableContainer[0].offsetHeight
            if (tableHeader[0]) {
              height = height - tableHeader[0].offsetHeight
            }
          }
          return height
        },
        $showSuccessNotification(message) {
          store.dispatch('showNotification', {
            color: 'success',
            message,
            showButton: false,
          });
        },
        $showWarningNotification(message) {
          store.dispatch('showNotification', {
            color: 'warning',
            message,
            showButton: false,
          });
        },
        $showErrorNotification(message) {
          const msg = message != null ? message : i18n.t('generic_error');
          store.dispatch('showNotification', {
            color: 'error',
            message: msg,
            showButton: false,
          });
        },
        /**
         *
         * @param {!error} err
         * @param {msg} [msg] message to show
         */
        $handleApiError(err, msg) {
          console.log(err);
          if (err.response != null && err.response.data && err.response.data.error_key != null) {
            this.$showErrorNotification(i18n.t(err.response.data.error_key));
          } else {
            this.$showErrorNotification(msg);
          }
        },
      }
    });
  },
};

export default HelperPlugin;
