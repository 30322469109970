<template>
  <v-container v-if="$isAdmin || $isSuperior" :fill-height="loading" class="report-view" fluid>
    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        <v-toolbar-title style="margin-left: 0; width: 100%">
          {{ $t('reports.title') }}
        </v-toolbar-title>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <v-row v-if="!loading" no-gutters>
        <v-col :class="$vuetify.breakpoint.smAndUp ? 'mb-5' : ''" :style="{ height: $vuetify.breakpoint.xsOnly ? '0' : 'auto' }"
               cols="12">
          <v-btn
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp }"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :large="$vuetify.breakpoint.smAndUp"
            :left="$vuetify.breakpoint.xsOnly"
            color="primary"
            dark
            v-if="$isAdmin"
            @click="save"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">save_alt</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('reports.download_excel') }}</span>
          </v-btn>
          <v-btn
            :bottom="$vuetify.breakpoint.xsOnly"
            :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp }"
            :fab="$vuetify.breakpoint.xsOnly"
            :fixed="$vuetify.breakpoint.xsOnly"
            :large="$vuetify.breakpoint.smAndUp"
            :right="$vuetify.breakpoint.xsOnly"
            color="primary"
            dark
            @click="createNewEntry"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('reports.create_new_entry') }}</span>
          </v-btn>
          <v-btn
            v-if="(sameInvoicedState === false || sameInvoicedState == null) && $isAdmin"
            :class="{ 'ma-0': $vuetify.breakpoint.smAndUp, 'd-none': $vuetify.breakpoint.smAndDown }"
            :disabled="sameInvoicedState == null"
            :large="$vuetify.breakpoint.smAndUp"
            color="primary"
            dark
            @click="massUpdateInvoicedState"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">fas fa-euro-sign</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('reports.update_as_invoiced') }}</span>
          </v-btn>
          <v-btn
            v-if="sameInvoicedState === true && $isAdmin"
            :class="{ 'ma-0': $vuetify.breakpoint.smAndUp, 'd-none': $vuetify.breakpoint.smAndDown }"
            :disabled="sameInvoicedState == null"
            :large="$vuetify.breakpoint.smAndUp"
            color="primary"
            dark
            @click="massUpdateInvoicedState"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">fas fa-euro-sign</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('reports.update_as_non_invoiced') }}</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-menu
                ref="dateRangePicker"
                v-model="dateRangePicker"
                :close-on-content-click="false"
                :min-width="$vuetify.breakpoint.xsOnly ? 'calc(100vw - 24px)' : 'auto'"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filterDatesText"
                    append-icon="filter_list"
                    background-color="secondary lighten-1"
                    hide-details
                    readonly
                    single-line
                    solo
                    v-on="on"
                  >
                    <template slot="prepend-inner">
                      <v-icon class="mr-2" color="white">event</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-card light>
                  <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                    <v-row no-gutters>
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisWeek">{{ $t('reports.this_week') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastWeek">{{ $t('reports.last_week') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisMonth">{{ $t('reports.this_month') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastMonth">{{ $t('reports.last_month') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectThisYear">{{ $t('reports.this_year') }}</v-btn>
                      </v-col
                      >
                      <v-col cols="6"
                      >
                        <v-btn block small text @click="selectLastYear">{{ $t('reports.last_year') }}</v-btn>
                      </v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
                  <v-card-text>
                    <v-row>
                      <div v-if="$vuetify.breakpoint.smAndUp" class="mr-1">
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisWeek">
                          {{ $t('reports.this_week') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastWeek">
                          {{ $t('reports.last_week') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisMonth">{{
                            $t('reports.this_month')
                          }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastMonth">{{
                            $t('reports.last_month')
                          }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectThisYear">
                          {{ $t('reports.this_year') }}
                        </v-btn>
                        <v-btn :block="$vuetify.breakpoint.smAndUp" small text @click="selectLastYear">
                          {{ $t('reports.last_year') }}
                        </v-btn>
                      </div>
                      <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
                      <v-date-picker
                        v-model="filterDates"
                        :full-width="$vuetify.breakpoint.xsOnly"
                        class="elevation-0"
                        color="secondary"
                        first-day-of-week="1"
                        locale="fi-fi"
                        no-title
                        range
                        scrollable
                      ></v-date-picker>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                v-model="filterSite"
                :items="sites"
                :placeholder="$t('reports.site')"
                append-icon="filter_list"
                background-color="secondary lighten-1"
                clearable
                hide-details
                item-text="name"
                item-value="id"
                multiple
                single-line
                solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-map-marked-alt</v-icon>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="index === 0 && filterSite.length === 1">
                    <span>{{ item.name }}</span>
                  </template>
                  <template v-if="filterSite.length > 1 && index === 0">
                    <span>{{ filterSite.length }} {{ $t('reports.selections') }}</span>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                v-model="filterCustomer"
                :items="customers"
                :placeholder="$t('reports.customer')"
                append-icon="filter_list"
                background-color="secondary lighten-1"
                clearable
                hide-details
                item-text="name"
                item-value="id"
                multiple
                single-line
                solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-map-marked-alt</v-icon>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="index === 0 && filterCustomer.length === 1">
                    <span>{{ item.name }}</span>
                  </template>
                  <template v-if="filterCustomer.length > 1 && index === 0">
                    <span>{{ filterCustomer.length }} {{ $t('reports.selections') }}</span>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                v-model="filterUser"
                :items="richerUsers"
                :placeholder="`${$t('reports.employee')}`"
                append-icon="filter_list"
                background-color="secondary lighten-1"
                clearable
                hide-details
                item-text="combinedInfo"
                item-value="id"
                multiple
                single-line
                solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2">people</v-icon>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="index === 0 && filterUser.length === 1">
                    <span v-if="item.lastName">{{ item.firstName }} {{ item.lastName }}</span>
                    <span v-else>{{ item.email }}</span>
                  </template>
                  <template v-if="filterUser.length > 1 && index === 0">
                    <span>{{ filterUser.length }} {{ $t('reports.selections') }}</span>
                  </template>
                </template>
                <template v-slot:item="data">
                  <v-list-item-title>
                    <template v-if="data.item.lastName">{{ data.item.firstName }} {{ data.item.lastName }}</template>
                    <template v-else>{{ data.item.email }}</template>
                  </v-list-item-title>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                v-model="filterEquipment"
                :items="equipments"
                :placeholder="`${$t('reports.equipment')}`"
                append-icon="filter_list"
                background-color="secondary lighten-1"
                clearable
                hide-details
                item-text="description"
                item-value="id"
                multiple
                single-line
                solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-snowplow</v-icon>
                </template>
                <template v-slot:selection="{ item, index }">
                  <template v-if="index === 0 && filterEquipment.length === 1">
                    <span>{{ item.description }}</span>
                  </template>
                  <template v-if="filterEquipment.length > 1 && index === 0">
                    <span>{{ filterEquipment.length }} {{ $t('reports.selections') }}</span>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xl="2">
              <v-select
                v-model="filterInvoiced"
                :items="invoiced"
                :placeholder="`${$t('reports.is_invoiced')}`"
                append-icon="filter_list"
                background-color="secondary lighten-1"
                clearable
                hide-details
                item-text="descr"
                item-value="val"
                single-line
                solo
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2" color="white" size="17">fas fa-euro-sign</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <template v-if="!loading">
            <v-data-table
              id="entries-table"
              :headers="headers"
              :items="filteredEntries"
              :mobile-breakpoint="0"
              :no-data-text="$t('reports.no_entries')"
              :sort-by.sync="sortBy"
              :sort-desc.sync="descending"
              class="transparent"
              disable-pagination
              hide-default-footer
              locale="fi-FI"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="items.length > 0">
                <tr
                  v-for="item in items"
                  :key="item.name"
                  :style="item.invoiced ? 'background-color: #1e3062' : ''"
                  class="trow"
                  @click="openEntryInDialog(item, false, false)"
                >
                  <td t="s">{{ $formatDateNoTime(item.entryStart) }}</td>
                  <td>{{ item.site ? item.site.name : $t('timer.entry_has_no_site') }}</td>
                  <td>{{
                      item.site && item.site.customer ? item.site.customer.name : $t('timer.entry_has_no_customer')
                    }}
                  </td>
                  <td>{{ item.user }}</td>
                  <td>
                    <template v-for="(equipment, index) in item.equipments">
                        <span :key="equipment.id"
                        ><span>{{ equipment.description }}</span
                        ><span v-if="index < item.equipments.length - 1" style="display: none">,&nbsp;</span><br
                        /></span>
                    </template>
                  </td>
                  <td>{{ item.info }}</td>
                  <td>{{ $formatTimeNoDate(item.entryStart) }} - {{ $formatTimeNoDate(item.entryEnd) }}</td>
                  <td>{{ item.duration }}</td>
                  <td>{{ $t('timer.work_types.' + item.workType) }}</td>
                  <td>{{ item.distance }}</td>
                  <td class="text-center">
                    <v-icon v-if="item.invoiced" size="16">fas fa-euro-sign</v-icon>
                  </td>
                  <td>
                    <v-badge v-if="item.imageCount > 0" color="accent darken-2" overlap>
                      <template v-slot:badge>
                        <span class="caption font-weight-bold">{{ item.imageCount }}</span>
                      </template>
                      <v-icon color="white" size="28">collections</v-icon>
                    </v-badge>
                  </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td class="text-center grey--text" colspan="9">
                    <span>{{ $t('reports.no_entries') }}</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>
    </v-fade-transition>

    <entry-dialog ref="entryDialog" :users="users" admin-mode/>
  </v-container>
</template>

<script>
import XLSX from 'xlsx';
import xlsxHelper from '../plugins/xlsxhelper.plugin.js';
import {saveAs} from 'file-saver';
import moment from 'moment';
import EntryDialog from '../components/Timer/EntryDialog.vue';

export default {
  name: 'ReportView',
  components: {
    EntryDialog: EntryDialog,
  },
  data() {
    return {
      sortBy: 'entryStartTS',
      descending: true,
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      filterSite: [],
      filterCustomer: [],
      filterEquipment: [],
      filterUser: [],
      filterDates: [moment().startOf('month').toISOString(), moment().endOf('day').toISOString()],
      filterInvoiced: undefined,
      dateRangePicker: false,
      users: [],
      headers: [
        {
          text: this.$t('reports.date'),
          value: 'entryStartTS',
        },
        {
          text: this.$t('reports.site'),
          value: 'site.name',
        },
        {
          text: this.$t('reports.customer'),
          value: 'site.customer.name',
        },
        {
          text: this.$t('reports.employee'),
          value: 'user',
        },
        {
          text: this.$t('reports.equipment'),
          value: 'equipmentString',
          divider: true,
        },
        {
          text: this.$t('reports.info_and_materials'),
          sortable: false,
        },
        {
          text: this.$t('reports.start_end'),
          sortable: false,
        },
        {
          text: this.$t('reports.duration'),
          sortable: false,
        },
        {
          text: this.$t('timer.work_type'),
          value: 'workType',
        },
        {
          text: this.$t('reports.distance'),
          sortable: false,
        },
        {
          text: this.$t('reports.invoiced'),
          value: 'invoiced',
          sortable: true,
        },
        {
          text: '',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    createNewEntry() {
      this.$refs.entryDialog.openNew();
    },
    openEntryInDialog(entry) {
      this.$refs.entryDialog.openEntry(entry);
    },
    save() {
      const element = document.querySelector('#entries-table table');
      const workBook = XLSX.utils.table_to_book(element, {sheet: this.$t('reports.title')});

      // Delete last column (image count) before export
      xlsxHelper.deleteColumns(workBook.Sheets[workBook.SheetNames[0]], this.headers.length + 1, 2);

      const wbOut = XLSX.write(workBook, {bookType: 'xlsx', bookSST: true, type: 'binary'});

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }

      const fileName = `${this.filterDatesText} ${this.$t('title')} ${this.$t('reports.title')}${this.getSiteNames()}.xlsx`;

      saveAs(new Blob([s2ab(wbOut)], {type: 'application/octet-stream'}), fileName);
    },
    getSiteNames() {
      const filterSite = [];
      this.filterSite.forEach(siteId => {
        const siteIndex = this.sites.findIndex(site => parseInt(site.id, 10) === parseInt(siteId, 10));
        if (siteIndex > -1) {
          filterSite.push(this.sites[siteIndex].name);
        }
      });
      if (filterSite.length > 0) {
        return ' - ' + filterSite.join(' ');
      }
      return '';
    },
    async reloadCloudUsers() {
      try {
        const users = await this.$api.getCloudUsers();
        this.users = users;
      } catch (error) {
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_loading_users'),
          timeOut: 10000,
          showButton: true,
          buttonText: this.$t('try_again'),
          callback: () => {
            this.reloadCloudUsers();
          },
        });
        console.log(error);
      }
    },
    async loadImages(entryId) {
      try {
        const images = await this.$api.getEntryImages(entryId);
        return images;
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async reloadAll() {
      if (this.$store.state.entries.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.reloadCloudUsers();
      await this.$store.dispatch('reloadSites');
      await this.$store.dispatch('reloadEquipments');
      await this.$store.dispatch('reloadEntries');
      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
    async massUpdateInvoicedState() {
      const allInvoicedState = this.sameInvoicedState;
      const entryIds = [];
      this.filteredEntries.forEach(entry => entryIds.push(entry.id));
      await this.$store.dispatch('massUpdateInvoicedStates', {
        invoiced: allInvoicedState === true ? false : true,
        entryIds,
      });
    },
    getEntryDuration(entry) {
      if (!entry.entryStart) return 0;
      if (!entry.entryEnd) return this.$t('reports.unfinished');

      let diff;
      let start = entry.entryStart;
      let end = entry.entryEnd;

      if (entry.containsLunch) {
        end = moment(end).subtract(0.5, 'hours');
      }

      if (end.isBefore(start)) {
        diff = start.diff(end);
        return '-' + moment.duration(diff).asHours().toFixed(1);
      } else {
        diff = end.diff(start);
        return moment.duration(diff).asHours().toFixed(1);
      }
    },
    selectThisWeek() {
      this.filterDates = [moment().startOf('week').toISOString(), moment().endOf('week').toISOString()];
    },
    selectLastWeek() {
      this.filterDates = [
        moment().subtract(1, 'weeks').startOf('week').toISOString(),
        moment().subtract(1, 'weeks').endOf('week').toISOString(),
      ];
    },
    selectThisMonth() {
      this.filterDates = [moment().startOf('month').toISOString(), moment().endOf('month').toISOString()];
    },
    selectLastMonth() {
      this.filterDates = [
        moment().subtract(1, 'months').startOf('month').toISOString(),
        moment().subtract(1, 'months').endOf('month').toISOString(),
      ];
    },
    selectThisYear() {
      this.filterDates = [moment().startOf('year').toISOString(), moment().endOf('year').toISOString()];
    },
    selectLastYear() {
      this.filterDates = [
        moment().subtract(1, 'years').startOf('year').toISOString(),
        moment().subtract(1, 'years').endOf('year').toISOString(),
      ];
    },
  },
  computed: {
    filterDatesText() {
      const filterDates = [];
      this.filterDates.forEach(date => {
        filterDates.push(this.$formatDateNoTime(moment(date)));
      });
      filterDates.sort();
      return filterDates.join(' - ');
    },
    entries() {
      const entries = [...this.$store.state.entries];
      entries.map(e => {
        const userIndex = this.users.findIndex(u => u.id === e.userId);
        if (userIndex > -1) {
          e.user = this.users[userIndex].lastName
            ? `${this.users[userIndex].firstName} ${this.users[userIndex].lastName}`
            : this.users[userIndex].email;
        } else {
          e.user = this.$t('reports.could_not_find_user');
        }

        e.duration = this.getEntryDuration(e);

        e.equipmentString = '';
        e.equipments.forEach(eq => {
          e.equipmentString += eq.description + ' ';
        });
      });
      return entries;
    },
    equipments() {
      return this.$store.state.equipments;
    },
    sites() {
      if (this.filterCustomer.length > 0) {
        return this.$store.state.sites.filter(site => {

          return site.customer && site.customer.id && this.filterCustomer.includes(site.customer.id);
        });
      }

      return this.$store.state.sites;
    },
    customers() {
      return this.$store.state.customers;
    },
    invoiced() {
      return [
        {val: true, descr: this.$t('reports.invoiced')},
        {val: false, descr: this.$t('reports.not_invoiced')},
      ];
    },
    sameInvoicedState() {
      let t = 0;
      let f = 0;
      if (this.filteredEntries) {
        this.filteredEntries.forEach(entry => {
          if (entry.invoiced === true) t += 1;
          else f += 1;
        });
      }
      if (t === 0) return false;
      else if (f === 0) return true;
      else return undefined;
    },
    filteredEntries() {
      let filteredEntries = this.entries;

      const filterDates = [...this.filterDates];
      filterDates.sort();
      if (filterDates.length > 1) {
        filteredEntries = filteredEntries.filter(entry => {
          const entryStart = entry.entryStart.clone();
          const startIsInTimeRange = entryStart.isBetween(moment(filterDates[0]).startOf('day'), moment(filterDates[1]).endOf('day'));
          let endIsInTimeRange = true;
          if (entry.entryEnd) {
            const entryEnd = entry.entryEnd.clone();
            endIsInTimeRange = entryEnd.isBetween(moment(filterDates[0]).startOf('day'), moment(filterDates[1]).endOf('day'));
          }
          return startIsInTimeRange && endIsInTimeRange;
        });
      } else if (filterDates.length === 1) {
        filteredEntries = filteredEntries.filter(entry => {
          const entryStart = entry.entryStart.clone();
          const startIsSameDay = moment(entryStart).startOf('day').isSame(moment(filterDates[0]).startOf('day'));
          return startIsSameDay;
        });
      }

      if (
        this.filterUser.length === 0 &&
        this.filterSite.length === 0 &&
        this.filterCustomer.length === 0 &&
        this.filterEquipment.length === 0 &&
        this.filterInvoiced == null
      ) {
        return filteredEntries;
      }

      return filteredEntries.filter(entry => {
        let found = [];

        if (this.filterSite.length > 0) {
          const siteIndex = this.filterSite.findIndex(site => parseInt(entry.siteId, 10) === parseInt(site, 10));
          if (siteIndex > -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        if (this.filterCustomer.length > 0) {
          const customerIndex = this.filterCustomer.findIndex(customer => {
            if (entry.site && entry.site.customer) {
              return parseInt(entry.site.customer.id, 10) === parseInt(customer, 10);
            }
          });
          if (customerIndex > -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        if (this.filterUser.length > 0) {
          const userIndex = this.filterUser.findIndex(user => entry.userId === user);
          if (userIndex > -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        if (this.filterEquipment.length > 0) {
          let equipmentFound = false;
          entry.equipments.forEach(entryEquipment => {
            const equipmentIndex = this.filterEquipment.findIndex(equipment => entryEquipment.id === equipment);
            if (equipmentIndex > -1) {
              equipmentFound = true;
            }
          });
          found.push(equipmentFound);
        }

        if (this.filterInvoiced != null) {
          if (entry.invoiced === this.filterInvoiced) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        return found.indexOf(false) === -1;
      });
    },
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return filteredUsers.map(user => {
        const userCopy = Object.assign({}, user);
        userCopy.combinedInfo = `${userCopy.firstName} ${userCopy.lastName} ${userCopy.email}`;
        return userCopy;
      });
    },
  },
  mounted() {
    this.reloadAll();
  },
};
</script>

<style scoped>
tr.trow:hover {
//background-color: #616161 !important;
}
</style>
