<template>
  <v-container :fill-height="loading || entries.length === 0" :pa-0="$vuetify.breakpoint.xsOnly" class="timer-view"
               fluid>
    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{ $t('timer.title') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <template v-if="!loading">
        <v-row v-if="entries.length > 0" no-gutters>
          <v-col cols="12">
            <v-btn
              v-if="!activeEntry"
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="success"
              dark
              @click="openNewEntryInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">play_arrow</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('timer.create_new_entry') }}</span>
            </v-btn>
            <v-btn
              v-if="activeEntry"
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="warning"
              dark
              @click="openActiveEntryInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">stop</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('timer.stop_entry') }}</span>
            </v-btn>
          </v-col>

          <reminder-alert/>

          <v-col v-if="!activeEntry" cols="12">
            <v-container fluid pb-0 pt-0>
              <v-row>
                <v-col xs="12">
                  <v-subheader class="pl-1 orange--text">{{ $t('timer.want_to_continue_latest_entries') }}</v-subheader>
                  <v-card style="overflow: hidden">
                    <v-list class="pa-0" dense light>
                      <template v-for="(entry, index) in lastTwoEntries">
                        <v-list-item :key="entry.id + '_listitem'" :disabled="!!activeEntry" class="white"
                                     @click="openCopyInDialog(entry)">
                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon color="accent" size="30">play_arrow</v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{
                                entry.site ? entry.site.name : $t('timer.entry_has_no_site')
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ entry.site ? entry.site.address : '' }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider
                          v-if="index !== lastTwoEntries.length - 1"
                          :key="entry.id + '_divider'"
                          class="grey darken-3"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12">
            <v-list color="transparent" subheader two-line>
              <template v-for="day in entriesByDate">
                <v-subheader :key="day.date.format('YYYY_MM_DD') + '_header'" class="mt-5">
                  <v-row align="start" justify="space-between" no-gutters>
                    <v-col cols="9">
                      <span class="text-capitalize">{{ formatTime(day.date) }}</span
                      ><span class="grey--text text--darken-1">&nbsp;{{ $formatDateNoTime(day.date) }}</span>
                    </v-col>
                    <v-col class="text-right caption grey--text text--lighten-3" cols="3">
                      <duration-display :entries="day.entries"/>
                    </v-col>
                  </v-row>
                </v-subheader>

                <v-divider :key="day.date.format('YYYY_MM_DD') + '_divider'" class="secondary lighten-1"></v-divider>

                <template v-for="entry in day.entries">
                  <v-list-item
                    :key="entry.id + '_listitem'"
                    :class="entry.entryEnd ? 'secondary darken-1' : 'white'"
                    :light="!entry.entryEnd"
                    @click="openEntryInDialog(entry)"
                  >
                    <v-list-item-action-text>
                      <div class="pr-2">
                        <v-btn v-if="entry.entryEnd" :disabled="!!activeEntry" color="accent"
                               icon @click.stop="openCopyInDialog(entry)"
                        >
                          <v-icon>play_arrow</v-icon>
                        </v-btn
                        >
                        <v-btn v-if="!entry.entryEnd" color="warning" icon @click.stop="openActiveEntryInDialog(entry)"
                        >
                          <v-icon>stop</v-icon>
                        </v-btn
                        >
                      </div>
                    </v-list-item-action-text>
                    <v-list-item-content>
                      <v-list-item-title :class="!entry.site ? 'warning--text' : ''">
                        {{
                          entry.site ? entry.site.name : $t('timer.entry_has_no_site')
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="entry.site && entry.site.customer">
                        {{ entry.site.customer.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ entry.site ? entry.site.address : '' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      <v-col class="flex-column text-right" cols="12">
                        <span>
                          {{ $formatTimeNoDate(entry.entryStart) }} - {{
                            entry.entryEnd ? $formatTimeNoDate(entry.entryEnd) : $t('timer.entry_is_running')
                          }}
                        </span>
                        <div :class="!entry.entryEnd ? 'success--text' : ''" class="caption pl-2">
                          <duration-display :entries="[entry]"/>
                        </div>
                        <v-chip
                          :color="workTypeChip(entry.workType).color"
                          :dark="workTypeChip(entry.workType).dark"
                          x-small
                        >
                          {{ $t(`timer.work_types.${entry.workType}`) }}
                        </v-chip>
                      </v-col>
                    </v-list-item-action-text>
                  </v-list-item>
                  <v-divider :key="entry.id + '_divider'" class="secondary lighten-1"></v-divider>
                </template>
              </template>
            </v-list>
          </v-col>
        </v-row>

        <v-row
          v-if="entries.length === 0"
          align="stretch"
          align-content-lg="start"
          align-content-md="start"
          align-content-sm="start"
          align-content-xl="start"
          class="fill-height"
          no-gutters
        >
          <v-col cols="12">
            <v-btn
              v-if="!activeEntry"
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="success"
              dark
              @click="openNewEntryInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">play_arrow</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('timer.create_new_entry') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center" no-gutters>
              <div
                :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '' }"
                class="text-center pl-5 pr-5"
                style="padding-bottom: 150px"
              >
                <v-icon color="secondary lighten-2" size="100">access_time</v-icon>
                <div class="headline font-weight-bold mt-3 mb-3">{{ $t('timer.no_entries') }}</div>
                <div v-if="$vuetify.breakpoint.smAndUp" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('timer.no_entries_help') }}.
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('timer.no_entries_help_xs') }}.
                </div>
                <v-btn class="mt-5" outlined @click="$reloadApp">
                  <v-icon left>refresh</v-icon>
                  {{ $t('reload') }}
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-fade-transition>

    <entry-dialog ref="entryDialog"/>
  </v-container>
</template>

<script>
import moment from 'moment';
import EntryDialog from '../components/Timer/EntryDialog.vue';
import DurationDisplay from '../components/Timer/DurationDisplay.vue';
import ReminderAlert from '../components/Timer/ReminderAlert.vue';
import EntryService from '../service/entry.service';

export default {
  name: 'TimerView',
  components: {
    EntryDialog: EntryDialog,
    DurationDisplay: DurationDisplay,
    ReminderAlert: ReminderAlert,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      offsetTop: 0,
      currentTime: false,
    };
  },
  methods: {
    workTypeChip(workType) {
      if (typeof workType !== 'number') {
        return
      }
      const response = {
        color: 'grey',
        dark: true,
      };
      if (workType === 0) {
        response.color = 'primary darken-1';
        response.dark = true;
      } else if (workType === 1) {
        response.color = 'secondary lighten-2';
        response.dark = true;
      } else if (workType === 2) {
        response.color = 'info darken-2';
        response.dark = true;
      }
      return response;
    },
    canModifyEntry(entry) {
      return EntryService.canModifyEntry(entry, this.$userInfo);
    },
    openActiveEntryInDialog() {
      this.$refs.entryDialog.openEntry(this.activeEntry, true, false, true, true);
    },
    openNewEntryInDialog() {
      this.$refs.entryDialog.openNew(true);
    },
    openEntryInDialog(entry) {
      this.$refs.entryDialog.openEntry(entry, false, false, this.canModifyEntry(entry));
    },
    openCopyInDialog(entry) {
      const entryCopy = {
        id: null,
        info: '',
        entryStart: null,
        entryEnd: null,
        siteId: entry.siteId,
        site: entry.site,
        equipment: null,
        workType: entry.workType,
        containsLunch: false,
        equipmentIds: [],
        deleted: false,
      };

      this.$refs.entryDialog.openEntry(entryCopy, false, true);
    },
    formatTime(time) {
      // @todo locale for multiple languages in case project ever goes multilingual
      return moment(time).locale('FI-fi').calendar(null, {
        sameDay: '[Tänään]',
        nextDay: '[Huomenna]',
        nextWeek: 'dddd[na]',
        lastDay: '[Eilen]',
        lastWeek: 'dddd',
        sameElse: 'dddd',
      });
    },
    async reloadAll() {
      if (this.$store.state.entries.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadSites');
      await this.$store.dispatch('reloadEquipments');
      await this.$store.dispatch('reloadMyEntries');
      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
  },
  computed: {
    activeEntry() {
      const entryWithoutEnd = this.entries.findIndex(entry => !entry.entryEnd);
      if (entryWithoutEnd > -1) {
        return this.entries[entryWithoutEnd];
      }
      return false;
    },
    entries() {
      const entries = [...this.$store.state.myEntries];
      // const userSubject = this.$userInfo.subject;
      // return entries.filter(e => e.user.subject === userSubject);
      return entries;
    },
    sites() {
      return this.$store.state.sites;
    },
    entriesByDate() {
      const entriesByDate = [];
      this.entries.forEach(entry => {
        const ebdIndex = entriesByDate.findIndex(ebd => moment(ebd.date).startOf('day').isSame(moment(entry.entryStart).startOf('day')));
        if (ebdIndex > -1) {
          entriesByDate[ebdIndex].entries.push(entry);
        } else {
          entriesByDate.push({
            date: entry.entryStart,
            entries: [entry],
          });
        }
      });
      entriesByDate.map(ebd => {
        ebd.entries.sort((a, b) => {
          if (!a.entryEnd && !b.entryEnd) return 0;
          if (!a.entryEnd) return -1;
          if (!b.entryEnd) return 1;
          return b.entryStart - a.entryStart;
        });
      });
      entriesByDate.sort((a, b) => {
        return b.date - a.date;
      });
      return entriesByDate;
    },
    lastTwoEntries() {
      const entries = [...this.entries];
      entries.sort((a, b) => {
        if (!a.entryEnd && !b.entryEnd) return 0;
        if (!a.entryEnd) return -1;
        if (!b.entryEnd) return 1;
        return b.entryStart - a.entryStart;
      });
      const latestEntriesBySideId = [];
      entries.forEach(entry => {
        if (latestEntriesBySideId.findIndex(ge => ge.siteId === entry.siteId) === -1) {
          latestEntriesBySideId.push(entry);
        }
      });
      return latestEntriesBySideId.slice(0, 2);
    },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    if (this.$isLoggedIn) {
      this.reloadAll();
    }
  },
};
</script>

<style scoped></style>
