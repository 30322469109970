<template>
  <v-container>
    <v-row v-if="!loading" align-content="start" class="fill-height" justify="center">
      <v-col cols="12" md='10' xl="8">
        <v-btn
          :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.xsOnly }"
          :large="$vuetify.breakpoint.smAndUp"
          color="primary"
          dark
          @click="exportExcel"
        >
          <v-icon left>save_alt</v-icon>
          <span>{{ $t('customer.export_pricing') }}</span>
        </v-btn>
        <v-btn
          :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp }"
          :large="$vuetify.breakpoint.smAndUp"
          color="primary"
          dark
          @click="importExcel"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">upload</v-icon>
          <span>{{ $t('customer.import_pricing') }}</span>
        </v-btn>
      </v-col>

      <v-col cols="12" md='10' xl="8">
        <template v-for="(type) in sortedActiveItemTypes">
          <v-card v-if="getItemsByItemType(type.id) && getItemsByItemType(type.id).length"
                  :key="type.id"
                  color="secondary darken-1 mb-5">
            <v-card-title class="accent--text">
              {{ type.shortDescription }}
            </v-card-title>
            <v-data-table
              v-if="getItemsByItemType(type.id) && getItemsByItemType(type.id).length"
              :headers="headers"
              :items="getItemsByItemType(type.id)"
              :items-per-page="getItemsByItemType(type.id).length"
              :loading="loading"
              class="transparent"
              hide-default-footer
            >
              <template v-slot:item.code="{ item }">
                <span :key="item.id + '-code'">{{ item.code }}</span>
              </template>

              <template v-slot:item.shortDescription="{ item }">
                <span :key="item.id + '-shortDescription'">{{ item.shortDescription }}</span>
              </template>

              <template v-slot:item.price="{ item }">
                <span :key="item.id + '-price'">{{ item.price }} / {{ item.unit }}</span>
              </template>

              <template v-slot:item.customerPrice="{ item }">
                <v-text-field
                  :key="item.id + '-customerPrice'"
                  :ref="`customerPrice-${item.id}`"
                  v-model.number="item.customerPrice"
                  :disabled="loadingItems.includes(item.id)"
                  :rules="priceRules"
                  class="mt-2 mb-n2 pb-0 pt-0 price-field"
                  inputmode="decimal"
                  prefix="€"
                  reverse
                  step="0.01"
                  type="number"
                  validate-on-blur
                  @input="handleCustomerPriceChange(item, $event)"
                >
                  <template v-slot:append>
                    <v-progress-circular
                      v-if="loadingItems.includes(item.id)"
                      color="primary"
                      indeterminate
                      size="20"
                    ></v-progress-circular>
                  </template>
                </v-text-field>

              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn :disabled="item.customerPrice == null" color="error" icon small
                       @click="removeCustomerPrice(item)">
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

        </template>
      </v-col>
    </v-row>

    <input ref="importFileInput" :multiple="false"
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
           style="display: none"
           type="file"
           @change="onImportFileChanged"/>
  </v-container>
</template>

<script>
import _, {debounce} from 'lodash';
import apiCollection from "@/api";
import downloadjs from "downloadjs";

export default {
  name: 'CustomerPricing',
  props: ['customerId', 'customerName'],
  data() {
    return {
      loading: false,
      exportExcelLoading: false,
      importExcelLoading: false,
      loadingItems: [],
      search: '',
      debouncedSaveCustomerPrice: {},
      headers: [
        {text: this.$t('items.code'), value: 'code', width: '100px'},
        {text: this.$t('items.short_desc'), value: 'shortDescription'},
        {text: this.$t('items.price'), value: 'price', width: '110px'},
        {text: this.$t('items.price_for_customer'), value: 'customerPrice', width: '180px'},
        {
          text: this.$t('customers.remove_customer_price'),
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '130px'
        },
      ],
      priceRules: [
        (value) => value === null || value === '' || /^(\d+\.)?\d+$/.test(value) || this.$t('validation.price_rule'),
      ],
    };
  },
  computed: {
    itemTypes() {
      return [...this.$store.state.itemTypes];
    },
    sortedActiveItemTypes() {
      const typesWithItems = this.itemTypes.filter((type) =>
        this.$store.state.items.some((item) => item.type.id === type.id && item.active),
      );
      return _.orderBy(typesWithItems, ['ordernum'], ['asc']);
    },
    customerPrices() {
      return [...this.$store.state.customerPrices];
    }
  },
  methods: {
    async removeCustomerPrice(item) {
      this.loadingItems.push(item.id);
      await this.$store.dispatch('deleteCustomerPrice', {customerId: this.customerId, itemId: item.id});
      this.loadingItems = this.loadingItems.filter(id => id !== item.id);
    },
    getItemsByItemType(itemTypeId) {
      const itemsByItemType = this.$store.getters.getItemsByItemType(itemTypeId);

      return itemsByItemType.map(item => {
        const customerPriceObj = this.customerPrices.find(priceObj => priceObj.id === item.id);
        return {
          ...item,
          customerPrice: customerPriceObj ? customerPriceObj.customerPrice : null
        };
      });
    },
    saveCustomerPrice: function (item, newValue) {
      this.loadingItems.push(item.id);
      this.$store.dispatch('upsertCustomerPrice', {
        customerId: this.customerId,
        itemId: item.id,
        customerPrice: newValue
      })
      this.loadingItems = this.loadingItems.filter(id => id !== item.id);
    },
    async exportExcel() {
      this.exportExcelLoading = true;
      try {
        const currentDate = new Date().toISOString().slice(0, 10);
        const blob = await apiCollection.exportExcel(this.customerId);
        downloadjs(blob, `${this.customerName.replace(/ /g, '_')}_hinnoittelu_${currentDate}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      } catch (error) {
        this.$handleApiError(error);
      }
      this.exportExcelLoading = false;
    },
    importExcel() {
      this.$refs.importFileInput.value = null;
      this.$refs.importFileInput.click();
    },
    async onImportFileChanged(event) {
      if (event.target.files) {
        this.importExcelLoading = true;
        try {
          await apiCollection.importExcel(this.customerId, event.target.files[0]);
          this.$showSuccessNotification(this.$t('customer.import_successful'));
          await this.$store.dispatch('loadCustomerPrices', this.customerId);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.importExcelLoading = false;
        this.$emit('reload');
      }
    },
    handleCustomerPriceChange(item, newValue) {
      if (!this.debouncedSaveCustomerPrice[item.id]) {
        this.debouncedSaveCustomerPrice[item.id] = debounce(async (item, newValue) => {
          // Check if the input value passes the validation rules
          const isValid = this.priceRules.every((rule) => rule(newValue) === true);

          if (isValid) {
            if (newValue === '' || newValue === null) {
              await this.removeCustomerPrice(item);
            } else {
              await this.saveCustomerPrice(item, newValue);
            }
          }
        }, 750);
      }
      this.debouncedSaveCustomerPrice[item.id](item, newValue);
    },

  },
  mounted() {
    this.$store.dispatch('reloadItemTypes');
    this.$store.dispatch('reloadItems');
    this.$store.dispatch('loadCustomerPrices', this.customerId);
  },
};
</script>

<style lang="scss">
.price-field {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
