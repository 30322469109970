<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    @keydown.esc="dialog = false"
    @keydown.enter="saveEquipment"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card color="secondary darken-1">
      <v-toolbar dark color="secondary">
        <v-toolbar-title>{{
          currentEquipment.id ? $t('equipments.edit_equipment') : $t('equipments.create_new_equipment')
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <template v-if="$isAdmin">
          <v-btn
            color="warning"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentEquipment.id && currentEquipment.status === 10"
            @click="setEquipmentInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentEquipment.id && currentEquipment.status === 0"
            @click="setEquipmentActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>

        <v-form ref="form">
          <v-text-field
            class="mt-5 equipmentdescription"
            :label="$t('equipments.description')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            v-model="currentEquipment.description"
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <template v-if="$isAdmin">
          <v-btn
            color="warning"
            v-if="$vuetify.breakpoint.smAndUp && currentEquipment.id && currentEquipment.status === 10"
            @click="setEquipmentInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            v-if="$vuetify.breakpoint.smAndUp && currentEquipment.id && currentEquipment.status === 0"
            @click="setEquipmentActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-btn
          color="default"
          @click="dialog = false"
          :disabled="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          @click="saveEquipment(false)"
          :loading="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EquipmentDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      currentEquipment: {},
      equipmentTemplate: {
        description: '',
        status: 10,
      },
    };
  },
  methods: {
    setEquipmentInactive() {
      this.currentEquipment.status = 0;
      this.saveEquipment(true);
    },
    setEquipmentActive() {
      this.currentEquipment.status = 10;
      this.saveEquipment(true);
    },
    async saveEquipment(statusChange) {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      const saveSuccess = await this.$store.dispatch('upsertEquipment', this.currentEquipment);
      if (saveSuccess) {
        this.dialog = false;
      } else if (statusChange) {
        this.currentEquipment.status = this.currentEquipment.status === 10 ? 0 : 10;
      }

      this.saving = false;
    },
    openEquipment(equipment) {
      this.dialog = true;
      this.currentEquipment = Object.assign({}, equipment);
    },
    openNew() {
      this.dialog = true;
      this.currentEquipment = Object.assign({}, this.equipmentTemplate);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
