<template>
  <v-container fluid :fill-height="loading" class="single-equipment-view fill-height">
    <v-progress-linear
      style="position: fixed; left: 0; right: 0; margin: 0; z-index: 99"
      :style="{ top: $vuetify.breakpoint.smAndDown ? '54px' : '62px' }"
      color="primary lighten-2"
      height="2"
      v-show="subtleLoading"
      :indeterminate="true"
    ></v-progress-linear>

    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      dark
      fixed
      app
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-back-button fallback="equipments" />
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        {{ equipment ? equipment.description : '' }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row v-if="!loading" class="fill-height pb-12" align-content="start" justify="center" no-gutters>
        <v-col cols="12" xl="8" md="10">
          <v-row align-content="start" justify="center">
            <v-col cols="12">
              <v-card color="secondary darken-1">
                <v-card-title class="accent--text">
                  {{ $t('equipments.equipment_information') }}
                </v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-text-field
                      class="equipmentdescription"
                      :label="$t('equipments.description')"
                      :rules="[$rules.required]"
                      validate-on-blur
                      :disabled="!$isAdmin"
                      type="text"
                      v-model="currentEquipment.description"
                    ></v-text-field>
                  </v-form>

                  <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
                </v-card-text>
                <v-card-actions v-if="$isAdmin">
                  <template v-if="$isAdmin">
                    <v-btn
                      color="warning"
                      v-if="$vuetify.breakpoint.smAndUp && currentEquipment.id && currentEquipment.status === 10"
                      @click="setEquipmentInactive"
                      :disabled="saving"
                    >
                      <span>{{ $t('set_inactive') }}</span>
                    </v-btn>
                    <v-btn
                      color="success"
                      v-if="$vuetify.breakpoint.smAndUp && currentEquipment.id && currentEquipment.status === 0"
                      @click="setEquipmentActive"
                      :disabled="saving"
                    >
                      <span>{{ $t('set_active') }}</span>
                    </v-btn>
                  </template>
                  <v-spacer></v-spacer>
                  <v-btn color="default" @click="setCurrentEquipment" :disabled="saving">
                    <span>{{ $t('cancel') }}</span>
                  </v-btn>
                  <v-btn color="primary" @click="saveEquipment" :loading="saving">
                    <span>{{ $t('save') }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12">
              <equipment-checks :equipment="equipment" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>

import EquipmentChecks from "@/components/SingleEquipment/EquipmentChecks";
export default {
  name: 'SingleEquipmentView',
  components: {EquipmentChecks},
  props: ['id'],
  data() {
    return {
      loading: true,
      subtleLoading: false,
      saving: false,
      showAlert: false,
      currentEquipment: {},
      tab: 0,
    };
  },
  methods: {
    setEquipmentInactive() {
      this.currentEquipment.status = 0;
      this.saveEquipment(true);
    },
    setEquipmentActive() {
      this.currentEquipment.status = 10;
      this.saveEquipment(true);
    },
    async saveEquipment(statusChange) {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      const saveSuccess = await this.$store.dispatch('upsertEquipment', this.currentEquipment);
      if (saveSuccess) {
        this.dialog = false;
      } else if (statusChange) {
        this.currentEquipment.status = this.currentEquipment.status === 10 ? 0 : 10;
      }

      this.saving = false;
    },
    async reloadEquipments() {
      if (this.$store.state.equipments.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadEquipments');

      this.loading = false;
      this.subtleLoading = false;
    },
    setCurrentEquipment() {
      this.showAlert = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      const equipmentIndex = this.$store.state.equipments.findIndex(equipment => parseInt(equipment.id) === parseInt(this.id));
      if (equipmentIndex > -1) {
        this.currentEquipment = {...this.$store.state.equipments[equipmentIndex]};
      }
    },
  },
  computed: {
    equipments() {
      return this.$store.state.equipments;
    },
    equipment() {
      const equipmentIndex = this.equipments.findIndex(equipment => parseInt(equipment.id) === parseInt(this.id));
      if (equipmentIndex > -1) {
        return this.equipments[equipmentIndex];
      }
      return false;
    },
  },
  beforeMount() {
    if (this.currentEquipment && this.id) {
      this.currentEquipment.id = parseInt(this.id);
    }
  },
  async mounted() {
    await this.reloadEquipments();
    await this.setCurrentEquipment();
  },
};
</script>

<style scoped></style>
