<template>
  <v-col v-if="items && items.length" cols="12">
    <v-card color="secondary darken-1">
      <v-card-title class="accent--text text-break">
        <div v-if="displayInactiveItems">
          {{ $t('items.inactive_items_title') }}
        </div>
        <div v-if="!displayInactiveItems && itemType">
          {{ itemType.shortDescription }} <small v-if="!itemType.active"
                                                 class="warning--text">{{ $t('item_types.item_type_inactive') }}</small>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          :class="$vuetify.breakpoint.xsOnly ? 'mt-2' : ''"
          :disabled="!itemType || !itemType.active"
          color="primary"
          dark
          small
          @click="$emit('open-new', itemType)"
        >
          <v-icon left>mdi mdi-plus</v-icon>
          <span>{{ $t('items.create_new') }}</span>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="items.length > 0">
        <v-data-table
          :footer-props="{itemsPerPageText: $t('rows_per_page'), itemsPerPageAllText: $t('all'),  itemsPerPageOptions: [10, 25, 50, -1]}"
          :header-props="{sortByText: $t('sort_by')}"
          :headers="headers"
          :items="filteredItems"
          :items-per-page="15"
          :loading-text="$t('loading')"
          class="transparent items-table"
          dense
          @click:row="rowClicked"
        >
          <template v-slot:item.code="{ item }">
            <div>{{ item.code }}</div>
          </template>
          <template v-slot:item.shortDescription="{ item }">
            <div>{{ item.shortDescription }}</div>
          </template>
          <template v-slot:item.longDescription="{ item }">
            <div>{{ item.longDescription }}</div>
          </template>
          <template v-slot:item.unit="{ item }">
            <div>{{ item.unit }}</div>
          </template>
          <template v-if="displayInactiveItems" v-slot:item.type="{ item }">
            <div class="text-truncate" style="max-width: 300px; width: 100%">{{ item.type.shortDescription }}</div>
          </template>
          <template v-slot:item.price="{ item }">
            <div>{{
                Number(item.price).toLocaleString('fi', {minimumFractionDigits: 2, maximumFractionDigits: 2})
              }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text v-else>
        <v-list class="secondary darken-1" dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ noContent }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'ItemsViewItemType',
  props: {
    noContent: {
      type: String,
      default: '',
    },
    displayInactiveItems: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    itemType: {
      type: Object,
      default: null,
    },
    searchPhrase: {
      type: String,
      default: '',
    }
  },
  computed: {
    headers() {
      if (this.displayInactiveItems) {
        return [
          {text: this.$t('items.code'), value: 'code', width: '100px'},
          {text: this.$t('items.short_desc'), value: 'shortDescription', width: '300px'},
          {text: this.$t('items.long_desc'), value: 'longDescription'},
          {text: this.$t('items.type'), value: 'type', width: '300px'},
          {text: this.$t('items.unit'), value: 'unit', width: '100px', align: 'right'},
          {text: this.$t('items.price'), value: 'price', align: 'right', width: '100px'},
        ]
      } else {
        return [
          {text: this.$t('items.code'), value: 'code', width: '100px'},
          {text: this.$t('items.short_desc'), value: 'shortDescription', width: '300px'},
          {text: this.$t('items.long_desc'), value: 'longDescription'},
          {text: this.$t('items.unit'), value: 'unit', width: '100px', align: 'right'},
          {text: this.$t('items.price'), value: 'price', align: 'right', width: '100px'},
        ]
      }
    },
    filteredItems() {
      const itemsToFilter = this.items;

      if (this.searchPhrase && this.searchPhrase !== '') {
        const searchPhraseLower = this.searchPhrase.toLowerCase();
        const searchPhraseArray = searchPhraseLower.split(' ');

        return itemsToFilter.filter(item => {
          let found = [];
          const lowerSearchedString =
            (item.code ? item.code.toLowerCase() : '') +
            (item.shortDescription ? item.shortDescription.toLowerCase() : '') +
            (item.longDescription ? item.longDescription.toLowerCase() : '');
          searchPhraseArray.forEach(phrase => {
            if (lowerSearchedString.indexOf(phrase) !== -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          });
          return found.indexOf(false) === -1;
        });
      } else {
        return itemsToFilter;
      }
    },
  },
  methods: {
    rowClicked(item) {
      this.$emit('open-item', item.id);
    },
  },
};
</script>
<style scoped>
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100% !important;
}
</style>
