import Vue from 'vue';
import Vuex from 'vuex';
import apiCollection from './api.js';
import i18n from './i18n.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mainMenuOpen: false,
    notificationObject: {
      color: 'alert',
      message: '',
      timeOut: 4000,
      showButton: false,
      buttonText: '',
      callback: () => {
      },
    },
    loadingOverlay: false,
    items: [],
    itemTypes: [],
    entries: [],
    myEntries: [],
    sites: [],
    equipments: [],
    customers: [],
    customerPrices: [],
    siteItems: [],
    siteIntroductions: [],
    selectedEntriesForSite: [],
  },
  mutations: {
    toggleMainMenuState(state) {
      state.mainMenuOpen = !state.mainMenuOpen;
    },
    setPreviousRouteName(state, val) {
      state.previousRouteName = val;
    },
    setLoadingOverlay(state, payload) {
      state.loadingOverlay = payload;
    },
    setEntries(state, payload) {
      state.entries = payload;
    },
    setMyEntries(state, payload) {
      state.myEntries = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setEquipment(state, payload) {
      state.equipments = payload;
    },
    setCustomers(state, payload) {
      state.customers = payload;
    },
    setItems(state, payload) {
      state.items = payload;
    },
    setItemTypes(state, payload) {
      state.itemTypes = payload;
    },
    setCustomerPrices(state, customerPrices) {
      state.customerPrices = customerPrices;
    },
    updateCustomerPrice(state, payload) {
      const index = state.customerPrices.findIndex((price) => price.id === payload.id);
      Vue.set(state.customerPrices, index, payload);
    },
    removeCustomerPrice(state, {itemId}) {
      const index = state.customerPrices.findIndex(price => price.id === itemId);
      if (index !== -1) {
        state.customerPrices.splice(index, 1);
      }
    },
    setSiteItems(state, payload) {
      state.siteItems = payload;
    },
    setSiteIntroductions(state, payload) {
      state.siteIntroductions = payload;
    },
    setSelectedEntriesForSite(state, payload) {
      state.selectedEntriesForSite = payload;
    }
  },
  actions: {
    showNotification(context, payload) {
      context.state.notificationObject = {
        color: payload.color,
        message: payload.message,
        timeOut: payload.timeOut !== undefined ? payload.timeOut : 4000,
        showButton: payload.showButton !== undefined ? payload.showButton : false,
        buttonText: payload.buttonText !== undefined ? payload.buttonText : '',
        callback: payload.showButton && payload.callback ? payload.callback : () => {
        },
      };
    },
    async reloadEntries(context) {
      try {
        const allEntries = await apiCollection.getAllEntries();
        allEntries.map(entry => {
          if (entry.equipments) {
            entry.equipmentIds = entry.equipments.map(e => e.id);
          } else {
            entry.equipmentIds = [];
          }
        });
        context.commit('setEntries', allEntries);
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_entries'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadEntries');
          },
        });
        console.log(error);
      }
    },
    async reloadMyEntries(context) {
      try {
        const myEntries = await apiCollection.getMyEntries();
        myEntries.map(entry => {
          if (entry.equipments) {
            entry.equipmentIds = entry.equipments.map(e => e.id);
          } else {
            entry.equipmentIds = [];
          }
        });
        context.commit('setMyEntries', myEntries);
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_entries'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadMyEntries');
          }
        });
      }
    },
    async upsertEntry(context, payload) {
      try {
        const entry = await apiCollection.upsertEntry(payload);
        return entry;
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_entry'),
          showButton: false,
        });
        console.log(error);
      }
      return null;
    },
    async reloadSites(context) {
      try {
        const allSites = await apiCollection.getAllSites();
        context.commit('setSites', allSites);
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_sites'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadSites');
          },
        });
        console.log(error);
      }
    },
    async upsertSite(context, payload) {
      try {
        const response = await apiCollection.upsertSite(payload);
        await context.dispatch('reloadSites');
        context.dispatch('showNotification', {
          color: 'success',
          message: i18n.t('sites.site_saved_succesfully'),
          showButton: false,
        });
        return response;
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_site'),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
    async reloadEquipments(context) {
      try {
        const allEquipment = await apiCollection.getAllEquipments();
        context.commit('setEquipment', allEquipment);
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_equipment'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadEquipment');
          },
        });
        console.log(error);
      }
    },
    async upsertEquipment(context, payload) {
      try {
        await apiCollection.upsertEquipment(payload);
        await context.dispatch('reloadEquipments');
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_equipment'),
          showButton: false,
        });
        console.log(error);
        return false;
      }
      return true;
    },
    async massUpdateInvoicedStates(context, payload) {
      try {
        const response = await apiCollection.massUpdateInvoiced(payload);
        await context.dispatch('reloadEntries');
        return response;
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_mass_updating_invoiced_state'),
          showButton: false,
        });
        console.log(error);
        return false;
      }
    },
    async reloadCustomers(context) {
      try {
        const allCustomers = await apiCollection.getAllCustomers();
        context.commit('setCustomers', allCustomers);
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_customers'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadCustomers');
          },
        });
        console.log(error);
      }
    },
    async upsertCustomer(context, payload) {
      try {
        await apiCollection.upsertCustomer(payload);
        await context.dispatch('reloadCustomers');
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_customer'),
          showButton: false,
        });
        console.log(error);
        return false;
      }
      return true;
    },
    async reloadItemTypes(context) {
      try {
        const allItems = await apiCollection.getAllItemTypes();
        context.commit('setItemTypes', allItems);
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_item_types'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadItemTypes');
          },
        });
        console.log(e);
      }
    },
    async upsertItemType(context, payload) {
      try {
        await apiCollection.upsertItemType(payload);
        // await context.dispatch('reloadItemTypes');
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_item_type'),
          showButton: false,
        });
        console.log(e);
        return false;
      }
      return true;
    },
    async reorderItemTypes(context, payload) {
      try {
        await apiCollection.reorderItemTypes(payload);
        await context.dispatch('reloadItemTypes');
      } catch (error) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_reordering_item_types'),
          showButton: false,
        });
        console.log(error.Message);
      }
    },
    async reloadItems(context) {
      try {
        const allItems = await apiCollection.getAllItems();
        context.commit('setItems', allItems);
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_items'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('reloadItems');
          },
        });
        console.log(e);
      }
    },
    async upsertItem(context, payload) {
      try {
        await apiCollection.upsertItem(payload);
        await context.dispatch('reloadItems');
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_item'),
          showButton: false,
        });
        console.log(e);
        return false;
      }
      return true;
    },
    async loadCustomerPrices(context, customerId) {
      try {
        const prices = await apiCollection.getAllPricesForCustomer(customerId);
        context.commit('setCustomerPrices', prices);
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_prices'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('getAllPricesForCustomer', customerId);
          },
        });
        console.log(e);
      }
    },
    async upsertCustomerPrice(context, payload) {
      try {
        const updatedPrice = await apiCollection.upsertCustomerPrice(payload.customerId, payload.itemId, {
          customerPrice: payload.customerPrice,
        });
        context.commit('updateCustomerPrice', updatedPrice);
      } catch (e) {
        context.dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_customer_price'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            context.dispatch('updateCustomerPrice', payload);
          },
        });
        console.log(e);
      }
    },
    async deleteCustomerPrice({dispatch, commit}, {customerId, itemId}) {
      try {
        await apiCollection.deleteCustomerPrice(customerId, itemId);
        commit('removeCustomerPrice', {itemId});
        dispatch('loadCustomerPrices', customerId);
      } catch (e) {
        dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_deleting_customer_price'),
          timeOut: 10000,
          showButton: false,
        });
        console.log(e.Message);
      }
    },
    async loadSiteItems({dispatch, commit}, siteIds) {
      try {
        const siteItems = await apiCollection.getAllItemsForSites(siteIds);
        commit('setSiteItems', siteItems);
      } catch (e) {
        dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_site_items'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            dispatch('loadSiteItems', siteIds);
          },
        });
        console.log(e.Message);
      }
    },
    async createSiteItem({dispatch}, {siteId, payload}) {
      try {
        await apiCollection.createSiteItem(siteId, payload);
        dispatch('loadSiteItems');
      } catch (e) {
        dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_creating_site_item'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            dispatch('createSiteItem', siteId, payload);
          },
        });
        console.log(e.Message);
      }
    },
    async updateSiteItem({dispatch}, {siteId, siteItemId, payload}) {
      try {
        await apiCollection.updateSiteItem(siteItemId, siteId, payload);
        dispatch('loadSiteItems');
      } catch (e) {
        dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_updating_site_item'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            dispatch('updateSiteItem', siteId, siteItemId, payload);
          },
        });
        console.log(e.Message);
      }
    },
    async loadSiteIntroductions({dispatch, commit}, siteId) {
      try {
        const siteIntroductions = await apiCollection.getAllIntroductionsForSite(siteId);
        commit('setSiteIntroductions', siteIntroductions)
      } catch (e) {
        dispatch('showNotification', {
          color: 'error',
          message: i18n.t('errors.error_loading_site_introductions'),
          timeOut: 10000,
          showButton: true,
          buttonText: i18n.t('try_again'),
          callback: () => {
            dispatch('loadSiteIntroductions', siteId);
          },
        });
        console.log(e.Message);
      }
    },
  },
  getters: {
    getCustomer: state => (id) => {
      return state.customers.find(customer => customer.id === id)
    },
    getItemById: (state) => (id) => {
      return state.items.find((item) => item.id === id);
    },
    getCustomerPriceByItemId: (state) => (itemId) => {
      return state.customerPrices.find((price) => price.item.id === itemId);
    },
    getItemsByItemType: (state) => (itemTypeId) => {
      return state.items.filter((item) => item.type.id === itemTypeId && item.active);
    },
    getSiteItems: state => {
      return state.siteItems.filter(si => si.amount && si.amount > 0);
    }
  }
});
