<template>
  <v-container :fill-height="loading || equipments.length === 0" :pa-0="$vuetify.breakpoint.xsOnly" class="equipments-view"
               fluid>

    <v-app-bar :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'" :elevate-on-scroll="$vuetify.breakpoint.smAndDown" app dark
               fixed>

      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%;">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$t('equipments.search')}...`"
          class="topbar-search"
          clearable
          full-width
          hide-details
          single-line
          style="font-size: 20px;"
        >
          <v-icon slot="append" style="cursor: pointer;">search</v-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <template v-if="!loading">
        <v-row v-if="equipments.length > 0" no-gutters>
          <v-col :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}" cols="12" lg="9" sm="8" xl="10">
            <v-btn
              v-if="$isAdmin || $isSuperior"
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewEquipmentInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('equipments.create_new_equipment') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" lg="3" sm="4" xl="2">
            <v-select
              v-model="filter"
              :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
              :items="filterOptions"
              :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
              background-color="secondary lighten-1"
              hide-details
              item-text="desc"
              item-value="value"
              multiple
              prepend-inner-icon="filter_list"
              single-line
              solo
            >
              <template v-slot:selection="{ item, index }">
                <template v-if="filter.length !== filterOptions.length && index === 0">
                  <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
                  <span class="grey--text caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
                </template>
                <template v-else>
                  <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
                </template>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-list color="transparent" two-line>

              <v-list-item>
                <v-list-item-avatar><span class="grey--text caption">{{ $t('equipments.status') }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="grey--text caption">{{ $tc('equipments.title', 1) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <template v-for="equipment in filteredEquipments">
                <v-list-item :key="equipment.id + '_listitem'" :to="{name: 'equipment', params: {id: equipment.id}}">
                  <v-list-item-avatar>
                    <v-icon :color="getStatusColor(equipment.status)">radio_button_checked</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title :class="equipment.status === 0 ? 'grey--text' : ''">{{
                        equipment.description
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="equipment.id + '_divider'"></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>

        <v-row v-if="equipments.length === 0" align="stretch" align-content-lg="start" align-content-md="start" align-content-sm="start"
               align-content-xl="start" class="fill-height" no-gutters>
          <v-col cols="12">
            <v-btn
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{'ma-0': $vuetify.breakpoint.smAndUp}"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewEquipmentInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('equipments.create_new_equipment') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center" no-gutters>
              <div :style="{paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : ''}" class="text-center pl-5 pr-5"
                   style="padding-bottom: 150px;">
                <v-icon color="secondary lighten-2" size="100">fas fa-snowplow</v-icon>
                <div class="headline font-weight-bold mt-3 mb-3">{{ $t('equipments.no_equipments') }}</div>
                <div v-if="$vuetify.breakpoint.smAndUp" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('equipments.no_equipments_help') }}.
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('equipments.no_equipments_help_xs') }}.
                </div>
                <v-btn class="mt-5" outlined @click="$reloadApp">
                  <v-icon left>refresh</v-icon>
                  {{ $t('reload') }}
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>

      </template>
    </v-fade-transition>

    <equipment-dialog ref="equipmentDialog"/>

  </v-container>
</template>

<script>
import EquipmentDialog from "../components/Equipments/EquipmentDialog.vue";

export default {
  name: "EquipmentsView",
  components: {
    EquipmentDialog: EquipmentDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      offsetTop: 0,
      filter: [0],
      filterOptions: [
        {desc: "Aktiiviset", value: 0},
        {desc: "Ei aktiiviset", value: 1},
      ],
    }
  },
  methods: {
    openNewEquipmentInDialog() {
      this.$refs.equipmentDialog.openNew();
    },
    openEquipmentInDialog(equipment) {
      this.$refs.equipmentDialog.openEquipment(equipment);
    },
    async reloadEquipments() {
      if (this.$store.state.equipments.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadEquipments');

      this.loading = false;
      this.subtleLoading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'grey darken-1';
        case 10:
          return 'success'
      }
    },
  },
  computed: {
    equipments() {
      return this.$store.state.equipments;
    },
    filteredEquipments() {
      const filteredEquipments = this.equipments;

      const filterOutActive = this.filter.findIndex(f => f === 0) === -1;
      const filterOutInactive = this.filter.findIndex(f => f === 1) === -1;

      if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
        return filteredEquipments;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(" ");
      return filteredEquipments.filter(equipment => {
        let found = [];
        const lowerSearchedString =
          (equipment.description ? equipment.description.toLowerCase() : '');
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        })
        if (filterOutActive && equipment.status === 10) found.push(false);
        if (filterOutInactive && equipment.status === 0) found.push(false);
        return found.indexOf(false) === -1;
      })
    },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    this.reloadEquipments();
  },
}
</script>

<style scoped>

</style>
