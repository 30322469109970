<template>
<div>
  <v-btn-toggle v-model="workTypeFilter" class="mb-4">
    <v-btn small :key="0">{{ $t('timer.work_types.0') }}</v-btn>
    <v-btn small :key="1">{{ $t('timer.work_types.1') }}</v-btn>
    <v-btn small :key="2">{{ $t('timer.work_types.2') }}</v-btn>
  </v-btn-toggle>
  <v-data-table
    id="entries-table"
    :items="entries"
    :headers="headers"
    disable-pagination
    hide-default-footer
    locale="fi-FI"
    :no-data-text="$t('reports.no_entries')"
    :mobile-breakpoint="0"
    fixed-header
    height="280"
    :sort-by.sync="sortBy"
    show-select
    v-model="selectedEntriesForSite"
    style="max-width: 100%;"
    :sort-desc.sync="descending"
    class="secondary mb-3"
    v-touch.prevent="() => {}"
  >
    <template v-slot:item.entryStartTS="{item}">
      <td t="s">
        {{ $formatDateNoTime(item.entryStart) }}
        <div class="caption">{{ $formatTimeNoDate(item.entryStart) }}-{{ $formatTimeNoDate(item.entryEnd) }}</div>
      </td>
    </template>
    <template v-slot:item.user="{item}">
      <td>{{ item.user ? item.user.firstName && item.user.lastName ? `${item.user.firstName} ${item.user.lastName}` : item.user.email : '' }}</td>
    </template>
    <template v-slot:item.equipmentString="{item}">
      <td class="caption">
        <template v-for="(equipment, index) in item.equipments">
                        <span :key="equipment.id"
                        ><span>{{ equipment.description }}</span
                        ><span v-if="index < item.equipments.length - 1" style="display: none">,&nbsp;</span><br
                        /></span>
        </template>
      </td>
    </template>
    <template v-slot:item.info="{item}">
      <td class="caption">{{ item.info }}</td>
    </template>
    <template v-slot:item.workType="{item}">
      <td>{{ $t('timer.work_types.' + item.workType) }}</td>
    </template>
  </v-data-table>
</div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "SiteEntries",
  props: ['site'],
  data() {
    return {
      sortBy: 'entryStartTS',
      descending: true,
      workTypeFilter: 0,
      selectedEntries: [],
      headers: [
        {
          text: this.$t('reports.date'),
          value: 'entryStartTS',
        },
        {
          text: this.$t('reports.duration'),
          value: 'duration',
        },
        {
          text: this.$t('reports.employee'),
          value: 'user',
          sortable: false,
        },
        {
          text: this.$t('reports.equipment'),
          value: 'equipmentString',
          sortable: false,
        },
        {
          text: this.$t('reports.info_and_materials'),
          value: 'info',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    getEntryDuration(entry) {
      if (!entry.entryStart) return 0;
      if (!entry.entryEnd) return this.$t('reports.unfinished');

      let diff;
      let start = entry.entryStart;
      let end = entry.entryEnd;

      if (entry.containsLunch) {
        end = moment(end).subtract(0.5, 'hours');
      }

      if (end.isBefore(start)) {
        diff = start.diff(end);
        return '-' + moment.duration(diff).asHours().toFixed(1);
      } else {
        diff = end.diff(start);
        return moment.duration(diff).asHours().toFixed(1);
      }
    },
  },
  computed: {
    selectedEntriesForSite: {
      get() {
        return this.$store.state.selectedEntriesForSite;
      },
      set(value) {
        this.$store.commit('setSelectedEntriesForSite', value);
      }
    },
    entries() {
      const entries = [...this.$store.state.entries.filter(entry => entry.site?.id === this.site.id)];
      entries.map(e => {
        e.duration = this.getEntryDuration(e);
        e.equipmentString = '';
        e.equipments.forEach(eq => {
          e.equipmentString += eq.description + ' ';
        });
      });
      return entries.filter(entry => entry.workType === this.workTypeFilter);
    }
  },
  async mounted() {
    this.selectedEntriesForSite = [];
    await this.$store.dispatch('reloadEntries');
  }
}
</script>

<style scoped>

</style>
