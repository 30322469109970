<template>
  <v-container fluid mt-0 pt-0 class="login" :fill-height="$vuetify.breakpoint.smAndUp">
    <v-row>
      <v-col cols="12" class="text-center" pa-3 v-if="!$isLoggedIn" :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : ''">
        <v-card :style="{display: $vuetify.breakpoint.xsOnly ? '' : 'inline-block', background: $vuetify.breakpoint.xsOnly ? 'none' : '', overflow: $vuetify.breakpoint.xsOnly ? '' : 'hidden'}" elevation="0">
          <v-container fluid pt-0>
            <v-row align="center" justify="center">
              <v-col cols="12" class="text-center primary">
                <v-row>
                  <v-col cols="12">
                    <img style="height: auto;" :style="{width: $vuetify.breakpoint.xsOnly ? '50px' : '75px' }" src="/img/logo.svg" />
                    <h1 class="display-1">{{ $t('title') }}</h1>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text class="login-content">
            <custom-sign-in class="sign-in" v-if="!isSignUp && !isConfirmSignUp && !isForgotPassword && !isRequireNewPassword " v-bind:signInConfig="signInConfig"></custom-sign-in>
            <custom-require-new-password v-if="isRequireNewPassword" v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"></custom-require-new-password>
            <custom-forgot-password class="forgot-password" v-if="isForgotPassword" v-bind:forgotPasswordConfig="forgotPasswordConfig"></custom-forgot-password>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UiEventBus from '../UiEventBus'
  import { components as amplifyComponents, AmplifyEventBus } from 'aws-amplify-vue'
  import CustomSignIn from '../components/Login/SignIn.vue';
  import CustomForgotPassword from '../components/Login/ForgotPassword.vue';
  import CustomRequireNewPassword from '../components/Login/RequireNewPassword.vue';

  export default {
    name: "LoginView",
    data() {
      return {
        signInConfig: {
          header: this.$t('login.login'),
          isSignUpDisplayed: false,
        },
        signUpConfig: {
          defaultCountryCode: '358',
          signUpFields: [
            {
              label: this.$Amplify.I18n.get('Username'),
              key: 'username',
              required: true,
              type: 'string',
              displayOrder: 1
            },
            {
              label: this.$Amplify.I18n.get('Email'),
              key: 'email',
              required: true,
              type: 'string',
              displayOrder: 2
            },
          ],
          hiddenDefaults: ['phone_number','username']
        },
        authConfig: {},
        forgotPasswordConfig: {},
        isSignUp: false,
        isConfirmSignUp: false,
        isForgotPassword: false,
        isRequireNewPassword: false,
      }
    },
    computed: {
      options() {
        const defaults = {
          signInConfig: {},
          signUpConfig: {},
          confirmSignUpConfig: {},
          confirmSignInConfig: {},
          forgotPasswordConfig: {},
          mfaConfig: {},
          requireNewPasswordConfig: {}
        };
        return Object.assign(defaults, this.authConfig)
      }
    },
    methods: {
      async getCurrentUser() {
        try {
          const cognitoUser = await this.$Amplify.Auth.currentAuthenticatedUser();
          UiEventBus.$emit('userLoggedIn', cognitoUser)
        } catch (error) {
          console.log(error)
        }
      },
      logout() {
        // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
        this.$Amplify.Auth.signOut().then(() => {
          // this is client side only logout
          UiEventBus.$emit('userLoggedOut');
        })
      }
    },
    created() {
      AmplifyEventBus.$on('localUser', user => {
        this.user = user;
        this.options.signInConfig.username = this.user.username;
        this.options.confirmSignInConfig.user = this.user;
        this.options.confirmSignUpConfig.username = this.user.username;
        this.options.requireNewPasswordConfig.user = this.user;
      });
      AmplifyEventBus.$on('authState', state => {
        this.isRequireNewPassword = state === 'requireNewPassword';
        this.isSignUp = state === 'signUp';
        this.isConfirmSignUp = state === 'confirmSignUp';
        this.isForgotPassword = state === 'forgotPassword';
        if (state === 'signedIn') {
          this.getCurrentUser();
        }
      });
    },
    mounted() {
      if (this.$isLoggedIn) {
        this.$store.commit('setLoadingOverlay', true);
        this.$router.push({ name: 'timer' });
      }
    },
    components: {
      CustomSignIn,
      CustomForgotPassword,
      CustomRequireNewPassword,
      ...amplifyComponents
    },
  }
</script>
<style lang="scss" scoped>
  .sign-in,
  .sign-up,
  .forgot-password,
  .confirm-sign-up {
    box-shadow: none;
    background: none;
    min-width: auto;
    padding: 0;
  }

  @media screen and (min-width: 600px) {
    .sign-in,
    .sign-up,
    .forgot-password,
    .confirm-sign-up {
      min-width: 460px;
    }
  }
</style>
<style lang="scss">
.login {
  .error {
    margin-top: 16px;
    padding: 16px;
    border-radius: 6px;
  }

  .login-content > div {
    background: transparent;
    box-shadow: none;

    & > div {
      color: white !important;

      & > div > div {
        color: white !important;
      }
    }
  }
}

.login a {
  color: #f90 !important;
}
</style>
