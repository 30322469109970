<template>
  <v-container
    v-if="$isAdmin || $isSuperior"
    :fill-height="loading || customers.length === 0"
    :pa-0="$vuetify.breakpoint.xsOnly"
    class="customers-view"
    fluid
  >
    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$t('customers.search')}...`"
          class="topbar-search"
          clearable
          full-width
          hide-details
          single-line
          style="font-size: 20px"
        >
          <v-icon slot="append" style="cursor: pointer">search</v-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <template v-if="!loading">
        <v-row v-if="customers.length > 0" no-gutters>
          <v-col :style="{ height: $vuetify.breakpoint.xsOnly ? '0' : 'auto' }" cols="12" lg="9" sm="8" xl="10">
            <v-btn
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewCustomerInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('customers.create_new_customer') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" lg="3" sm="4" xl="2">
            <!--<v-select
              v-model="filter"
              :items="filterOptions"
              :placeholder="`${$t('user_management.filtered')} 0 ${$t('user_management.selections')}`"
              item-text="desc"
              item-value="value"
              multiple
              hide-details
              single-line
              solo
              prepend-inner-icon="filter_list"
              background-color="secondary lighten-1"
              :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
            >
              <template v-slot:selection="{ item, index }">
                <template v-if="filter.length !== filterOptions.length && index === 0">
                  <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
                  <span class="grey--text caption">({{ filter.length }} {{ $t('user_management.selections') }})</span>
                </template>
                <template v-else>
                  <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
                </template>
              </template>
            </v-select>-->
          </v-col>
          <v-col cols="12">
            <v-list color="transparent" two-line>
              <v-list-item>
                <v-list-item-avatar
                ><span class="grey--text caption">{{ $t('customers.status') }}</span></v-list-item-avatar
                >
                <v-list-item-content>
                  <v-list-item-title class="grey--text caption">{{ $tc('customers.title', 1) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <template v-for="customer in filteredCustomers">
                <v-list-item :key="customer.id + '_listitem'" @click="openCustomerView(customer)">
                  <v-list-item-avatar>
                    <v-icon :color="getStatusColor(customer.status)">radio_button_checked</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title :class="customer.status === 0 ? 'grey--text' : ''">{{
                        customer.name
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="customer.id + '_divider'"></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>

        <v-row
          v-if="customers.length === 0"
          align="stretch"
          align-content-lg="start"
          align-content-md="start"
          align-content-sm="start"
          align-content-xl="start"
          class="fill-height"
          no-gutters
        >
          <v-col cols="12">
            <v-btn
              :bottom="$vuetify.breakpoint.xsOnly"
              :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
              :fab="$vuetify.breakpoint.xsOnly"
              :fixed="$vuetify.breakpoint.xsOnly"
              :large="$vuetify.breakpoint.smAndUp"
              :right="$vuetify.breakpoint.xsOnly"
              color="primary"
              dark
              @click="openNewCustomerInDialog"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">add</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('customers.create_new_customer') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-row align="center" justify="center" no-gutters>
              <div
                :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '' }"
                class="text-center pl-5 pr-5"
                style="padding-bottom: 150px"
              >
                <v-icon color="secondary lighten-2" size="100">far fa-building</v-icon>
                <div class="headline font-weight-bold mt-3 mb-3">{{ $t('customers.no_customers') }}</div>
                <div v-if="$vuetify.breakpoint.smAndUp" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('customers.no_customers_help') }}.
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly" class="subtitle-1 grey--text text--lighten-2">
                  {{ $t('customers.customers.no_customers_xs') }}.
                </div>
                <v-btn class="mt-5" outlined @click="$reloadApp">
                  <v-icon left>refresh</v-icon>
                  {{ $t('reload') }}
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-fade-transition>

    <customer-dialog ref="customerDialog"/>
  </v-container>
</template>

<script>
import CustomerDialog from '@/components/Customers/CustomerDialog.vue';

export default {
  name: 'CustomerView',
  components: {
    CustomerDialog: CustomerDialog,
  },
  data() {
    return {
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      offsetTop: 0,
      filter: [0],
      filterOptions: [
        {desc: 'Aktiiviset', value: 0},
        {desc: 'Ei aktiiviset', value: 1},
      ],
    };
  },
  methods: {
    createNewCustomer() {
      this.$router.push('customer/new');
    },
    openNewCustomerInDialog() {
      this.$refs.customerDialog.openNew();
    },
    openCustomerView(customer) {
      this.$router.push({name: 'customer', params: {id: customer.id}});
    },
    openCustomerInDialog(customer) {
      this.$refs.customerDialog.openCustomer(customer);
    },
    async reloadCustomers() {
      if (this.$store.state.customers.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await this.$store.dispatch('reloadCustomers');

      this.loading = false;
      this.subtleLoading = false;
    },
    getStatusColor(status) {
      switch (status) {
        case 'DISABLED':
          return 'grey darken-1';
        case 'ACTIVE':
          return 'success';
      }
    },
  },
  computed: {
    customers() {
      return this.$store.state.customers;
    },
    filteredCustomers() {
      const filteredCustomers = this.customers;

      const filterOutActive = this.filter.findIndex(f => f === 0) === -1;
      const filterOutInactive = this.filter.findIndex(f => f === 1) === -1;

      if ((this.searchPhrase == null || this.searchPhrase.length === 0) && !filterOutActive && !filterOutInactive) {
        return filteredCustomers;
      }

      const searchPhraseLower = this.searchPhrase.toLowerCase();
      const searchPhraseArray = searchPhraseLower.split(' ');
      return filteredCustomers.filter(customer => {
        let found = [];
        const lowerSearchedString = customer.name ? customer.name.toLowerCase() : '';
        searchPhraseArray.forEach(phrase => {
          if (lowerSearchedString.indexOf(phrase) !== -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        });
        if (filterOutActive && customer.status === 'ACTIVE') found.push(false);
        if (filterOutInactive && customer.status === 'DISABLED') found.push(false);
        return found.indexOf(false) === -1;
      });
    },
  },
  mounted() {
    this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });
    this.reloadCustomers();
  },
};
</script>

<style scoped></style>
