<template>
  <div v-if="$isAdmin || $isSuperior">
    <v-expansion-panels>
      <template v-for="itemType in itemTypesWithItems">
        <v-expansion-panel v-if="itemType.items.length > 0" :key="itemType.id" class="secondary darken-1">
          <v-expansion-panel-header>
            <v-card-title class="accent--text pa-0 text-break">
              <div>
                <v-badge :value="itemType.siteItemCount"
                         :content="itemType.siteItemCount"
                         left
                         color="red">
                  {{ itemType.shortDescription }}
                </v-badge>
              </div>
            </v-card-title>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2 white--text mb-5">
              {{ itemType.longDescription }}
            </div>
            <template v-if="itemType.shortDescription === 'Aikatyö'">
              <site-entries ref="siteEntries" :site="site" />
            </template>
            <v-list color="transparent" class="mx-n4">
              <template v-for="(item, index) in itemType.items">
                <v-list-item  :key="item.id">
                  <v-list-item-content class="flex-grow-1">
                    <v-list-item-title>
                      <strong>{{ item.shortDescription }}</strong> <span class="grey--text text--lighten-1">({{ item.code }})</span>
                      <v-btn :disabled="$store.state.selectedEntriesForSite.length === 0"
                             class="ml-2"
                             small
                             color="primary"
                             v-if="itemType.shortDescription === 'Aikatyö'"
                             @click="assignSelectedEntriesHours(item)">
                        {{ $t('sites.site_items.add_entries_to_site_item') }}
                      </v-btn>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">
                      {{ item.longDescription }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text class="pl-5">
                    <v-row no-gutters justify="end" class="fill-height">
                      <v-tooltip left color="success" :value="justUpdatedItems.findIndex(i => i === item.id) > -1" transition="scale-transition">
                        <template v-slot:activator="{on, attrs}">
                          <v-text-field hide-details
                                        type="number"
                                        inputmode="number"
                                        :suffix="item.unit"
                                        class="my-2"
                                        :disabled="!!site.billed"
                                        :loading="item.id === savingItemId"
                                        v-model.number="item.siteItemAmount"
                                        @change="upsertItemAmount(item)"
                                        style="width: 105px; max-width: 105px;"
                                        outlined
                                        dense></v-text-field>
                        </template>
                        <span><v-icon left>mdi mdi-check-circle-outline</v-icon> {{ $t('sites.site_items.amount_updated') }}!</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action-text>
                </v-list-item>
                <v-divider v-if="index < itemType.items.length - 1" :key="`${item.id}-divider`"></v-divider>
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>


  </div>
</template>

<script>
import apiCollection from "@/api";
import { uniqBy } from 'lodash';
import SiteEntries from "@/components/SingleSite/SiteEntries";

export default {
  name: "SiteItemsView",
  components: { SiteEntries },
  props: ['site'],
  data() {
    return {
      saveLoading: false,
      savingItemId: null,
      justUpdatedItems: [],
    }
  },
  methods: {
    async upsertItemAmount(item) {
      if (!this.$isSuperior && !this.$isAdmin) return;
      this.savingItemId = item.id;
      try {
        const siteItemIndex = this.siteItems.findIndex(sItem => sItem.item.id === item.id);
        if (siteItemIndex > -1) {
          await apiCollection.updateSiteItem(this.siteItems[siteItemIndex].id, this.site.id, {itemId: item.id, amount: item.siteItemAmount});
        } else {
          await apiCollection.createSiteItem(this.site.id, {itemId: item.id, amount: item.siteItemAmount});
        }
        await this.reloadSiteItems();
        this.justUpdatedItems.push(item.id);
      } catch (error) {
        item.siteItemAmount = item.originalSiteItemAmount;
        this.$handleApiError(error);
      }
      this.savingItemId = null;
      setTimeout(() => {
        const itemIndex = this.justUpdatedItems.findIndex(i => i === item.id);
        if (itemIndex > -1) {
          this.justUpdatedItems.splice(itemIndex, 1);
        }
      }, 1500);
    },
    async reloadSiteItems() {
      await this.$store.dispatch('loadSiteItems', [this.site.id]);
    },
    assignSelectedEntriesHours(item) {
      const entries = this.$store.state.selectedEntriesForSite;
      let amount = 0;
      entries.forEach(entry => {
        amount += Number(entry.duration);
      });
      item.siteItemAmount = amount + Number(item.originalSiteItemAmount);
      this.upsertItemAmount(item);
      this.$store.commit('setSelectedEntriesForSite', []);
    },
  },
  computed: {
    items() {
      return this.$store.state.items.filter(item => item.active);
    },
    itemsWithSiteItems() {
      const items = [];
      this.items.forEach(item => {
        const siteItemIndex = this.siteItems.findIndex(sItem => sItem.item.id === item.id);
        if (siteItemIndex > -1) {
          item.originalSiteItemAmount = this.siteItems[siteItemIndex].amount;
          item.siteItemAmount = this.siteItems[siteItemIndex].amount;
        } else {
          item.originalSiteItemAmount = null;
          item.siteItemAmount = null;
        }
        items.push(item);
      })
      return items;
    },
    itemTypes() {
      return this.$store.state.itemTypes;
    },
    itemTypesWithItems() {
      return this.itemTypes.map(itemType => {
        itemType.items = this.itemsWithSiteItems.filter(item => item.type.id === itemType.id);
        itemType.siteItemCount = uniqBy(this.siteItems.filter(item => item.amount > 0 && item.item?.type?.id === itemType.id), 'item.id')?.length;
        return itemType;
      });
    },
    siteItems() {
      let siteItems = [...this.$store.getters.getSiteItems];
      return siteItems;
    },
  },
  async mounted() {
    if (!this.items || this.items.length === 0) {
      await this.$store.dispatch('reloadItems');
    }
    if (!this.itemTypes || this.itemTypes.length === 0) {
      await this.$store.dispatch('reloadItemTypes');
    }
    await this.reloadSiteItems();
  }
}
</script>

<style scoped>

</style>
