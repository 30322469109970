const messages = {
  // English
  en: {},
  // Finnish
  fi: {
    title: 'Inframykrä',
    login: {
      username: 'Käyttäjätunnus',
      password: 'Salasana',
      login: 'Kirjaudu',
      login_failed: 'Kirjautuminen epäonnistui',
      logout: 'Kirjaudu ulos',
    },
    timer: {
      title: 'Omat kirjaukset',
      create_new_entry: 'Luo uusi työaikakirjaus',
      entry_info: 'Työaikakirjauksen tiedot',
      entry_is_running: 'Käynnissä',
      stop_entry: 'Pysäytä työaikakirjaus',
      create_new_equipment: 'Luo uusi kalusto',
      create_new_site: 'Luo uusi työmaa',
      want_to_continue_latest_entries: 'Haluatko jatkaa edellisestä kirjauksesta?',
      copy_entry: 'Jatka työtä',
      edit_entry: 'Muokkaa',
      work_type: 'Työlaji',
      work_types: {
        0: 'Tuntityö',
        1: 'Urakka',
        2: 'Lisätyö',
      },
      site: 'Työmaa',
      choose_site: 'Valitse työmaa',
      equipment: 'Kalusto',
      choose_equipment: 'Valitse kalusto',
      info: 'Lisätiedot',
      info_placeholder: 'Materiaalit, osoite, poikkeamat jne.',
      user: 'Tekijä',
      choose_user: 'Valitse tekijä',
      no_users_found: 'Ei löytynyt yhtään tekijää.',
      no_sites_found: 'Ei löytynyt yhtään työmaata.',
      start_time: 'Aloitus',
      end_time: 'Lopetus',
      duration: 'Kesto',
      add_images: 'Kuvat',
      distance: 'Kilometrit',
      distance_placeholder: 'Täytä tarvittaessa kilometrit',
      add_time: 'Lisää aikaa',
      subtract_time: 'Vähennä aikaa',
      no_entries: 'Ei löytynyt yhtään kirjausta',
      no_entries_help: 'Lataa sivu uudelleen tästä tai aloita kirjausten luominen vasemmasta yläreunasta',
      no_entries_help_xs: 'Lataa sivu uudelleen tästä tai aloita kirjausten luominen oikeasta alareunasta',
      entry_has_no_site: 'Ei työmaata',
      entry_has_no_customer: 'Ei asiakasta',
      reminder: 'Muista tarkistaa eiliset työaikakirjauksesi ennen klo 09:00',
      contains_lunch: 'Kirjaus sisältää lounastauon',
      entry_deleted_succesfully: 'Kirjaus poistettu onnistuneesti',
      customer: 'Asiakas',
      choose_customer: 'Valitse asiakas',
      no_customers_found: 'Ei löytynyt yhtään asiakasta.',
      site_customer_error: 'Kun asiakas on valittu, niin työmaa on myös pakollinen tieto.',
    },
    user_management: {
      title: 'Käyttäjien hallinta',
      search: 'Hae käyttäjistä',
      roles: 'Roolit',
      user: 'Käyttäjä',
      add_role_to_user: 'Lisää käyttäjälle rooli',
      manage_user: 'Hallitse käyttäjää',
      manage_user_roles: 'Hallitse käyttäjän rooleja',
      disable: 'Poista käyttäjä käytöstä',
      enable: 'Aseta käyttäjä aktiiviseksi',
      you_cannot_disable_yourself: 'Et voi poistaa omaa tunnustasi käytöstä.',
      you_cannot_enable_yourself: 'Et voi asettaa omaa tunnustasi aktiiviseksi.',
      user_state_set_to_enabled: 'Käyttäjä asetettu aktiiviseksi.',
      user_state_set_to_disabled: 'Käyttäjä poistettu käytöstä.',
      user_state_change_failed: 'Käyttäjän tilan vaihtaminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      user_roles_updated: 'Käyttäjän roolit päivitetty.',
      user_roles_update_failed: 'Käyttäjän roolien päivittäminen epäonnistui. Ole hyvä ja yritä uudelleen.',
      create_new_user: 'Luo uusi käyttäjä',
      user_type: 'Tilin tyyppi',
      shared_user: 'Jaettu käyttäjä',
      shared_user_hint: 'Valitse tämä kun kyseessä on laitekohtainen tili, jota käyttää useampi henkilö',
      user_info: 'Käyttäjän tiedot',
      user_roles: 'Käyttäjän roolit',
      machine_id: 'Laitetunniste',
      email: 'Sähköposti',
      password: 'Salasana',
      first_name: 'Etunimi',
      last_name: 'Sukunimi',
      user_created_success: 'Käyttäjä on luotu onnistuneesti.',
      user_creation_failed: 'Käyttäjän luominen epäonnistui. Ole hyvä ja yritä uudelleen.',
      no_username_or_email_given: 'Käyttäjälle luominen epäonnistui, koska käyttäjälle ei annettu sähköpostia tai käyttäjänimeä.',
      user_exists_with_username: 'Antamallasi käyttäjänimellä tai sähköpostiosoitteella on jo käyttäjä olemassa.',
      no_password_for_agent_user_given: 'Et määrittänyt salasanaa jaetulle käyttäjälle.',
      constraint_exception: {
        roleSet: 'Käyttäjälle tulee määritellä ainakin yksi rooli.',
      },
      filter: 'Suodata',
      filtered: 'Suodatettu',
      show_all: 'Näytetään kaikki',
      selections: 'valintaa',
      role: {
        admin: 'Ylläpitäjä',
        basic: 'Peruskäyttäjä',
        agent: 'Laitekäyttäjä',
        superior: 'Esihenkilö',
        tenant_admin: 'Pääkäyttäjä',
      },
      user_settings: 'Asetukset',
      user_info_updated: 'Käyttäjän tiedot päivitetty',
      user_info_update_failed: 'Käyttäjän tietojen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      edit_user_settings: 'Muokkaa käyttäjän tietoja',
    },
    user_profile: {
      title: 'Omat asetukset',
      user_account: 'Käyttäjätili',
      push_notifications: 'Push-ilmoitukset',
      push_notifications_explained:
        'Sallimalla push-ilmoitukset sovellus voi lähettää sinulle ilmoituksia vaikka sovellus olisi pois päältä.',
      allow_notifications: 'Salli ilmoitukset',
      change_password: 'Vaihda salasana',
      old_password: 'Nykyinen salasanasi',
      new_password: 'Uusi salasanasi',
      new_password_again: 'Uusi salasanasi uudelleen',
      new_password_guide: 'Valitse vahva salasana, joka ei ole käytössä muualla.',
      new_passwords_has_to_match: 'Tämän tulee olla sama kuin uusi salasanasi',
      password_must_be_at_least_6_characters: 'Salasanan tulee olla vähintään 6 merkkiä pitkä.',
      errors: {
        new_passwords_do_not_match: 'Uusi salasanasi ja uudelleen kirjoitettu uusi salasanasi eivät vastaa toisiaan.',
        old_password_must_be_at_least_6_characters: 'Antamasi nykyinen salasanasi on väärin.',
        new_password_must_be_at_least_6_characters: 'Uusi salasanasi tulee olla vähintään 6 merkkiä pitkä.',
        NotAuthorizedException: 'Antamasi nykyinen salasana on väärin.',
        InvalidParameterException: 'Antamasi salasanat ovat virheellisiä.',
        InvalidPasswordException:
          'Uusi salasanasi pitää olla vähintään 6 merkkiä pitkä. Salasanan tulee sisältää isoja sekä pieniä kirjaimia, vähintään yksi numero ja vähintään yksi erikoismerkki.',
        LimitExceededException:
          'Olet yrittänyt vaihtaa salasanan liian monta kertaa lyhyessä ajassa. Ole hyvä ja odota hetki ennen kuin yrität uudelleen.',
      },
    },
    site_reports: {
      title: 'Työmaan työt | Työmaiden työt',
      summary_sheet: 'Yhteenveto',
    },
    sites: {
      title: 'Työmaa | Työmaat',
      basic_details: 'Perustiedot',
      "billed": "Laskutettu",
      "billed_hint": "Laskutettu työmaa ei näy oletuksena listauksissa.",
      search: 'Hae työmaista',
      no_sites: 'Ei löytynyt yhtään työmaata',
      no_sites_help: 'Lataa sivu uudelleen tästä tai aloita lisäämällä työmaita vasemmasta yläreunasta',
      no_sites_help_xs: 'Lataa sivu uudelleen tästä tai aloita lisäämällä työmaita oikeasta alareunasta',
      create_new_site: 'Lisää uusi työmaa',
      edit_site: 'Muokkaa työmaata',
      site_visibility: 'Työmaan näkyvyys',
      hidden: 'Piilotettu',
      hidden_hint: 'Piilotettu työmaa näkyy vain ylläpitäjille.',
      site_information: 'Työmaan tiedot',
      introduction_list: 'Perehdytyslistaus',
      site_orientations: 'Työmaan perehdytykset',
      no_introductions_to_list: 'Työmaalla ei ole yhtäkään perehdytystä.',
      name: 'Työmaan nimi / numero',
      address: 'Työmaan osoite',
      status: 'Tila',
      images: 'Kuvat',
      files: 'Tiedostot',
      start_date: 'Aloituspäivä',
      end_date: 'Lopetuspäivä',
      duration_dates: 'Aloitus- ja lopetuspäivä',
      images_from_entries: 'Tuntikirjauksista',
      site_saved_succesfully: 'Työmaan tiedot tallennettu.',
      site_items: {
        title: 'Työmaan työt',
        amount_updated: 'Määrä päivitetty',
        add_entries_to_site_item: 'Lisää valitut',
      },
      "introduction": {
        "accept_and_save": "Merkitse suoritetuksi",
        "introduction_accepted": "Olet merkinnyt perehdytyksen suoritetuksi. Perehdytys odottaa vielä esihenkilön kuittausta.",
        "introduction_accepted_and_checked": "Perehdytys on tarkistettu ja hyväksytty.",
        "licence": {
          "ea1": "EA1",
          "rataturva": "Rataturva",
          "tieturva1": "Tieturva 1",
          "tieturva2": "Tieturva 2",
          "tulityokortti": "Tulityökortti",
          "tyoturvallisuuskortti": "Työturvallisuuskortti"
        },
        "licences": "Pätevyydet",
        "proxy_title": "Toisen henkilön puolesta täyttö",
        "proxy_target_label": "Perehdytettävä",
        "save_unfinished": "Tallenna keskeneräisenä",
        "step": {
          "koneet": "Koneet, laitteet ja käyttöturvallisuus",
          "liikenneturvallisuus": "Liikenneturvallisuus",
          "ontutustunutperehdytysaineistoon": "Olen lukenut ja ymmärtänyt annetut ohjeistukset, ja sitoudun toimimaan niiden mukaisesti",
          "paloturvallisuus": "Paloturvallisuus ml. sammutuskalusto ja tupakointi ",
          "telineet": "Telineet, suojarakenteet, kulkutiet, tuennat",
          "tilaajanturvallisuusaineisto": "Tilaajan turvallisuusaineisto ml. työmaaopas ja käytettävä mittaristo esim. MVR",
          "toteutusorganisaatio": "Toteutusorganisaatio",
          "tyomaakierros": "Työmaakierros",
          "tyomaanjarjestysjasiisteys": "Työmaan järjestys ja siisteys ml. lajittelu ja ongelmajätteet ",
          "tyomaankunnossapitotarkastukset": "Työmaan kunnossapito- ja käyttöönotto- ja viikkotarkastukset ",
          "tyomaanvaaratekijat": "Työmaan vaaratekijät, terveydelle vaaralliset aineet, käyttöturvallisuus",
          "tyomaatilat": "Työmaatilat ml. lastaus, varastointi ja pysäköinti",
          "tyosuojeluorganisaatio": "Työsuojeluorganisaatio, työmaan turvallisuusmääräykset, henkilösuojaimet ja kulkuluvat",
          "tyoterveyshuoltojaensiapu": "Työterveyshuolto ja ensiapu ml. toimintaohjeet onnettomuus- ja tapaturmatilanteissa",
          "yleistiedot": "Työmaan yleistiedot, kohteen esittely"
        },
        "steps": "Työmaaperehdytyksen kohdat",
        "steps_help": "Sinun tulee rastittaa kaikki alla olevat kohdat, että olet saanut niihin opastuksen. Kun kaikki kohdat on suoritettu, voit merkitä perehdytyksen suoritetuksi.",
        "title": "Perehdytys",
        "validation_error_message": "Sinun tulee rastittaa kaikki kohdat suoritetuiksi, jotta voit merkitä perehdytyksen suoritetuksi.",
        "what_accepting_means": "Painamalla \"Merkitse suoritetuksi\"-painiketta hyväksyt, että sinulle on pidetty perehdytys ja olet ymmärtänyt kaikki perehdytyksen kohdat. Et voi enää sen jälkeen muokata tätä lomaketta tämän työmaan kohdalla.",
        accepted: "Suoritettu",
        checked: 'Hyväksytty',
        checker: 'Hyväksyjä',
        not_checked: 'Ei hyväksytty'
      },
    },
    equipments: {
      title: 'Kalusto | Kalustot',
      basic_details: 'Perustiedot',
      equipment_information: 'Kaluston tiedot',
      search: 'Hae kalustoista',
      no_equipments: 'Ei löytynyt yhtään kalustoa',
      no_equipments_help: 'Lataa sivu uudelleen tästä tai aloita lisäämällä uusia kalustoja vasemmasta yläreunasta',
      no_equipments_help_xs: 'Lataa sivu uudelleen tästä tai aloita lisäämällä uusia kalustoja oikeasta alareunasta',
      create_new_equipment: 'Lisää uusi kalusto',
      edit_equipment: 'Muokkaa kalustoa',
      description: 'Kaluston kuvaus',
      status: 'Tila',
      "equipment_checks": {
        new_equipment_check: 'Uusi kalustotarkastus',
        edit: 'Muokkaa kalustotarkastusta',
        checked_by: 'Tarkastanut',
        approved_at: 'Hyväksytty',
        checks: 'Tarkastukset',
        status: 'Tila',
        no_equipment_checks: 'Ei tarkastuksia.',
        "equipment_check_saved": "Kaluston tarkastus tallennettu",
        "additional_info": "Muuta huomioitavaa",
        "approved_by": "Hyväksynyt",
        "as_fixed": "korjatuksi",
        "checked_at": "Tarkastettu",
        "checked_every_day": "Kone tarkastettu päivittäin",
        "close": "Sulje",
        "create": "Luo tarkastus",
        "create_check_for_equipment": "Luo tarkastus kalustolle",
        "equipment": "Kaluston tiedot",
        "equipment_license_number": "Rekisterinumero",
        "equipment_serial_number": "Valmistusnumero",
        "equipment_year": "Valmistusvuosi",
        "fault": "Puute/vika",
        "fixed": "Korjattu",
        "hours": "tuntia",
        "make": "Merkki ja malli",
        "mark": "Merkitse",
        "mark_fixed": "Merkitse korjatuksi",
        "mark_inspected": "Hyväksy tarkastus",
        "not_checked": "Ei tarkastettu",
        "ok": "Kunnossa",
        "open": "Katso",
        "operating_hours": "Käyttötuntimäärä",
        "save": "Tallenna ja kuittaa tarkastus",
        "site_equipment": "Työmaan kalustot",
        "step": {
          "ajojahallintalaitteet": "Ajo- ja hallintalaitteet, sähkölaitteet ja jarrut",
          "akselistotjavaihteistot": "Akselistot ja vaihteistot",
          "alkusammutinensiapulaukku": "Alkusammutin, ensiapulaukku, puhelin",
          "hydrauliikka": "Hydrauliikka, letkut, sylinterit",
          "koneenhavaittavuus": "Koneen havaittavuus (varoituslaitteet)",
          "koneenhuoltokayttoturvallisuus": "Koneen huolto- ja käyttö- ja turvallisuusohjeet",
          "koneenkuljettajaperehdytystyomaanolosuhteisiin": "Koneen kuljettaja perehdytetty työmaan olosuhteisiin",
          "koneenlisalaitteidenturvallisuus": "Koneen lisälaitteiden turvallisuus ja havaittavuus",
          "koneenmerkinnatjakilvet": "Koneen merkinnät ja kilvet (CE-merkintä)",
          "laitekiinnitykset": "Laitekiinnitykset, huolto- ja kuljetustuet",
          "nostokoukut": "Nostokoukut, nostovälineet",
          "suojauksetjasuojalaitteet": "Suojukset ja suojalaitteet",
          "turvakatkaisijat": "Turvakatkaisijat, moottorin pysäytinlaite",
          "tyokonekunnossa": "Työkone on kunnossa",
          "tyokonevaatimustenmukainen": "Työkone on vaatimustenmukainen",
          "valaisimetjasuuntavalaisimet": "Valaisimet ja suuntavalaisimet"
        },
        "steps": "Tarkastuskohdat",
        "steps_help": "Voit jättää kohdan tyhjäksi, jos se ei ole tarpeellinen kyseiselle kalustolle.",
        "supervisor_user": "Tarkastuksen hyväksynyt vastaava työnjohtaja",
        "title": "Tarkastukset",
        "user_that_did_the_equipment_check": "Tarkastuksen tehnyt koneen kuljettaja",
        "waiting_for_inspection": "Odottaa esihenkilöä",
        "what_signing_means": "Tallentamalla kuittaat, että olet suorittanut tarkastuksen kohdat kalustolle. Et voi muokata tarkastuslomaketta enää tallennuksen jälkeen."
      },
    },
    reports: {
      title: 'Työaikakirjaukset',
      date: 'Pvm',
      site: 'Työmaa',
      employee: 'Tekijä',
      equipment: 'Kalusto',
      include_billed: 'Näytä myös laskutetut',
      info_and_materials: 'Lisätiedot/materiaalit',
      start_end: 'Alkoi - Loppui',
      duration: 'Kesto (h)',
      distance: 'Kilometrit (km)',
      could_not_find_user: 'Tekijää ei löytynyt',
      selections: 'valintaa',
      unfinished: 'Kesken',
      this_week: 'Tämä viikko',
      last_week: 'Viime viikko',
      this_month: 'Tämä kuukausi',
      last_month: 'Viime kuukausi',
      this_year: 'Tämä vuosi',
      last_year: 'Viime vuosi',
      download_excel: 'Lataa raportti',
      create_new_entry: 'Luo uusi työaikakirjaus',
      no_entries: 'Ei yhtään kirjausta löytynyt.',
      is_invoiced: 'Onko laskutettu',
      invoiced: 'Laskutettu',
      not_invoiced: 'Ei laskutettu',
      update_as_invoiced: 'Merkitse laskutetuksi',
      update_as_non_invoiced: 'Poista laskutusmerkintä',
      customer: 'Asiakas',
    },
    customer: {
      basic_info: 'Perustiedot',
      customer_information: 'Asiakkaan tiedot',
      export_pricing: 'Lataa excelinä',
      import_pricing: 'Tuo hinnat excelistä',
      import_successful: 'Hintojen tuonti onnistui',
      pricing: 'Hinnoittelu',
    },
    customers: {
      title: 'Asiakas | Asiakkaat',
      search: 'Hae asiakkaista',
      create_new_customer: 'Lisää uusi asiakas',
      status: 'Tila',
      no_content_title: 'Tuntematon asiakas',
      no_customers: 'Ei löytynyt yhtään asiakasta',
      no_customers_help: 'Lataa sivu uudelleen tästä tai aloita lisäämällä uusia asiakkaita vasemmasta yläreunasta',
      no_customers_help_xs: 'Lataa sivu uudelleen tästä tai aloita lisäämällä uusia asiakkaita oikeasta alareunasta',
      name: 'Yrityksen nimi',
      description: 'Asiakkaan kuvaus',
      edit_customer: 'Muokkaa asiakasta',
      remove_customer_price: 'Poista hinnoittelu',
      return_to_listing: 'Palaa asiakaslistaukseen',
    },
    items_view: {
      search_placeholder: 'Etsi nimiketyypeistä | Etsi nimikkeistä'
    },
    items: {
      title: 'Nimike | Nimikkeet',
      no_items: 'Ei löytynyt yhtään nimikettä',
      search: 'Hae nimikkeistä',
      create_new: 'Lisää uusi nimike',
      new_item: 'Uusi nimike',
      edit_item: 'Muokkaa nimikettä',
      no_items_of_type: 'Ei nimikkeitä joiden tyyppi on',
      inactive_items_title: 'Poistetut nimikkeet',
      item: 'Nimike',
      amount: 'Määrä',
      code: 'Koodi',
      unit: 'Yksikkö',
      price: 'Hinta',
      priceTotal: 'Yhteensä',
      price_for_customer: 'Asiakkaan hinta',
      type: 'Nimiketyyppi',
      short_desc: 'Lyhyt kuvaus',
      long_desc: 'Pitkä kuvaus',
      item_type: 'Nimiketyyppi',
      set_active: 'Palauta käyttöön',
      set_inactive: 'Poista käytöstä'
    },
    item_types: {
      title: 'Nimiketyyppi | Nimiketyypit',
      active_types_title: 'Aktiiviset',
      item_type_inactive: 'Ei aktiivinen',
      inactive_types_title: 'Käytöstä poistetut',
      delete_item_type: 'Poista käytöstä',
      restore_item_type: 'Palauta käyttöön',
      no_item_types: 'Ei löytynyt yhtään nimiketyyppiä',
      edit_item_type: 'Muokkaa nimiketyyppiä',
      new_item_type: 'Uusi nimiketyyppi',
      short_desc_label: 'Tyypin nimi',
      short_desc_placeholder: '',
      long_desc_label: 'Valinnainen kuvaus',
      long_desc_placeholder: '',
    },
    validation: {
      field_is_required: 'Kenttä on pakollinen',
      email_is_required: 'Kentän tulee olla sähköpostiosoite',
      spaces_are_not_allowed: 'Välilyönnit eivät ole sallittuja',
      check_all_required_fields: 'Tarkista, että olet täyttänyt kaikki pakolliset kentät',
      price_rule: 'Vain numerot ja yksi piste sallittu.'
    },
    errors: {
      error_loading_site_items: 'Työmaan nimikkeiden hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_site_item: 'Työmaan nimikkeen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_creating_site_item: 'Työmaan nimikkeen luominen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_entries: 'Kirjausten hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_entry: 'Kirjauksen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_sites: 'Työmaiden hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_site: 'Työmaan päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen',
      error_loading_equipment: 'Kaluston hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_equipment: 'Kaluston päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_customers: 'Asiakkaiden hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_customer: 'Asiakkaan päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_users: 'Käyttäjien hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_deleting_entry: 'Kirjauksen poistaminen ei onnistunut. Ole hyvä ja yritä uudelleen',
      error_undo_deleting_entry: '',
      error_mass_updating_invoiced_state: 'Laskutettu-tietojen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_item_types: 'Nimikkeiden hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_item_type: 'Nimikkeen päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_reordering_item_types: 'Nimiketyyppien uudelleenjärjestäminen ei onnistunut.',
      error_loading_items: 'Nimiketyyppien hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_item: 'Nimiketyypin päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_loading_prices: 'Asiakaskohtaisen hinnoittelun hakeminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_updating_customer_price: 'Asiakaskohtaisen hinnan päivittäminen ei onnistunut. Ole hyvä ja yritä uudelleen.',
      error_deleting_customer_price: 'Asiakaskohtaisen hinnan poistaminen ei onnistunut. Ole hyvä ja yritä uudelleen',
    },
    cancel: 'Peruuta',
    save: 'Tallenna',
    delete: 'Poista',
    delete_entry: 'Poista kirjaus',
    undo: 'Kumoa',
    search: 'Hae',
    clear_search: 'Tyhjennä haku',
    try_again: 'Yritä uudelleen',
    update_available: 'Päivitys saatavilla',
    install_update: 'Asenna',
    ok: 'Hyväksy',
    start: 'Aloita',
    set_inactive: 'Poista käytöstä',
    set_active: 'Aseta aktiiviseksi',
    show_inactive: 'Näytä käytöstä poistetut',
    reload: 'Lataa uudelleen',
    generic_error: 'Tapahtui virhe.',
    sort_by: 'Järjestä',
    rows_per_page: 'Rivejä sivulla',
    loading: 'Ladataan...',
    all: 'Kaikki'
  },
  // German
  de: {},
  // Swedish
  sv: {},
};

export default messages;
