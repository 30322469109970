<template>
  <v-card color="secondary darken-1">
    <v-card-title class="accent--text">
      {{ $t('equipments.equipment_checks.checks') }}
    </v-card-title>
    <v-data-table id="equipment-checks-table"
                  :footer-props="{itemsPerPageText: $t('rows_per_page'), itemsPerPageAllText: $t('all'),  itemsPerPageOptions: [10, 25, 50, -1]}"
                  :header-props="{sortByText: $t('sort_by')}"
                  :headers="headers"
                  :items="equipmentChecksForEquipment"
                  :loading="loading"
                  :loading-text="$t('loading')"
                  :no-data-text="$t('equipments.equipment_checks.no_equipment_checks')"
                  class="transparent"
                  locale="fi-FI">
      <template v-slot:item.workerCheckDate="{ item }">
        {{ item.workerCheckDate ? $formatWeekAndYear(item.workerCheckDate * 1000) : '' }}
      </template>
      <template v-slot:item.inspectedDate="{ item }">
        {{ item.inspectedDate ? $formatWeekAndYear(item.inspectedDate * 1000) : '' }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip v-if="isEquipmentCheckWaitingForInspector(item)" class="ma-1" color="info" x-small>
          {{ $t('equipments.equipment_checks.waiting_for_inspection') }}
        </v-chip>
        <v-chip v-if="isEquipmentCheckOk(item)" class="ma-1" color="success" x-small>
          {{ $t('equipments.equipment_checks.ok') }}
        </v-chip>
        <v-chip v-if="equipmentCheckHasUncheckedFaults(item)" class="ma-1" color="error" x-small>
          {{ $t('equipments.equipment_checks.fault') }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn :to="{name: 'equipmentcheck', params: {equipmentId: equipment.id, id: item.id}}" outlined small>
          {{ $t('equipments.equipment_checks.open') }}
        </v-btn>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :bottom="$vuetify.breakpoint.xsOnly"
        :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
        :fab="$vuetify.breakpoint.xsOnly"
        :fixed="$vuetify.breakpoint.xsOnly"
        :right="$vuetify.breakpoint.xsOnly"
        :to="{name: 'equipmentcheck', params: {equipmentId: equipment.id}}"
        color="success"
        dark
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi mdi-plus</v-icon>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('equipments.equipment_checks.create') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import equipmentChecksApi from "@/api/equipmentcheck";

export default {
  name: "EquipmentChecks",
  props: ['equipment'],
  data() {
    return {
      saving: false,
      loading: true,
      equipmentChecksForEquipment: [],
      equipmentCheckSteps: [
        'koneenhavaittavuus', 'valaisimetjasuuntavalaisimet', 'hydrauliikka', 'akselistotjavaihteistot', 'nostokoukut',
        'laitekiinnitykset', 'ajojahallintalaitteet', 'turvakatkaisijat', 'suojauksetjasuojalaitteet', 'alkusammutinensiapulaukku',
        'koneenhuoltokayttoturvallisuus', 'koneenmerkinnatjakilvet', 'koneenlisalaitteidenturvallisuus',
        'koneenkuljettajaperehdytystyomaanolosuhteisiin', 'tyokonekunnossa', 'tyokonevaatimustenmukainen'
      ],
      headers: [
        {
          text: this.$t('equipments.equipment_checks.checked_at'),
          value: 'workerCheckDate',
        },
        {
          text: this.$t('equipments.equipment_checks.checked_by'),
          value: 'workerUser.fullName',
          sortable: false,
        },
        {
          text: this.$t('equipments.equipment_checks.approved_at'),
          value: 'inspectedDate',
          sortable: false,
        },
        {
          text: this.$t('equipments.equipment_checks.approved_by'),
          value: 'inspectorUser.fullName',
          sortable: false,
        },
        {
          text: this.$t('equipments.equipment_checks.status'),
          value: 'status',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    validateFault(step) {
      if (this.equipmentCheck[step + 'Fault'] === null || this.equipmentCheck[step + 'Fault'].length === 0) {
        this.equipmentCheck[step] = null;
      }
    },
    async getEquipmentChecks() {
      try {
        const equipmentChecks = await equipmentChecksApi.getEquipmentChecksByEquipment(this.equipment.id);
        if (equipmentChecks) {
          equipmentChecks.forEach(check => {
            if (check.workerUser) {
              check.workerUser.fullName = check.workerUser.firstName && check.workerUser.lastName ? `${check.workerUser.firstName} ${check.workerUser.lastName}` : check.workerUser.email;
            }
            if (check.inspectorUser) {
              check.inspectorUser.fullName = check.inspectorUser.firstName && check.inspectorUser.lastName ? `${check.inspectorUser.firstName} ${check.inspectorUser.lastName}` : check.inspectorUser.email;
            }
          })
          this.equipmentChecksForEquipment = equipmentChecks.sort((a, b) => b.workerCheckDate - a.workerCheckDate);
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    isEquipmentCheckWaitingForInspector(equipmentCheck) {
      if (equipmentCheck) {
        return !equipmentCheck.inspectorUserId;
      }
      return false;
    },
    isEquipmentCheckOk(equipmentCheck) {
      if (equipmentCheck) {
        if (!this.equipmentCheckHasUncheckedFaults(equipmentCheck)) {
          return !!equipmentCheck.inspectorUserId;
        }
      }
      return false;
    },
    equipmentCheckHasUncheckedFaults(equipmentCheck) {
      if (equipmentCheck) {
        const uncheckedFaults = [];
        this.equipmentCheckSteps.forEach(step => {
          uncheckedFaults.push(equipmentCheck[step] === 'false' && equipmentCheck[step + 'Fixed'] === null);
        });
        return uncheckedFaults.includes(true);
      }
      return false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.getEquipmentChecks();
    this.loading = false;
  }
}
</script>

<style lang="scss">
.site-equipment-check-form {
  .selected {
    font-weight: 600;
  }

  .site-equipment-check-steps {
    .theme--light.v-input input {
      &::placeholder {
        color: rgba(0, 0, 0, 0.88);
      }

      &:focus {
        font-weight: 600;
      }

      &:focus::placeholder {
        color: rgba(0, 0, 0, 0.68);
      }
    }
  }

  .v-input--is-disabled input {
    color: rgba(0, 0, 0, 0.68);
  }

  .accept-step-input {

    * {
      cursor: pointer !important;
    }

    &.v-input--is-disabled * {
      cursor: default !important;
    }

    .fix-button, .fix-button * {
      pointer-events: all !important;
      cursor: pointer !important;
      height: 30px;
      padding-top: 2px;
      margin-top: -4px;
    }
  }
}
</style>
