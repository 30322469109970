<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
    :hide-overlay="$vuetify.breakpoint.xsOnly"
    @keydown.esc="dialog = false"
    @keydown.enter="saveCustomer"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
  >
    <v-card color="secondary darken-1">
      <v-toolbar dark color="secondary">
        <v-toolbar-title>{{ currentCustomer.id ? $t('customers.edit_customer') : $t('customers.create_new_customer') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <template v-if="$isAdmin">
          <v-btn
            color="warning"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentCustomer.id && currentCustomer.status === 'ACTIVE'"
            @click="setCustomerInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            class="mt-5 mb-5"
            outlined
            v-if="$vuetify.breakpoint.xsOnly && currentCustomer.id && currentCustomer.status === 'DISABLED'"
            @click="setCustomerActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>

        <v-form ref="form">
          <v-text-field
            class="mt-5 customername"
            :label="$t('customers.name')"
            :rules="[$rules.required]"
            validate-on-blur
            type="text"
            v-model="currentCustomer.name"
          ></v-text-field>
        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ $t('validation.check_all_required_fields') }}.</v-alert>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <!--<template v-if="$isAdmin">
          <v-btn
            color="warning"
            v-if="$vuetify.breakpoint.smAndUp && currentCustomer.id && currentCustomer.status === 'ACTIVE'"
            @click="setCustomerInactive"
            :disabled="saving"
          >
            <span>{{ $t('set_inactive') }}</span>
          </v-btn>
          <v-btn
            color="success"
            v-if="$vuetify.breakpoint.smAndUp && currentCustomer.id && currentCustomer.status === 'DISABLED'"
            @click="setCustomerActive"
            :disabled="saving"
          >
            <span>{{ $t('set_active') }}</span>
          </v-btn>
        </template>-->
        <v-spacer></v-spacer>
        <v-btn
          color="default"
          @click="dialog = false"
          :disabled="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :left="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          @click="saveCustomer(false)"
          :loading="saving"
          :fab="$vuetify.breakpoint.xsOnly"
          :fixed="$vuetify.breakpoint.xsOnly"
          :bottom="$vuetify.breakpoint.xsOnly"
          :right="$vuetify.breakpoint.xsOnly"
        >
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CustomerDialog',
  data() {
    return {
      dialog: false,
      saving: false,
      showAlert: false,
      currentCustomer: {},
      customerTemplate: {
        name: '',
        status: 'ACTIVE',
      },
    };
  },
  methods: {
    setCustomerInactive() {
      this.currentCustomer.status = 'DISABLED';
      this.saveCustomer(true);
    },
    setCustomerActive() {
      this.currentCustomer.status = 'ACTIVE';
      this.saveCustomer(true);
    },
    async saveCustomer(statusChange) {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        return;
      }

      this.saving = true;
      const saveSuccess = await this.$store.dispatch('upsertCustomer', this.currentCustomer);
      if (saveSuccess) {
        this.dialog = false;
      } else if (statusChange) {
        this.currentCustomer.status = this.currentCustomer.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED';
      }
      this.saving = false;
    },
    openCustomer(customer) {
      this.dialog = true;
      this.currentCustomer = Object.assign({}, customer);
    },
    openNew() {
      this.dialog = true;
      this.currentCustomer = Object.assign({}, this.customerTemplate);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.showAlert = false;
        this.$refs.form.reset();
        this.$emit('on-dismiss');
      }
    },
  },
};
</script>

<style scoped></style>
