<template>
  <v-container :fill-height="loading"
               class="items-view" fluid>
    <v-app-bar
      :color="$vuetify.breakpoint.xsOnly ? 'secondary' : 'secondary'"
      :elevate-on-scroll="$vuetify.breakpoint.smAndDown"
      app
      dark
      fixed
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <hb-main-menu-button/>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="tab" background-color="transparent" color="accent" grow>
          <v-tabs-slider color="accent"/>
          <v-tab>
            {{ $tc('items.title', 2) }}
          </v-tab>
          <v-tab>
            {{ $tc('item_types.title', 2) }}
          </v-tab>
        </v-tabs>
      </template>

      <v-toolbar-title style="margin-left: 0; width: 100%">
        <v-text-field
          v-model="searchPhrase"
          :placeholder="`${$tc('items_view.search_placeholder',tab)}...`"
          class="topbar-search"
          full-width
          hide-details
          single-line
          clearable
          style="font-size: 20px"
        >
          <v-icon style="cursor: pointer" slot="append">search</v-icon>
        </v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <hb-loading-indicator v-if="loading" align-middle/>

    <v-fade-transition>
      <div v-if="!loading" class="fill-height" style="min-width: 100%">
          <v-tabs-items v-model="tab" class="transparent">
            <!-- NIMIKKEET -->
            <v-tab-item>
              <v-container class="fill-height pa-0" fluid>
                <v-row>
                  <v-col cols="12">
                    <v-btn :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.xsOnly }"
                           :large="$vuetify.breakpoint.smAndUp"
                           :block="$vuetify.breakpoint.xsOnly" color="primary" @click="addItem">
                      <v-icon left>mdi-plus</v-icon>
                      <span>{{ $t('items.new_item') }}</span>
                    </v-btn>
                    <v-btn :class="{ 'ma-0 mr-5': $vuetify.breakpoint.smAndUp, 'mb-3': $vuetify.breakpoint.xsOnly }"
                           :large="$vuetify.breakpoint.smAndUp"
                           :block="$vuetify.breakpoint.xsOnly"
                           v-if="searchPhrase !== ''"
                           color="info" @click="searchPhrase = ''">
                      <v-icon class="no-gradient" left>mdi-close</v-icon>
                      <span>{{ $t('clear_search') }}</span>
                    </v-btn>
                  </v-col>
                  <template v-if="activeTypes && activeTypes.length">
                    <template v-for="type in activeTypes">
                      <v-col  :key="type.id" cols="12" v-if="activeItems.filter(item => item.type.id === type.id).length > 0">
                        <v-row>
                          <items-view-item-type
                            :item-type="type"
                            :items="activeItems.filter(item => item.type.id === type.id)"
                            :no-content="$t('items.no_items_of_type') + ' ' + type.shortDescription"
                            :search-phrase="searchPhrase"
                            @open-item="openItem"
                            @open-new="addItem"
                          />
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                  <template v-if="removedTypes && removedTypes.length">
                    <template v-for="type in removedTypes">
                      <v-col :key="type.id" class="mt-15" cols="12" v-if="activeItems.filter(item => item.type.id === type.id).length > 0">
                        <v-row>
                          <items-view-item-type
                            :item-type="type"
                            :items="activeItems.filter(item => item.type.id === type.id)"
                            :no-content="$t('items.no_items_of_type') + ' ' + type.shortDescription"
                            :search-phrase="searchPhrase"
                            @open-item="openItem"
                            @open-new="addItem"
                          />
                        </v-row>
                      </v-col>
                    </template>
                  </template>
                  <template v-if="inactiveItems && inactiveItems.length" class="secondary">
                    <v-col class="mt-15" cols="12">
                      <v-row>
                        <items-view-item-type
                          :items="inactiveItems"
                          :search-phrase="searchPhrase"
                          display-inactive-items
                          @open-item="openItem"
                          @open-new="addItem"
                        />
                      </v-row>
                    </v-col>
                  </template>
                  <confirm-dialog ref="openItemRemoveDialog"/>
                  <add-or-edit-item-dialog
                    ref="addOrEditItemDialog"
                    :items="allItems"
                    :types="itemTypes"
                  />
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- NIMIKETYYPIT -->
            <v-tab-item>
              <item-types ref="itemTypesComponent" :searchPhrase="searchPhrase" align-self="center"
                          @clear-search="searchPhrase = ''"></item-types>
            </v-tab-item>
          </v-tabs-items>
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import ItemsViewItemType from "@/components/Items/ItemsViewItemType.vue";
import AddOrEditItemDialog from "@/components/Items/AddOrEditItemDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import ItemTypes from "@/components/Items/ItemTypes.vue";

export default {
  name: "ItemsView",
  components: {ConfirmDialog, AddOrEditItemDialog, ItemsViewItemType, ItemTypes},
  data() {
    return {
      tab: null,
      loading: false,
      subtleLoading: false,
      searchPhrase: '',
      timeOut: 2000,
      itemDeletedSnackbar: false,
      itemCreatedSnackbar: false,
      itemUpdatedSnackbar: false,
      filter: [] /*types.map((t, i) => i),*/,
      // filterOptions: this.itemTypes.map((t, i) => {
      //   return {desc: this.$tc('items.' + t, 2), value: i};
      // }),
    }
  },
  watch: {},
  computed: {
    allItems() {
      return this.$store.state.items;
    },
    activeItems() {
      return this.allItems.filter(it => it.active);
    },
    inactiveItems() {
      return this.allItems.filter(it => !it.active);
    },
    itemTypes() {
      return [...this.$store.state.itemTypes].sort((a, b) => {
        return a.ordernum - b.ordernum;
      });
    },
    activeTypes() {
      return this.itemTypes.filter(type => type.active);
    },
    removedTypes() {
      return this.itemTypes.filter(type => !type.active);
    },
  },
  methods: {
    openItemRemoveDialog(item) {
      this.$refs.openItemRemoveDialog
        .open(this.$t('items.delete_item_dialog.title'), this.$t('items.delete_item_dialog.description'), {color: 'error'})
        .then(confirm => {
          this.removeItem(confirm, item);
        });
    },
    openItem(itemId) {
      this.$refs.addOrEditItemDialog.open(itemId, false);
    },
    addItem(type = null) {
      const itemId = null;
      const newItem = true;
      this.$refs.addOrEditItemDialog.open(itemId, newItem, type);
    },
    removeItem(confirm, item) {
      if (!confirm) {
        return;
      }
      console.info(item);
      //TODO
    },
    async reloadItems() {
      this.subtleLoading = true;
      await this.$store.dispatch('reloadItems');
      this.subtleLoading = false;
    },
    async reloadItemTypes() {
      this.subtleLoading = true;
      await this.$store.dispatch('reloadItemTypes');
      this.subtleLoading = false;
    },
    addItemType() {
      this.$refs.itemTypesComponent.openDialog();
    }
  },
  async mounted() {
    await this.reloadItemTypes();
    await this.reloadItems();
  }
}
</script>

<style scoped>

</style>
