<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '600'"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card>
      <v-toolbar dark color="#303030">
        <v-toolbar-title>{{ $t('user_profile.change_password') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>

        <v-alert :value="true" outlined type="info" class="mt-5 mb-5">{{ $t('user_profile.new_password_guide') }}</v-alert>

        <v-form ref="form">

          <v-text-field
            class="password"
            :append-icon="showOldPassword ? 'visibility' : 'visibility_off'"
            :type="showOldPassword ? 'text' : 'password'"
            :label="$t('user_profile.old_password')"
            v-model="oldPassword"
            :rules="[$rules.required, $rules.mustBeAtLeast6Characters]"
            validate-on-blur
            autocomplete="new-password"
            @click:append="showOldPassword = !showOldPassword"
          ></v-text-field>

          <v-text-field
            class="password"
            :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
            :type="showNewPassword ? 'text' : 'password'"
            :label="$t('user_profile.new_password')"
            v-model="newPassword"
            :rules="[$rules.required, $rules.mustBeAtLeast6Characters]"
            validate-on-blur
            autocomplete="new-password"
            @click:append="showNewPassword = !showNewPassword"
          ></v-text-field>

          <v-text-field
            class="password"
            :append-icon="showNewPassword2 ? 'visibility' : 'visibility_off'"
            :type="showNewPassword2 ? 'text' : 'password'"
            :label="$t('user_profile.new_password_again')"
            v-model="newPassword2"
            :rules="[$rules.required, $rules.passwordHasToMatch(newPassword2, newPassword)]"
            validate-on-blur
            autocomplete="new-password"
            @click:append="showNewPassword2 = !showNewPassword2"
          ></v-text-field>

        </v-form>

        <v-alert :value="showAlert" outlined class="mt-4" type="error">{{ alertText }}</v-alert>

      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default"
               @click="dialog = false"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :left="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="primary"
               @click="savePassword"
               :loading="saving"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">check</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('save') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Auth } from 'aws-amplify';

  export default {
    name: "ChangePasswordDialog",
    props: {
      user: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        dialog: false,
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        showOldPassword: false,
        showNewPassword: false,
        showNewPassword2: false,
        showAlert: false,
        alertText: '',
        saving: false,
      }
    },
    methods: {
      open() {
        this.dialog = true;
      },
      savePassword() {
        this.saving = true;
        this.showAlert = false;
        this.alertText = '';

        if (this.newPassword !== this.newPassword2) {
          this.showAlert = true;
          this.alertText = this.$t('user_profile.errors.new_passwords_do_not_match');
          this.saving = false;
          return;
        }
        if (this.oldPassword.length < 6) {
          this.showAlert = true;
          this.alertText = this.$t('user_profile.errors.old_password_must_be_at_least_6_characters');
          this.saving = false;
          return;
        }
        if (this.newPassword.length < 6) {
          this.showAlert = true;
          this.alertText = this.$t('user_profile.errors.new_password_must_be_at_least_6_characters');
          this.saving = false;
          return;
        }
        if (!this.$refs.form.validate()) {
          this.saving = false;
          return;
        }
        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(user, this.oldPassword, this.newPassword);
          })
          .then(data => {
            console.log(data)
            this.saving = false;
            this.dialog = false;
          })
          .catch(err => {
            console.log(err)
            this.saving = false;
            this.showAlert = true;
            this.alertText = this.$t('user_profile.errors.' + err.code);
          });
      },
    },
    watch: {
      dialog(value) {
        if (!value) {
          this.oldPassword = '';
          this.newPassword = '';
          this.newPassword2 = '';
          this.showOldPassword = false;
          this.showNewPassword = false;
          this.showNewPassword2 = false;
          this.showAlert = false;
          this.alertText = '';
          this.saving = false;
          this.$refs.form.reset();
        }
      }
    },
  }
</script>

<style scoped>

</style>
