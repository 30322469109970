<template>
  <v-app id="app" style="background: #141450">
    <template v-if="loadingOverlay">
      <v-fade-transition>
        <div class="loading-overlay">
          <v-row align="center" class="fill-height" justify="center">
            <v-col class="text-center" cols="4">
              <img src="/img/hubble-h-logo-pulse.svg" style="width: 100px; height: auto; opacity: 0.4"/>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </template>

    <v-snackbar
      v-model="snackbar"
      :bottom="$vuetify.breakpoint.xsOnly"
      :color="notification.color"
      :timeout="notification.timeOut"
      :top="$vuetify.breakpoint.smAndUp"
      multi-line
    >
      {{ notification.text }}
      <template v-slot:action>
        <template v-if="notification.showButton">
          <v-btn
            :x-small="$vuetify.breakpoint.xsOnly"
            outlined
            @click="
            () => {
              notification.callback();
              snackbar = false;
            }
          "
          >
            {{ notification.buttonText }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn icon @click.stop.prevent="snackbar = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </template>
    </v-snackbar>

    <v-navigation-drawer
      v-if="shouldMenuBeAvailable"
      v-model="menuOpenState"
      :mini-variant="miniMenu && $vuetify.breakpoint.lgAndUp"
      app
      fixed
      light
      width="300"
    >
      <v-list class="primary pa-0" dark style="border-radius: 0">
        <v-list-item style="padding: 2px 10px">
          <v-list-item-avatar style="margin-top: 10px; margin-bottom: 10px" @click.stop="toggleMiniMenu">
            <img src="/img/logo.svg" style="height: 100%; width: auto"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="subheading font-weight-bold text-uppercase">{{ $t('title') }}</v-list-item-title>
            <v-list-item-subtitle v-if="$isLoggedIn" class="caption">{{ $userInfo.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click.stop="toggleMiniMenu">
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list :dense="$vuetify.breakpoint.mdAndDown">
        <template v-if="filteredPrimaryPages.length > 0">
          <v-list-item
            v-for="(page, index) in filteredPrimaryPages"
            :key="index + 'primary'"
            :to="{ name: page.link }"
            exact
            @click="handleMenuItemClick(page)"
          >
            <v-list-item-avatar>
              <v-icon :size="page.iconSize ? page.iconSize : ''">{{ page.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2 mb-2"></v-divider>
        </template>

        <v-list-item
          v-for="(page, index) in filteredSecondaryPages"
          :key="index + 'secondary'"
          :to="{ name: page.link }"
          exact
          @click="handleMenuItemClick(page)"
        >
          <v-list-item-avatar>
            <v-icon :size="page.iconSize ? page.iconSize : ''">{{ page.icon }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-avatar>
            <v-icon>logout</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $t('login.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
  data() {
    return {
      notification: {
        color: '',
        text: '',
        buttonText: '',
        showButton: false,
        timeOut: -1,
        callback: () => {
        },
      },
      snackbar: false,
      miniMenu: false,
      bottomNavSelected: '',
      showBottomNav: true,
      menuOpenState: false,
      pages: [
        {
          title: this.$t('timer.title'),
          icon: 'access_time',
          link: 'timer',
          roles: ['BASIC', 'ADMIN', 'SUPERIOR'],
          primary: true,
        },
        {
          title: this.$t('reports.title'),
          icon: 'fas fa-file-alt',
          iconSize: '20px',
          link: 'report',
          roles: ['ADMIN', 'SUPERIOR'],
          primary: true,
        },
        {
          title: this.$tc('sites.title', 2),
          icon: 'fas fa-map-marked-alt',
          iconSize: '20px',
          link: 'sites',
          roles: ['BASIC', 'ADMIN', 'SUPERIOR'],
          primary: true,
        },
        {
          title: this.$tc('site_reports.title', 2),
          icon: 'fas fa-book',
          iconSize: '20px',
          link: 'site_reports',
          roles: ['ADMIN'],
          primary: true,
        },
        {
          title: this.$tc('equipments.title', 2),
          icon: 'fas fa-snowplow',
          iconSize: '20px',
          link: 'equipments',
          roles: ['BASIC', 'ADMIN', 'SUPERIOR'],
          primary: true,
        },
        {
          title: this.$tc('customers.title', 2),
          icon: 'far fa-building',
          iconSize: '20px',
          link: 'customers',
          roles: ['ADMIN', 'SUPERIOR'],
          primary: true,
        },
        {
          title: this.$tc('items.title', 2),
          icon: 'fas fa-cubes',
          iconSize: '20px',
          link: 'items',
          roles: ['ADMIN'],
          primary: true,
        },
        {
          title: this.$t('user_management.title'),
          icon: 'people',
          link: 'users',
          roles: ['ADMIN'],
          primary: false,
        },
        {
          title: this.$t('user_profile.title'),
          icon: 'person',
          link: 'userprofile',
          roles: ['BASIC', 'ADMIN', 'SUPERIOR'],
          primary: false,
        },
      ],
    };
  },
  methods: {
    handleMenuItemClick(page) {
      this.closeMenuIfOnSamePage(page);
      //this.updateStoreData();
    },
    updateStoreData() {
      this.$store.dispatch('reloadSites');
      this.$store.dispatch('reloadCustomers');
      this.$store.dispatch('reloadEquipments');
      this.$store.dispatch('reloadItemTypes');
      this.$store.dispatch('reloadItems');
    },
    logout() {
      // this logout for remote (if not used e.g. local logout used then service has 'rememberme'-functionality)
      this.$Amplify.Auth.signOut().then(() => {
        // this is client side only logout
        this.$clearUserInfo();
        this.$store.commit('setLoadingOverlay', true);
        this.$router.push({name: 'login'});
      });
    },
    goToLink(name) {
      this.$router.push({name: name});
    },
    toggleMiniMenu() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.miniMenu = !this.miniMenu;
      } else {
        this.$store.commit('toggleMainMenuState');
      }
    },
    closeMenuIfOnSamePage(page) {
      if (this.$vuetify.breakpoint.mdAndDown && this.$route.name === page.link) {
        this.menuOpenState = false;
      }
    },
    handleSelectedBottomNavItem(name) {
      const isPagePrimary = this.pages.findIndex(p => p.primary && p.link === name) > -1;
      if (isPagePrimary) {
        this.bottomNavSelected = name;
        this.showBottomNav = true;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  watch: {
    loadingOverlay(to) {
      if (to) {
        setTimeout(() => {
          this.$store.commit('setLoadingOverlay', false);
        }, 2000);
      }
    },
    notificationObject(to) {
      this.notification.color = to.color;
      this.notification.text = to.message;
      this.snackbar = true;
      this.notification.buttonText = to.buttonText;
      this.notification.showButton = to.showButton;
      this.notification.timeOut = to.timeOut;
      this.notification.callback = to.callback;
    },
    menuOpenComputed(to) {
      this.menuOpenState = to;
    },
    menuOpenState(to) {
      if (to !== this.$store.state.mainMenuOpen) {
        this.$store.commit('toggleMainMenuState');
      }
    },
    $route(to, from) {
      if (from && from.name) this.$store.commit('setPreviousRouteName', from.name);
      this.handleSelectedBottomNavItem(to.name);
    },
    // isLoggedIn(val) {
    //   if (!val) {
    //     this.$router.push({name: 'login'});
    //   }
    // },
  },
  computed: {
    loadingOverlay() {
      return this.$store.state.loadingOverlay;
    },
    shouldMenuBeAvailable() {
      return this.$isLoggedIn;
    },
    notificationObject() {
      return this.$store.state.notificationObject;
    },
    menuOpenComputed() {
      let menuState = true;
      if (this.$vuetify.breakpoint.mdAndDown) {
        menuState = this.$store.state.mainMenuOpen;
      }
      return menuState;
    },
    filteredSecondaryPages() {
      let pages = [];
      this.pages.forEach(page => {
        if (!page.primary) {
          if (this.$hasMatchingRoles(page.roles)) {
            pages.push(page);
          }
        }
      });
      return pages;
    },
    filteredPrimaryPages() {
      let pages = [];
      this.pages.forEach(page => {
        if (page.primary) {
          if (this.$hasMatchingRoles(page.roles)) {
            pages.push(page);
          }
        }
      });
      return pages;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.lgAndUp) this.menuOpenState = true;
    if (this.$userInfo) {
      if (Sentry) {
        Sentry.setUser({email: this.$userInfo.email});
      }
    }
    this.handleSelectedBottomNavItem(this.$route.name);
  },
};
</script>


<style lang="scss">
@import './sass/commonstyles.scss';
</style>
