import Vue from 'vue';

export default new Vue({
  data() {
    return {
      innerUserInfo: JSON.parse(localStorage.getItem("userInfo" )),
      innerSession: JSON.parse(localStorage.getItem("session" )),
    }
  },
  methods: {
    setSession(session) {
      console.log('setSession')
      localStorage.setItem("session", JSON.stringify(session));
      this.innerSession = session;
    },
    getSession() {
      return this.innerSession;
    },
    setUserInfo(userInfo) {
      console.log('setUserInfo')
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      this.innerUserInfo = userInfo;
    },
    getUserInfo() {
      return this.innerUserInfo;
    },
    clearUserInfo() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("session");
      this.innerUserInfo = null;
      this.innerSession = null;
    }
  },
})
