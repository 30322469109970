<template>
  <v-card v-if="introduction && introduction.ea1 !== null" color="secondary darken-1">
    <v-card-title v-if="$isSuperior || $isAdmin" class="accent--text">
      {{ $t('sites.introduction.proxy_title') }}
    </v-card-title>
    <v-card-text v-if="$isSuperior || $isAdmin">
      <v-text-field v-model="introduction.username"
                    :label="$t('sites.introduction.proxy_target_label')"
                    hide-details
                    outlined
      ></v-text-field>
    </v-card-text>
    <v-divider v-if="$isSuperior || $isAdmin"></v-divider>
    <v-card-title class="accent--text">
      {{ $t('sites.introduction.licences') }}
    </v-card-title>
    <v-card-text class="mb-4">
      <template v-for="license in introductionLicenses">
        <v-checkbox :key="license" v-model="introduction[license]" :disabled="!!introduction.accepted"
                    :label="$t(`sites.introduction.licence.${license}`)" class="mr-3"
                    dense hide-details></v-checkbox>
      </template>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-title class="accent--text">
      {{ $t('sites.introduction.steps') }}
    </v-card-title>
    <v-card-text>
      {{ $t('sites.introduction.steps_help') }}
    </v-card-text>
    <v-list class="transparent">
      <template v-for="step in introductionSteps">
        <v-divider :key="`${step}_divider`" class="mx-4"></v-divider>
        <v-list-item :key="step">
          <v-list-item-content>
            <v-list-item-title
              :class="validationErrors.findIndex(ve => ve === step) > -1 ? 'error--text font-weight-bold' : ''"
              class="text-wrap body-1">
              {{ $t(`sites.introduction.step.${step}`) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="py-1">
            <v-btn :color="validationErrors.findIndex(ve => ve === step) > -1 ? 'error' : 'accent'"
                   :disabled="!!introduction.accepted" icon
                   outlined
                   @click="toggleStepApprove(step)">
              <v-fab-transition hide-on-leave>
                <v-icon v-if="introduction[step]" size="32">mdi mdi-check-bold</v-icon>
                <v-icon v-if="validationErrors.findIndex(ve => ve === step) > -1" size="16">mdi mdi-exclamation</v-icon>
              </v-fab-transition>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
    <v-divider></v-divider>
    <v-card-text v-if="!introduction.accepted">
      <v-alert v-if="validationErrors.length > 0" text type="error">
        {{ $t('sites.introduction.validation_error_message') }}
      </v-alert>
      <div class="text-center my-5">
        {{ $t('sites.introduction.what_accepting_means') }}
      </div>
      <v-btn
        :disabled='saving'
        :loading="accepting"
        :small="$vuetify.breakpoint.xsOnly"
        block
        color='primary'
        @click='acceptIntroduction'
      >
        {{ $t('sites.introduction.accept_and_save') }}
      </v-btn>
    </v-card-text>
    <v-card-text v-else-if="introduction.accepted && !introduction.checked">
      <v-alert class="mb-0" text type="info">
        {{ $t('sites.introduction.introduction_accepted') }}
      </v-alert>
    </v-card-text>
    <v-card-text v-else-if="introduction.accepted && introduction.checked">
      <v-alert class="mb-0" text type="success">
        {{ $t('sites.introduction.introduction_accepted_and_checked') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import introductionApi from "@/api/introduction";
import moment from 'moment';

export default {
  name: "SiteIntroduction",
  props: ['site'],
  data() {
    return {
      saving: false,
      accepting: false,
      loading: true,
      introduction: {},
      introductionTemplate: {
        ea1: false,
        tyoturvallisuuskortti: false,
        tulityokortti: false,
        tieturva1: false,
        tieturva2: false,
        rataturva: false,
        yleistiedot: null,
        toteutusorganisaatio: null,
        tyomaatilat: null,
        tyosuojeluorganisaatio: null,
        paloturvallisuus: "",
        tyoterveyshuoltojaensiapu: null,
        liikenneturvallisuus: null,
        tyomaanjarjestysjasiisteys: null,
        tilaajanturvallisuusaineisto: null,
        tyomaankunnossapitotarkastukset: null,
        tyomaanvaaratekijat: null,
        ontutustunutperehdytysaineistoon: null,
        telineet: null,
        koneet: null,
        tyomaakierros: null,
        username: null,
      },
      introductionSteps: [
        'yleistiedot', 'toteutusorganisaatio', 'tyomaatilat', 'tyosuojeluorganisaatio', 'paloturvallisuus',
        'tyoterveyshuoltojaensiapu', 'liikenneturvallisuus', 'tyomaanjarjestysjasiisteys', 'tilaajanturvallisuusaineisto',
        'tyomaankunnossapitotarkastukset', 'tyomaanvaaratekijat', 'tyomaakierros', 'telineet', 'koneet', 'ontutustunutperehdytysaineistoon'
      ],
      validationErrors: [],
      introductionLicenses: ['ea1', 'tyoturvallisuuskortti', 'tulityokortti', 'tieturva1', 'tieturva2', 'rataturva']
    }
  },
  methods: {
    toggleStepApprove(step) {
      if (this.introduction[step]) {
        this.introduction[step] = null;
      } else {
        this.introduction[step] = moment().toISOString();
        const validationErrorIndex = this.validationErrors.findIndex(ve => ve === step);
        if (validationErrorIndex > -1) {
          this.validationErrors.splice(validationErrorIndex, 1)
        }
      }
    },
    async getIntroduction() {
      if (this.$isSuperior || this.$isAdmin) return;
      try {
        const introduction = await introductionApi.getIntroductionByUserAndSite(this.site?.id);
        if (introduction) {
          this.introduction = introduction;
        } else {
          this.copyIntroductionTemplate();
        }
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    validateIntroduction() {
      const invalidSteps = this.introductionSteps.filter(step => !this.introduction[step]);
      if (invalidSteps.length > 0) {
        this.validationErrors = invalidSteps;
        return false;
      }
      return true;
    },
    async acceptIntroduction() {
      if (!this.validateIntroduction()) return;
      this.accepting = true;
      await this.saveIntroduction();
      this.accepting = false;
    },
    async saveIntroduction() {
      if (!this.accepting) this.saving = true;
      try {
        this.introduction.site = this.site;
        const introduction = {...this.introduction};
        if (!introduction.site) introduction.site = this.site;
        if (this.accepting) introduction.accepted = moment().toISOString();

        if (introduction.id) {
          this.introduction = await introductionApi.updateIntroduction(introduction.id, introduction);
        } else {
          this.introduction = await introductionApi.createIntroduction(introduction);
          if (this.introduction.username) { // hyväksytään toisen henkilön puolesta tehtävä perehdytys
            await introductionApi.markIntroductionChecked(this.introduction.id)
            await this.copyIntroductionTemplate();
          }
        }
        await this.$store.dispatch('loadSiteIntroductions', this.site.id);
      } catch (e) {
        this.$handleApiError(e);
      }
      this.saving = false;
    },
    copyIntroductionTemplate() {
      this.loading = true;
      this.introduction = {};
      Object.keys(this.introductionTemplate).forEach(key => {
        this.$set(this.introduction, key, this.introductionTemplate[key]);
      });
      this.loading = false;
    },
  },
  mounted() {
    this.copyIntroductionTemplate();
    this.getIntroduction();
  }
}
</script>

<style scoped>

</style>
