import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c(VCard,{attrs:{"color":"secondary darken-1"}},[_c(VCardTitle,{staticClass:"accent--text"},[_vm._v(" "+_vm._s(_vm.$t('customer.customer_information'))+" ")]),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{staticClass:"mt-5 customername",attrs:{"label":_vm.$t('customers.name'),"rules":[_vm.$rules.required],"type":"text","validate-on-blur":""},model:{value:(_vm.currentCustomer.name),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "name", $$v)},expression:"currentCustomer.name"}}),_c(VTextarea,{staticClass:"mt-5 customername",attrs:{"label":_vm.$t('customers.description'),"type":"text"},model:{value:(_vm.currentCustomer.description),callback:function ($$v) {_vm.$set(_vm.currentCustomer, "description", $$v)},expression:"currentCustomer.description"}})],1),_c(VAlert,{staticClass:"mt-4",attrs:{"value":_vm.showAlert,"outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('validation.check_all_required_fields'))+". ")])],1),_c(VCardActions,[(_vm.$isAdmin)?[(_vm.currentCustomer.id && _vm.currentCustomer.status === 'ACTIVE')?_c(VBtn,{attrs:{"loading":_vm.saving,"color":"red"},on:{"click":_vm.setCustomerInactive}},[_c('span',[_vm._v(_vm._s(_vm.$t('set_inactive')))])]):_vm._e(),(_vm.currentCustomer.id && _vm.currentCustomer.status === 'DISABLED')?_c(VBtn,{attrs:{"loading":_vm.saving,"color":"green"},on:{"click":_vm.setCustomerActive}},[_c('span',[_vm._v(_vm._s(_vm.$t('set_active')))])]):_vm._e()]:_vm._e(),_c(VSpacer),(_vm.$isAdmin || _vm.$isSuperior)?[_c(VBtn,{attrs:{"disabled":_vm.saving,"color":"default"},on:{"click":_vm.setCurrentCustomer}},[_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c(VBtn,{attrs:{"loading":_vm.saving,"color":"primary"},on:{"click":_vm.updateCustomer}},[_c('span',[_vm._v(_vm._s(_vm.$t('save')))])])]:_vm._e()],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }