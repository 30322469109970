import Vue from 'vue';
import Router from 'vue-router';
import LoginView from './views/LoginView.vue';
import UserManagementView from './views/UserManagementView.vue';
import UserProfile from './views/UserProfile.vue';
import TimerView from './views/TimerView.vue';
import ReportView from './views/ReportView.vue';
import SitesView from './views/SitesView.vue';
import EquipmentsView from './views/EquipmentsView.vue';
import CustomersView from '@/views/CustomersView.vue';
import SingleSiteView from '@/views/SingleSiteView.vue';
import CustomerView from "@/views/CustomerView.vue";
import ItemsView from "@/views/ItemsView.vue";
import SingleEquipmentView from "@/views/SingleEquipmentView";
import SingleEquipmentCheckView from "@/views/SingleEquipmentCheckView";
import SiteReports from "@/views/SiteReports.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/',
      name: 'timer',
      component: TimerView,
    },
    {
      path: '/users',
      name: 'users',
      component: UserManagementView,
    },
    {
      path: '/me',
      name: 'userprofile',
      component: UserProfile,
    },
    {
      path: '/sites',
      name: 'sites',
      component: SitesView,
      props: true,
    },
    {
      path: '/sites/:id',
      name: 'site',
      component: SingleSiteView,
      props: true,
    },
    {
      path: '/site_reports/',
      name: 'site_reports',
      component: SiteReports,
    },
    {
      path: '/equipments',
      name: 'equipments',
      component: EquipmentsView,
    },
    {
      path: '/equipments/e/:id',
      name: 'equipment',
      component: SingleEquipmentView,
      props: true,
    },
    {
      path: '/equipments/check/:id?',
      name: 'equipmentcheck',
      component: SingleEquipmentCheckView,
      props: true,
    },
    {
      path: '/report',
      name: 'report',
      component: ReportView,
    },
    {
      path: '/customers',
      name: 'customers',
      component: CustomersView,
    },
    {
      path: '/customers/c/:id',
      name: 'customer',
      component: CustomerView,
      props: true,
    },
    {
      path: '/items',
      name: 'items',
      component: ItemsView,
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
