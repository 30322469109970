import axios from 'axios';
import moment from 'moment';
import _ from "lodash";

function convertEntryTimes(e) {
  e.entryStartTS = e.entryStart;
  e.entryStart = moment.unix(e.entryStart);
  if (e.entryEnd) {
    e.entryEnd = moment.unix(e.entryEnd);
  }
  return e;
}

const api = {
  /** ENTRIES **/
  getAllEntries: async () => {
    const response = await axios.get('/api/entry');
    const entries = response.data;
    entries.forEach(e => convertEntryTimes(e));
    return entries;
  },
  getMyEntries: async () => {
    const response = await axios.get('/api/entry/my');
    const entries = response.data;
    entries.forEach(e => convertEntryTimes(e));
    return entries;
  },
  upsertEntry: async entry => {
    let url = '/api/entry';
    if (entry.id) {
      url = `${url}/${entry.id}`;
    }

    const entryData = {
      distance: entry.distance,
      entryEnd: entry.entryEnd,
      entryStart: entry.entryStart,
      equipmentIds: entry.equipmentIds,
      info: entry.info,
      siteId: entry.siteId,
      userSubject: entry.userSubject,
      workType: entry.workType,
      containsLunch: entry.containsLunch,
      invoiced: entry.invoiced,
    };

    const response = await axios.post(url, entryData);
    return convertEntryTimes(response.data);
  },
  deleteEntry: async entryId => {
    const response = await axios.delete('/api/entry/' + entryId);
    return convertEntryTimes(response.data);
  },
  getEntryImages: async entryId => {
    const response = await axios.get(`/api/entry/${entryId}/images`);
    return response.data;
  },
  uploadImage: async (entryId, file, imageFilename) => {
    const formData = new FormData();
    formData.append('file', file, imageFilename);

    // const config =  {
    //   onUploadProgress: progressEvent => {
    //     this.imageUploadProgress = progressEvent.loaded / progressEvent.total * 100;
    //   },
    // };
    const response = await axios.post(`/api/image/${entryId}/entry`, formData /*, config*/);
    return response.data;
  },
  massUpdateInvoiced: async payload => {
    const invoiced = payload.invoiced;
    const entryIds = payload.entryIds;
    const response = await axios.post('/api/entry/massUpdateInvoiced', {
      entryIds,
      invoiced,
    });
    return response.data;
  },

  /** IMAGES **/
  deleteImage: async imageKey => {
    await axios.delete(`/api/image/${imageKey}`);
  },

  /** SITES **/
  getAllSites: async () => {
    const response = await axios.get('/api/site');
    return _.sortBy(response.data, 'name')
  },
  upsertSite: async site => {
    let url = '/api/site';
    if (site.id) {
      url = `${url}/${site.id}`;
    }
    const response = await axios.post(url, {
      address: site.address,
      hidden: site.hidden,
      name: site.name,
      status: site.status,
      customer: site.customer,
      startDate: site.startDate,
      endDate: site.endDate,
    });
    return response.data;
  },
  getSiteEntryImages: async siteId => {
    const response = await axios.get(`/api/site/${siteId}/entry/images`);
    return response.data;
  },
  setSiteBilled: async siteId => {
    const response = await axios.post(`/api/site/${siteId}/billed`);
    return response.data;
  },
  setSiteUnbilled: async siteId => {
    const response = await axios.post(`/api/site/${siteId}/unbilled`);
    return response.data;
  },

  /** EQUIPMENT **/
  getAllEquipments: async () => {
    const response = await axios.get('/api/equipment');
    return _.sortBy(response.data, 'description');
  },
  upsertEquipment: async equipment => {
    let url = '/api/equipment';
    if (equipment.id) {
      url = `${url}/${equipment.id}`;
    }
    const response = await axios.post(url, {
      description: equipment.description,
      status: equipment.status,
    });
    return response.data;
  },

  /** USERS **/
  getCloudUsers: async () => {
    const response = await axios.get('/api/user/list-cloud');
    return response.data;
  },

  /** CUSTOMERS **/
  getAllCustomers: async () => {
    const response = await axios.get('/api/customer');
    return _.sortBy(response.data, 'name');

  },
  upsertCustomer: async customer => {
    let url = '/api/customer';
    if (customer.id) {
      url = `${url}/${customer.id}`;
    }
    const response = await axios.post(url, {
      name: customer.name,
      status: customer.status,
      description: customer.description,
    });
    return response.data;
  },

  /** ITEMS **/
  getAllItems: async () => {
    const response = await axios.get('/api/item');
    return response.data;
  },
  upsertItem: async item => {
    let url = '/api/item';
    if (item.id) {
      url = `${url}/${item.id}`;
    }
    const response = await axios.post(url, {
      active: item.active,
      code: item.code,
      shortDescription: item.shortDescription,
      longDescription: item.longDescription,
      unit: item.unit,
      typeId: item.type.id,
      price: item.price,
    });
    return response.data;
  },

  /** ITEMTYPES **/
  getAllItemTypes: async () => {
    const response = await axios.get('api/item/type');
    return response.data;
  },
  reorderItemTypes: async ids => {
    const response = await axios.post('api/item/order_types', ids);
    return response.data;
  },
  upsertItemType: async itemType => {
    let url = '/api/item/type';
    if (itemType.id) {
      url = `${url}/${itemType.id}`;
    }
    const response = await axios.post(url, {
      active: itemType.active,
      longDescription: itemType.longDescription,
      ordernum: itemType.ordernum,
      shortDescription: itemType.shortDescription,
    })
    return response.data;
  },

  /** PRICES **/
  getAllPricesForCustomer: async customerId => {
    const url = `api/price/${customerId}`;
    const response = await axios.get(url);
    return response.data;
  },
  upsertCustomerPrice: async (customerId, itemId, request) => {
    const url = `api/price/${customerId}/${itemId}`;
    const response = await axios.post(url, request);
    return response.data;
  },
  deleteCustomerPrice: async (customerId, itemId) => {
    const url = `api/price/${customerId}/${itemId}`;
    await axios.delete(url);
  },
  async importExcel(customerId, file) {
    const data = new FormData();
    data.append("file", file);
    return await axios({
      url: `/api/price/${customerId}/import`,
      method: 'post',
      data: data,
    }).then(r => r.data);
  },
  async exportExcel(customerId) {
    return await axios({
      url: `/api/price/${customerId}/export`,
      method: 'get',
      responseType: 'blob'
    }).then(r => r.data);
  },

  /** SITEITEMS **/
  getAllItemsForSite: async siteId => {
    const url = `api/site_item/site/${siteId}`
    const response = await axios.get(url);
    return response.data;
  },
  getAllItemsForSites: async siteIds => {
    const url = `api/site_item/sites`
    const response = await axios.post(url, siteIds);
    return response.data;
  },
  getAllItemsForCustomer: async customerId => {
    const url = `api/site_item/customer/${customerId}`
    const response = await axios.get(url);
    return response.data;
  },
  createSiteItem: async (siteId, request) => {
    const url = `api/site_item/${siteId}`
    const response = await axios.post(url, request);
    return response.data
  },
  updateSiteItem: async (siteItemId, siteId, request) => {
    const url = `api/site_item/${siteId}/${siteItemId}`
    const response = await axios.post(url, request);
    return response.data;
  },

  /** INTRODUCTIONS **/
  getAllIntroductionsForSite: async siteId => {
    const url = `api/tenant/introduction/${siteId}`
    const response = await axios.post(url);
    return response.data;
  }
};

export default api;
